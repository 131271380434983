
import React, { useState, useEffect } from 'react';
import { background_color, base_color } from '../assets/colors';
import above_layer from '../assets/left_back.png'
import bottom_layer from '../assets/rectangle_left_2.png'

import left_bottom from '../assets/left_bottom.png'
import right_top from '../assets/right_top.png'

import ReactiveButton from 'reactive-button';
import '../App.css'
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { MdOutlinePassword } from 'react-icons/md'
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { db } from '../config';
import { encrypt, encryptPass } from '../Crypto';
import { toast } from 'wc-toast';
import { connect } from 'react-redux';
import { SetId, SetProject } from './redux/actions';
import { useLottie } from 'lottie-react';
import waiting from '../assets/Waiting.json'

const userdb = db.ref('userdb')

function Forget({ SetId, SetProject, items }) {

    console.log(encrypt('boom@gmail.com'))
    console.log(encryptPass('12345'))

    let path = window.location.href;

    const options = {
        animationData: waiting,
        loop: true,
        width: 800,
        height: 800
    };

    const { View } = useLottie(options);

    const navigate = useNavigate()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);


    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowWidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [mail, setMail] = useState('')

    const [pass, setPass] = useState('')

    const [secure, setSecure] = useState(true)
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState(null)

    async function check_exist() {
        setLoading(true)
        let check = await userdb.orderByChild('email').equalTo(encrypt(mail)).once('value')
        if (check.val() == null) {
            checkAdmin()
            setLoading(false)
        }
        else {
            let arr = Object.values(check.val())
            // console.log('Arr ', arr)
            if (arr[0].pass != undefined) {
                SetId(arr[0])
                SetProject(arr[0].permission)
                setLoading(false)
                let res = await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/mail_verification?email=${mail}`)
                res = await res.json()
                console.log('Res ', res)

            }

            else if (arr[0].pass == undefined) {
                toast('Sign up with this email')
                setLoading(false)
            }
        }
    }

    async function checkAdmin() {
        setLoading(true)
        const admin_users = db.ref('admin_users')
        let check = await admin_users.orderByChild('email').equalTo(encrypt(mail)).once('value')
        if (check.val() == null) {
            toast('This email doesnot exist')
            setLoading(false)
        }
        else {
            let arr = Object.values(check.val())
            console.log('Arr ', arr)
            if (arr[0].pass != undefined) {
                let obj = arr[0];
                obj['role'] = 'Master'
                SetId(arr[0])
                SetProject(arr[0].permission)
                setLoading(false)
                let res = await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/mail_verification?email=${mail}`)
                res = await res.json()
                console.log('Res ', res)

            }

            else if (arr[0].pass == undefined) {
                toast('Sign up with this email')
                setLoading(false)
            }
        }
    }


    function hasLowerCase(str) {
        return str.toUpperCase() != str;
    }

    function hasUpperCase(str) {
        return str.toLowerCase() != str;
    }

    function ispass(pass) {
        let ret = 0;
        if (hasLowerCase(pass)) ret++;
        if (hasUpperCase(pass)) ret++;
        if (pass.length >= 6) ret++;
        if (pass.includes(0) || pass.includes(1) || pass.includes(2) || pass.includes(3) || pass.includes(4) || pass.includes(5) || pass.includes(6) || pass.includes(7) || pass.includes(8) || pass.includes(9)) ret++;

        return ret == 4

    }


    return (<div style={{ display: 'flex', backgroundColor: background_color, height: windowHeight, width: windowWidth, flexDirection: 'row' }}>

        <div style={{ width: 0.45 * windowWidth, height: windowHeight, display: 'block', justifyContent: 'center' }}>
            <img src={bottom_layer} style={{ width: 0.45 * windowWidth, height: windowHeight }} />
        </div>

        <div style={{ width: 0.55 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: windowHeight, display: 'flex', position: 'relative' }}>
            <div style={{ width: 400, alignSelf: 'center', display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: -100, flexDirection: 'column' }}>
                <img src={require('./promas.png')} style={{ width: 300, height: 70, objectFit: 'contain', marginBottom: 20 }} />
                <text style={{ fontFamily: 'Montserrat', color: '#0D1C41', fontSize: 32, fontWeight: '700' }}>Welcome</text>
                <text style={{ fontFamily: 'Montserrat', color: '#5E5E5E', fontSize: 14, fontWeight: '600', marginTop: 5 }}>Login to your account to continue</text>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 400, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Montserrat' }}>Email</text>
                    <input placeholder=' Email' style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#e7ebee', marginTop: 6, width: 400, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#e7ebee', outline: 'none' }} value={mail} onChange={(e) => setMail(e.currentTarget.value)} />
                </div>
            </div>


            <div style={{ marginTop: 10, width: 405, alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 6 }}>
                <text></text>
                <text onClick={() => navigate('/')} style={{ color: 'black', fontSize: 13, fontWeight: '400', fontFamily: 'Montserrat', textDecorationLine: 'underline', textDecorationColor: '#41A4C3', cursor: 'pointer' }}>Forgot password ?</text>
            </div>


            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 25 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Log in</text>}
                    successText="Done"
                    onClick={() => { check_exist() }}
                    style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                />
            </div>
            <img src={left_bottom} style={{ width: 300, height: 300, position: 'absolute', bottom: 0, left: 0 }} />
            <img src={right_top} style={{ width: 300, height: 300, position: 'absolute', top: 0, right: 0 }} />
        </div>

        {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
            {View}
        </div> : null}

    </div>)
}


const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId, SetProject })(Forget)