
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/square_logo.png'
import logo1 from './promas1.png'
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast'
//import 'react-toastify/dist/ReactToastify.css';
import { db, firestore, storage } from '../config';
import { favourite_active, favourite_inactive, files_active_icon, files_inactive_icon, link_inactive, settings_inactive, tags_inactive, user_active, user_inactive } from '../assets/Icons';
import { FaAngleLeft, FaFile } from "react-icons/fa6";
import FlatList from 'flatlist-react';
import Pic from './Pic.png'
import Search from './search.png'
import menu1 from './menu1.png'

import arrow2 from './arrow2.png'
import arrow3 from './arrow3.png'
import rectangle from './Rectangle.png'
import Component1 from './Component1.png'
import Component2 from './Component2.png'
import Component3 from './Component3.png'

import ProgressBar from "@ramonak/react-progress-bar";

import D1 from './D1.png'
import D2 from './D2.png'
import D3 from './D3.png'
import D4 from './D4.png'
import D5 from './D5.png'
import D6 from './D6.png'
import D7 from './D7.png'
import D8 from './D8.png'
import ReactModal from 'react-modal';

import { FaPlus } from "react-icons/fa6";
import { FaCheckCircle, FaPlusCircle } from 'react-icons/fa';
import { IoMdAdd, IoMdClose, IoMdEyeOff, IoMdSearch, IoMdShare } from 'react-icons/io';
import ReactiveButton from 'reactive-button';

import { Bounce } from "react-activity";
import "react-activity/dist/library.css";
import { FaFolder } from "react-icons/fa";

import { IoMdEye } from "react-icons/io";
import { BsUpload } from "react-icons/bs";
import Arrow from './arrow1.png'
import icon from './icon.png'
import { MdAdminPanelSettings, MdHistory, MdOutlineDelete } from 'react-icons/md';

import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import Select, { components } from 'react-select';
import { decrypt, decryptPass, encrypt, encryptPass } from '../Crypto';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { RxActivityLog } from 'react-icons/rx';
import { GrUserAdmin } from 'react-icons/gr';
import { AiTwotoneEdit } from 'react-icons/ai';
import Modal from 'react-responsive-modal';
import { IoEye } from 'react-icons/io5';
import { CiCircleCheck } from 'react-icons/ci';
import { RiseLoader } from 'react-spinners';

const fav_icon = <FaStar size={25} color='black' />
const non_fav_icon = <FaRegStar size={25} color='black' />

const activity_icon = <RxActivityLog size={17} color='white' />
const inactivity_icon = <RxActivityLog size={17} color='#A2B0BF' />

const admin_icon = <MdAdminPanelSettings size={20} color='white' />
const inactivity_admin = <MdAdminPanelSettings size={20} color='#A2B0BF' />

const left_bar = '#0D1C41'

const folder_db = db.ref('folders');
//const subfolder_db = db.ref('sub_folders');

const close_button = <IoMdClose size={20} color={'black'} />

const search_icons = <RiArrowDropDownLine color='#0b6d0f' size={27} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={27} />

const userdb = db.ref('userdb')

const company_sub_folder_db = db.ref('company_sub_folders');

function Users({ items }) {

    console.log('Items ', items)

    let myobj = items.myid;

    const navigate = useNavigate();


    const [full_access_company, setFullAccessCompany] = useState([])

    const [sharing_curr_folder, setSharingCurrFolder] = useState(null)
    const [company_sub_folders, setCompanySubFolders] = useState(null)

    const arr1 = ['Asbestos', 'Fire Risk', 'Drawings & Plans', 'Health & Safety File', 'Safety Inspections', 'Other Surveys']


    useEffect(() => {
        if (sharing_curr_folder != null) {
            let subscribe = company_sub_folder_db.orderByChild('folder_id').equalTo(Number(sharing_curr_folder.ID)).on('value', (snapshot) => {
                if (snapshot != undefined && snapshot.val() != null) {
                    let arr = Object.values(snapshot.val())
                    setCompanySubFolders([...arr])
                }
                else setCompanySubFolders([])
            })
            return () => subscribe()
        }
    }, [sharing_curr_folder])

    function getSubFoldername(Id) {
        for (let i = 0; i < company_sub_folders.length; i++) {
            if (company_sub_folders[i].ID == Id) return company_sub_folders[i].name
        }
        return 'Folder';
    }

    const [width, setwidth] = useState(window.innerWidth);
    const [height, setWindowHeight] = useState(window.innerHeight);
    const [all_obj, setAllObj] = useState([])

    // adding paths 
    function checking_doubleUser(arr, id) {
        let temp = ''
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].user_id == id) {
                temp += (temp != '' ? ' , ' : ' ') + arr[i].path
            }
        }
        console.log('Total ', temp)
        return temp;
    }

    function getUsersOnly(user_id = null) {
        let arrs = full_access_company != null ? [...full_access_company] : [];
        let temp = [];
        let arr = []

        for (let i = 0; i < arrs.length; i++) {
            //  console.log('sub matching ', arrs[i].company_sub_id, company_sub_id)
            if (arrs[i] != null && arrs[i].full_company == true) {
                arrs[i].path = `All folders`
                temp.push(arrs[i])
            }
        }

        for (let i = 0; i < arrs.length; i++) {
            //  console.log('sub matching ', arrs[i].company_sub_id, company_sub_id)
            if (arrs[i].full_company != true) {
                if (arrs[i] != null && arrs[i].company_sub_id == null) {
                    arrs[i].path = getSubFoldername(arrs[i].sub_company)
                    temp.push(arrs[i])
                }
                else if (arrs[i] != null && arrs[i].company_sub_id != null) {
                    //   console.log('Folder name ',arrs[i].company_sub_id,' ',getSubFoldername(arrs[i].company_sub_id))
                    // `${getSubFoldername(arrs[i].company_sub_id)}`
                    arrs[i].path = `${getSubFoldername(arrs[i].company_sub_id)} / ${arrs[i].sub_company == 0 ? 'Building Services Management' : arrs[i].sub_company == 1 ? 'Building Information Management' : 'Building Projects'}`
                    temp.push(arrs[i])
                }
            }
        }

        for (let i = 0; i < temp.length; i++) {
            if (temp[i].user_id == user_id || user_id == null) {
                arr.push(temp[i])
            }
        }

        return arr;
    }


    function getFullCompanyUsers(company_id) {

        let temp = [], res = [];

        let arrs = full_access_company != null ? [...full_access_company] : [];

        // full company true

        for (let i = 0; i < arrs.length; i++) {
            //  console.log('sub matching ', arrs[i].company_sub_id, company_sub_id)
            if (arrs[i] != null && arrs[i].full_company == true) {
                arrs[i].path = `All folders`
                temp.push(arrs[i])
            }
        }

        // company_sub_id==undefined and sub_company means full folder 

        for (let i = 0; i < arrs.length; i++) {
            //  console.log('sub matching ', arrs[i].company_sub_id, company_sub_id)
            if (arrs[i].full_company != true) {
                if (arrs[i] != null && arrs[i].company_sub_id == null) {
                    arrs[i].path = getSubFoldername(arrs[i].sub_company)
                    temp.push(arrs[i])
                }
                else if (arrs[i] != null && arrs[i].company_sub_id != null) {
                    //   console.log('Folder name ',arrs[i].company_sub_id,' ',getSubFoldername(arrs[i].company_sub_id))
                    // `${getSubFoldername(arrs[i].company_sub_id)}`
                    arrs[i].path = `${getSubFoldername(arrs[i].company_sub_id)} / ${arrs[i].sub_company == 0 ? 'Building Services Management' : arrs[i].sub_company == 1 ? 'Building Information Management' : 'Building Projects'}`
                    temp.push(arrs[i])
                }
            }
        }


        let temp_checking_duplicate = [];
        for (let i = 0; i < temp.length; i++) {
            if (!temp_checking_duplicate.includes(temp[i].user_id)) {
                let obj = temp[i];
                obj.path = checking_doubleUser(temp, temp[i].user_id)
                res.push(obj)
                temp_checking_duplicate.push(temp[i].user_id)
            }
        }

        return res;

    }

    const [upload_report, setUploadReport] = useState([])

    const updateAllTitle = (index, e) => {
        let newArr = [...all_obj]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        let obj = newArr[index]
        obj.title = e
        newArr[index] = obj; // replace e.target.value with whatever you want to change it to

        setAllObj([...newArr]);
    }

    let report_arr = useRef([])

    function make_report(len) {
        let arr = [];
        for (let i = 0; i < len; i++) arr.push(0)
        setUploadReport([...arr])
        report_arr.current = [...arr]
    }


    const uploadImage1 = async (key, imguri, indx) => {
        const upload = storage.ref(`/images/${key}`).put(imguri)
        upload.on("state_changed", (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progress = Math.trunc(progress)
            updateAllUpload(indx, progress)
            console.log("upload is" + progress + "% done");
            switch (snapshot.state) {
                case "paused":
                    console.log("Upload paused");
                    break;
                case "running":
                    console.log("Upload running");
                    break;
                default:
                    break;
            }
        },
            (error) => {
                console.log(error);
                return null;
            }
        );

        await Promise.resolve(upload)
        let uri = await storage.ref(`/images/${key}`).getDownloadURL();
        console.log('Uri ', uri)
        return uri

    };

    const updateAllUpload = (index, val) => {
        let newArr = report_arr.current; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index] = val; // replace e.target.value with whatever you want to change it to
        setUploadReport([...newArr]);
    }

    async function firstUpload(indx) {
        if (all_obj[indx].title != '') {

            let final_obj = all_obj[indx]

            let uri = await uploadImage1(all_obj[indx].ID, all_obj[indx].imguri, indx)
            console.log('Got uri ', uri)
            final_obj.uri = uri

            delete final_obj.imguri
            delete final_obj.upload
            delete final_obj.picture
            delete final_obj.mark

            delete final_obj.replace
            delete final_obj.parent


            // create docs
            const doc_db = firestore.collection(`documents`)
            await doc_db.doc(String(all_obj[indx].ID)).set(final_obj)


            toast(`${final_obj.title} has been uploaded successfully`)
        }
        else {
            toast('Empty fields are not accepted !')
        }
    }

    async function all_uploading() {
        let temp = []
        for (let i = 0; i < all_obj.length; i++) {
            temp.push(firstUpload(i))
        }
        await Promise.all(temp)
        setUploadObj(null)
        setAllObj([])
    }

    const handleDragOver = (event) => {
        event.preventDefault()
    }

    // Function to handle image drop
    const handleDrop = (e) => {
        e.preventDefault()
        let temp = [];
        if (e != undefined && e.dataTransfer != undefined && e.dataTransfer.files.length > 0) {
            let files = Array.from(e.dataTransfer.files);
            let temp = []
            files.forEach((file) => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    let name = String(file.name)
                    console.log('Files ', file)
                    name = String(name).substring(0, name.indexOf('.'))
                    let tim = new Date().getTime()
                    let new_obj = { company_id: curr_folder.ID, par_id: upload_obj.par_id, sub_id: upload_obj.sub_id, sub_folder_id: upload_obj.sub_folder_id.ID, author_id: myobj.ID, ID: tim, title: name, uri: null, picture: reader.result, imguri: file, file: String(file.name).substring(String(file.name).indexOf('.') + 1, String(file.name).length) }
                    temp.push(new_obj)
                    let new_temp = temp;
                    for (let i = 0; i < new_temp.length; i++) {
                        new_temp[i].ID = tim + i;
                    }
                    if (new_temp.length == files.length) {
                        setAllObj([...new_temp])
                    }
                    make_report(new_temp.length)
                }
                console.log('All temp ', temp)
                reader.readAsDataURL(file);
            });
            console.log('All temp ', temp)
        }
        else {
            console.log('select ', e.target.files[0])
            let files = Array.from(e.target.files);
            let temp = []
            files.forEach((file) => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    let tim = new Date().getTime()
                    let name = String(file.name)
                    console.log('Files ', file)
                    name = String(name).substring(0, name.indexOf('.'))
                    let new_obj = { company_id: curr_folder.ID, par_id: upload_obj.par_id, sub_id: upload_obj.sub_id, sub_folder_id: upload_obj.sub_folder_id.ID, author_id: myobj.ID, ID: tim, title: name, uri: null, picture: reader.result, imguri: file, file: String(file.name).substring(String(file.name).indexOf('.') + 1, String(file.name).length) }
                    temp.push(new_obj)

                    let new_temp = temp;
                    for (let i = 0; i < new_temp.length; i++) {
                        new_temp[i].ID = tim + i;
                    }

                    if (new_temp.length == files.length) {
                        setAllObj([...new_temp])
                    }
                    make_report(new_temp.length)
                    //setAllObj([...all_obj, new_obj])
                }
                console.log('All temp ', temp)
                reader.readAsDataURL(file);
            });
        }
        //console.log('All temp ', temp)
    }

    const refs = useRef()

    const openFileInput = () => {
        refs.current.click()
    }

    useEffect(() => {
        const windowSizeHandler = () => {
            setwidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [curr_folder, setCurrFolder] = useState(null)

    const uploadImage = async (key, imguri) => {
        console.log('Keys ', key, imguri)

        await storage.ref(`/folders/${key}`).put(imguri);
        let uri = await storage.ref(`/folders/${key}`).getDownloadURL();
        return uri
    };

    const [user_obj, setUserObj] = useState(null)

    useEffect(() => {
        if (curr_folder != null) {
            const users_db = firestore.collection(`users_db`).doc(String(curr_folder.ID))
            let subscribe = users_db.onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.data();
                let arr = [];
                if (main != undefined && main != null) {
                    arr = Object.values(main)
                }
                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setFullAccessCompany([...arr])
                setUserObj({ ...main })
            })
            return () => subscribe()
        }
    }, [curr_folder])


    useEffect(() => {
        let subscribe = folder_db.on('value', (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val())
                arr.sort(function (a, b) {
                    if (a.name != undefined) return a.name.localeCompare(b.name)
                    else return a.ID - b.ID
                })
                let obj = {
                    name: 'Admin Users',
                    ID: 444
                }
                arr.unshift(obj)
                setFolders([...arr])
            }
            else setFolders([])
        })
        return () => subscribe()
    }, [])




    const renderPerson = (person, id) => {
        return (
            getCompanyAccess(person.ID) ? person.name != 'Admin Users' ? <div onClick={() => { setCurrFolder(person); setSharingCurrFolder(person) }} key={id} style={{ cursor: 'pointer', padding: 4 }} >
                <div style={{ display: 'flex', width: 270, height: 270, flexDirection: 'column', justifyContent: 'start', alignSelf: 'center', backgroundColor: 'white', borderRadius: 8 }}>
                    <img src={person.uri} onClick={() => ('/')} style={{ width: 230, height: 235, alignSelf: 'center', marginTop: 5, borderRadius: 8 }} />
                    <text style={{ textAlign: 'center', fontWeight: '600', color: 'black', fontSize: 16 }}>{person.name}</text>
                </div>
            </div> : <div onClick={() => { setCurrFolder(person); setSharingCurrFolder(person) }} key={id} style={{ cursor: 'pointer', padding: 4 }} >
                <div style={{ display: 'flex', width: 270, height: 270, flexDirection: 'column', justifyContent: 'start', alignSelf: 'center', backgroundColor: 'white', borderRadius: 8 }}>
                    <GrUserAdmin color='black' size={230} style={{ alignSelf: 'center', marginTop: 5, }} />
                    <text style={{ textAlign: 'center', fontWeight: '600', color: 'black', fontSize: 16 }}>{person.name}</text>
                </div>
            </div> : null
        );
    }

    // const folders = [{ 'ID': 1, name: 'HSB' }, { 'ID': 2, name: 'HSBC' }, { 'ID': 2, name: 'HSC' }]
    const [folders, setFolders] = useState([])

    const ref = useRef()

    const arr = ['Lightening Protection', 'Fire', 'Electrical', 'Mechanical', 'Catering Equipment', 'Security Alarm', 'Legionella & Water Hygiene', 'Lifts & Lifting Equipment']



    const [upload_obj, setUploadObj] = useState(null)

    const [curr_company_docs, setCurrCompanyDocs] = useState([])

    const [open_subfolder, setOpenSubFolder] = useState([])

    useEffect(() => {
        if (curr_folder != null) {
            const doc_db = firestore.collection(`documents`)
            let subscribe = doc_db.where('company_id', '==', curr_folder.ID).onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.docs;
                let arr = [];

                for (let i = 0; i < main.length; i++) {
                    arr.push(main[i].data());
                }

                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setCurrCompanyDocs([...arr])
            })
            return () => subscribe()
        }
    }, [curr_folder])

    function closesubfolder(id) {
        let temp = []
        for (let i = 0; i < open_subfolder.length; i++) {
            if (open_subfolder[i] !== id) temp.push(open_subfolder[i])
        }
        console.log('Closing subfolder ', temp, id)
        setOpenSubFolder([...temp])
    }


    function getCompanyAccess(id) {
        let arr = items.projects
        if (arr[id] == undefined && myobj.ID !== 12345678 && myobj.role != 'Master') return false;
        else return true;
    }



    const [main_favourite, setMainFavourite] = useState([])


    useEffect(() => {
        if (curr_folder != null && curr_folder.ID != undefined && myobj.ID != undefined) {
            const favourites_db = firestore.collection(`Favourites`)
            let subscribe = favourites_db.doc(String(myobj.ID)).onSnapshot(snapshot => {
                if (snapshot != undefined || snapshot != null) {
                    let obj = snapshot.data()
                    let arr = [];
                    if (obj != undefined && obj != null) arr = Object.values(obj);
                    setMainFavourite([...arr])
                }
            })
            return () => subscribe()
        }
    }, [curr_folder])



    function today_dats(str) {
        // return new Date(str).getDate() + " " + months[new Date(str).getMonth()] + " " + new Date().getFullYear() + " / " + moment(new Date(str)).format('hh : mm A')
        return moment(new Date(str)).format('DD-MM-YYYY') + " at " + moment(new Date(str)).format('hh : mm A')
    }

    const [histories, setHistories] = useState([])
    const [history_obj, setHistoryObj] = useState({
        par_id: null, sub_id: null
    })

    useEffect(() => {
        if (curr_folder != null && history_obj.par_id != null && history_obj.sub_id != null) {
            const users_db = firestore.collection(`history${curr_folder.ID}`)
            let subscribe = users_db.where('par_id', '==', history_obj.par_id).where('sub_id', '==', history_obj.sub_id).onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.docs;
                let arr = [];

                for (let i = 0; i < main.length; i++) {
                    arr.push(main[i].data());
                }
                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                console.log('Arr ', arr.length)
                if (arr.length > 0) setHistories([...arr])
                else setHistories([])
            })
            return () => subscribe()
        }
    }, [curr_folder, history_obj])




    function getHistory() {
        let temp = [];
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].par_id == history_obj.par_id && curr_company_docs[i].sub_id == history_obj.sub_id) temp.push({ doc_id: curr_company_docs[i].ID, action: 'Upload', user_id: curr_company_docs[i].author_id, ID: curr_company_docs[i].ID, par_id: curr_company_docs[i].par_id, sub_id: curr_company_docs[i].sub_id, sub_folder_id: curr_company_docs[i].sub_folder_id })
        }
        for (let i = 0; i < histories.length; i++) {
            temp.push({ doc_id: histories[i].doc_id, action: histories[i].action, user_id: histories[i].user_id, ID: histories[i].ID, par_id: histories[i].par_id, sub_id: histories[i].sub_id, sub_folder_id: histories[i].sub_folder_id })
        }
        temp.sort(function (a, b) {
            return b.ID - a.ID
        })
        return temp;
    }

    const par_arr = ['Building Services Management', 'Building Information Management', 'Building Projects']

    // Building Services Management
    // Building Information Management
    // Building Projects

    //function if

    const [admin_users, setAdminUsers] = useState([])

    useEffect(() => {
        const admin_users = db.ref('admin_users')
        let subscribe = admin_users.on('value', (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val())
                arr.sort(function (a, b) {
                    if (a.name != undefined) return a.name.localeCompare(b.name)
                    return a.ID - b.ID
                })
                setAdminUsers([...arr])
            }
            else setAdminUsers([])
        })
        return () => subscribe()
    }, [])

    function make_see(id, val) {
        let temp = [...admin_users]
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].ID == id) {
                temp[i].see = val;
                break;
            }
        }
        setAdminUsers([...temp])
    }

    const [delete_pop, setDeletePopUp] = useState(false)
    const [edit_pop, setEditPop] = useState(false)
    const [edit_mail, setEditMail] = useState('')
    const [pass, setPass] = useState('')
    const [add_pop, setAddPop] = useState(false)

    const [secure, setSecure] = useState(false)

    async function deleteUser() {
        const admin_users = db.ref('admin_users');
        await admin_users.child(String(delete_pop.ID)).remove()
        toast('This user has been deleted')
        setDeletePopUp(false)
    }

    async function make_edit() {
        const admin_users = db.ref('admin_users');
        await admin_users.child(String(edit_pop.ID)).update({ email: encrypt(edit_mail), pass: encryptPass(pass) })
        toast('This user has been updated')
        setEditPop(false)
        setEditMail('')
        setPass('')
    }

    async function make_add() {
        const admin_users = db.ref('admin_users');
        let ID = new Date().getTime()
        await admin_users.child(String(ID)).set({ ID: ID, email: encrypt(edit_mail), pass: encryptPass(pass) })
        toast('This user has been added')
        setAddPop(false)
        setEditMail('')
        setPass('')
    }

    function getStars(str) {
        let ans = '';
        for (let i = 0; i < str.length; i++) {
            ans += '*'
        }
        return ans;
    }

    useEffect(() => {
        if (!edit_pop) {
            setEditMail('')
            setPass('')
        }
    }, [edit_pop])

    useEffect(() => {
        if (!add_pop) {
            setEditMail('')
            setPass('')
        }
    }, [add_pop])

    const [remove_pop, setRemoveApp] = useState(false)
    const [removal, setRemoval] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])

    function handleCheckOnly(index, val) {
        const nextCounters = removal.map((c, i) => {
            if (i === index) {
                // Increment the clicked counter
                return val;
            } else {
                // The rest haven't changed
                return c;
            }
        });
        setRemoval([...nextCounters]);
    }

    function checkAnyTrue() {
        for (let i = 0; i < removal.length; i++) {
            if (removal[i] == true) return false
        }
        return true
    }

    async function delete_checked_folders() {
        setLoading(true)
        let arr = getUsersOnly(remove_pop.user_id)
        let obj = { ...user_obj }
        let updates = {}
        for (let i = 0; i < arr.length; i++) {
            if (removal[i]) { delete obj[(arr[i].ID)]; updates[String(arr[i].ID)] = null }
        }

        //remove from userdb
        await userdb.child(String(remove_pop.user_id)).child('permission').update(updates)

        // remove from users from firestore 
        const user_db_firestore = firestore.collection(`users_db`).doc(String(curr_folder.ID))
        await user_db_firestore.set(obj)
        setLoading(false)
        setRemoveApp(false)
        toast('This user has been deleted from selected folders')

    }

    const [loading, setLoading] = useState(false)

    async function delete_from_full_company() {
        setLoading(true)
        let arr = getUsersOnly()
        let obj = { ...user_obj }
        let updates = {}
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].user_id == remove_pop.user_id) { delete obj[(arr[i].ID)]; updates[String(arr[i].ID)] = null }
        }
        console.log('New ', updates)
        //remove from userdb
        //remove from userdb
        await userdb.child(String(remove_pop.user_id)).child('permission').update(updates)

        // remove from users from firestore 
        const user_db_firestore = firestore.collection(`users_db`).doc(String(curr_folder.ID))
        await user_db_firestore.set(obj)
        setLoading(false)
        setRemoveApp(false)
        toast(`This user has been deleted from ${curr_folder.name}`)
    }

    function getUsersObj(obj) {
        let arr = Object.values(obj);
        let new_obj = obj;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].user_id == remove_pop.user_id) {
                delete new_obj[String(arr[i].ID)]
            }
        }
        return new_obj;
    }

    async function delete_from_all_company() {
        // remove from all companies 
        setLoading(true)
        let obj = await userdb.child(String(remove_pop.user_id)).child('permission').once('value')
        let arr = obj.val() != null ? Object.values(obj.val()) : [];
        for (let i = 0; i < arr.length; i++) {
            let new_obj = (await firestore.collection(`users_db`).doc(String(arr[i].company_id)).get()).data()
           // console.log('Object got ',new_obj)
            if (new_obj != undefined && new_obj != null) {
                new_obj = getUsersObj(new_obj)
                await firestore.collection(`users_db`).doc(String(arr[i].company_id)).set(new_obj)
            }
        }

        // remove all companies 
        await userdb.child(String(remove_pop.user_id)).child('permission').remove()
        setLoading(false)
        setRemoveApp(false)
        toast('This user has been deleted from whole system')
    }

    return (
        <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', width: width, height: height, position: 'absolute', top: 0, left: 0, overflowX: 'hidden' }}>


            <input id="actual" multiple ref={refs} accept="/*" type="file" onChange={handleDrop} hidden={true} />


            <div style={{ backgroundColor: 'white', display: 'flex', position: 'absolute', top: 0, left: 0, flexDirection: 'column', height: height, width: width * 0.20, textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: left_bar, width: width * 0.20, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src={logo1}
                        alt="Logo"
                        style={{ width: 230, marginTop: 1, height: 68, alignSelf: 'center', objectFit: 'contain', borderRadius: 4 }}
                    />
                </div>

                <div onClick={() => { navigate('/AllFiles') }} style={{ cursor: 'pointer', marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 0.18 * width, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{files_inactive_icon}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '600', fontFamily: 'Montserrat' }}>All files</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Favourites')} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{favourite_inactive}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Favourites</text>
                    </div>
                </div>



                <div onClick={() => { setCurrFolder(null) }} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center', backgroundColor: '#0D1C41' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{user_active}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Users</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Activities')} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{inactivity_icon}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Activities</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Admin')} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{inactivity_admin}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Admin</text>
                    </div>
                </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: width * 0.20, width: width * 0.80, position: 'absolute', top: 0, height: height, backgroundColor: 'white' }}>

                <div style={{ height: 100, width: width * 0.80, justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 5, height: 54, width: 300 }}>
                        {curr_folder ? <div style={{ cursor: 'pointer', height: 46, width: 46, borderRadius: 8, justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F5F5', display: 'flex' }}>
                            {curr_folder ? <FaAngleLeft onClick={() => setCurrFolder(null)} color='black' size={20} /> : null}
                        </div> : null}
                        <text style={{ marginLeft: 8, fontSize: 22, fontWeight: '600', letterSpacing: 1.01, color: 'black', textAlign: 'left', fontFamily: 'Montserrat' }}>{curr_folder == null ? 'All files' : curr_folder.name + " / Users"}</text>
                    </div>
                    <div style={{ display: 'flex', width: 0.2618 * width, height: 46, alignSelf: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                        <div style={{ width: 0.2159 * width, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 0 }}>
                            <div style={{ height: 46, width: 46, borderRadius: 8, justifyContent: 'center', alignItems: 'center', display: 'flex', marginRight: 8 }}>

                            </div>
                            <input placeholder='Search' style={{ alignSelf: 'center', fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 12, backgroundColor: '#F5F5F5', width: 0.1388 * width, height: 44, paddingLeft: 10, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, outline: 'none', borderStyle: 'none' }} />
                            <div style={{ width: 0.0215 * width, height: 46, display: 'flex', alignSelf: 'center', alignItems: 'center', backgroundColor: '#F5F5F5', borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
                                <img src={Search} style={{ width: 24, height: 24, alignSelf: 'center' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: width * 0.80, display: 'flex', flexDirection: 'column', alignSelf: 'center', backgroundColor: '#F5F6FA', height: height - 100, overflowY: 'scroll' }}>
                    {curr_folder == null && (folders.length > 0 ? <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, width: '95%', alignSelf: 'center', maxHeight: '95%', flexWrap: 'wrap' }}>
                        <FlatList
                            list={folders}
                            renderItem={renderPerson}
                        />
                    </div> : <text style={{ marginTop: 40, textAlign: 'center', fontSize: 20, fontWeight: '600', fontFamily: 'Montserrat' }}>You have no folder yet</text>)}

                    {curr_folder != null && <div style={{ width: '95%', alignSelf: 'center', display: 'flex', flexDirection: 'column', height: height - 200 }}>
                        {(curr_folder.name != 'Admin Users' && getFullCompanyUsers(curr_folder.ID).length > 0) ? <div style={{ width: '98%', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 60, display: 'flex' }}>
                            <text style={{ width: '30%', color: '#5E5E5E', fontSize: 15, fontFamily: 'Montserrat' }}>Name</text>
                            <text style={{ width: '35%', color: '#5E5E5E', fontSize: 15, fontFamily: 'Montserrat' }}>Path</text>
                            <text style={{ width: '20%', color: '#5E5E5E', fontSize: 15, fontFamily: 'Montserrat' }}>Date</text>
                            <text style={{ width: '7%', color: '#5E5E5E', fontSize: 15, fontFamily: 'Montserrat' }}>Access</text>
                            <div style={{ width: '7%', color: '#5E5E5E', fontSize: 15, fontFamily: 'Montserrat' }}>Action</div>
                        </div> : null}

                        {curr_folder.name != 'Admin Users' && <div style={{ width: '99%', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: 320, overflowY: 'scroll', marginLeft: 20, overflowX: 'hidden' }}>
                            {getFullCompanyUsers(curr_folder.ID).map((item, indx) => <div style={{ marginTop: 6, width: '100%', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', height: 50, backgroundColor: 'white', justifyContent: 'space-between' }}>
                                <text style={{ width: '30%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{(item.email)}</text>
                                <text style={{ width: '35%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{(item.path)}</text>
                                <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{today_dats(item.ID)}</text>
                                <text style={{ width: '7%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{item.role}</text>
                                <div style={{ width: '7%', color: '#5E5E5E', fontSize: 15, fontFamily: 'Montserrat', cursor: 'pointer' }}><MdOutlineDelete onClick={() => { setRemoveApp(item) }} size={20} color='black' /></div>
                            </div>)}
                        </div>}

                        {(curr_folder != null && curr_folder.name != 'Admin Users' && getFullCompanyUsers(curr_folder.ID).length == 0) ? <text style={{ marginTop: 200, textAlign: 'center', fontWeight: '600', fontSize: 16, color: 'black' }}>This folder has no user</text> : null}

                        {curr_folder != null && curr_folder.name == 'Admin Users' && <div style={{ marginTop: 50 }}>
                            {admin_users.map((item, indx) => <div style={{ alignSelf: 'center', width: 500, display: 'flex', height: 50, backgroundColor: 'white', borderRadius: 8, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: 10 }}>
                                <text style={{ color: 'black', fontWeight: '600', fontSize: 13, width: 200, textAlign: 'left' }}>{decrypt(item.email)}</text>
                                <text style={{ color: 'black', fontWeight: '600', fontSize: 13, width: 120, textAlign: 'left' }}>{(item.see != undefined && item.see) ? decryptPass(item.pass) : getStars(item.pass)}</text>
                                {(item.see == undefined || item.see == false) ? <IoMdEye onClick={() => make_see(item.ID, true)} size={20} color='black' /> : <IoMdEyeOff onClick={() => make_see(item.ID, false)} size={20} color='black' />}
                                <AiTwotoneEdit onClick={() => { setEditPop(item); setEditMail(decrypt(item.email)); setPass(decryptPass(item.pass)) }} size={20} color='black' />
                                <MdOutlineDelete onClick={() => setDeletePopUp(item)} size={20} color='black' />
                            </div>)}

                        </div>}


                    </div>}

                </div>

            </div>

            <Modal
                open={delete_pop != false}
                onClose={() => setDeletePopUp(false)}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20 }}>  Are you sure you want to delete this ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={() => deleteUser()} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeletePopUp(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#8455f6", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                open={remove_pop != false}
                onClose={() => {setRemoveApp(false);setLoading(false)}}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 600, alignContent: 'center', display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                    <text style={{ textAlign: 'center', marginTop: 8, marginBottom: 6, color: '#0D1C41', fontWeight: '700', fontSize: 18 }}>Remove {remove_pop.email} From {curr_folder != null && curr_folder.name}</text>
                    {getUsersOnly(remove_pop.user_id).map((item, indx) =>
                        <div onClick={() => handleCheckOnly(indx, removal[indx] ? false : true)} style={{ width: 580, alignSelf: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#F5F6FA', height: 50, borderRadius: 4, marginTop: 4, paddingLeft: 5, paddingRight: 5 }}>
                            <text style={{ fontWeight: '700', fontSize: 13, color: 'black', textAlign: 'left', width: '45%' }}>{item.email}</text>
                            <text style={{ fontWeight: '700', fontSize: 13, color: 'black', textAlign: 'left', width: '45%' }}>{item.path}</text>
                            <FaCheckCircle size={20} color={removal[indx] ? 'green' : 'gray'} style={{ cursor: 'pointer' }} />
                        </div>)}
                    <div style={{ width: 580, alignSelf: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ width: 300, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 7 }}>
                            <ReactiveButton
                                idleText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>Delete the checked folders</text>}
                                successText="Done"
                                disabled={checkAnyTrue() || loading}
                                onClick={() => { delete_checked_folders() }}
                                style={{ backgroundColor: left_bar, width: 250, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center' }}
                            />
                        </div>
                        <div style={{ width: 300, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 7 }}>
                            <ReactiveButton
                                idleText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>Delete user from company</text>}
                                successText="Done"
                                disabled={loading}
                                onClick={() => { delete_from_full_company() }}
                                style={{ backgroundColor: left_bar, width: 250, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center' }}
                            />
                        </div>
                    </div>
                    <div style={{ width: 500, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 7 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>Delete user from all companies</text>}
                            successText="Done"
                            disabled={loading}
                            onClick={() => { delete_from_all_company() }}
                            style={{ backgroundColor: left_bar, width: 450, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center' }}
                        />
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <RiseLoader color={'#36D7B7'} isLoading={true}
                            css={override} size={20} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                    </div>}
                </div>
            </Modal>

            <Modal
                open={edit_pop != false}
                onClose={() => setEditPop(false)}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontFamily: 'Montserrat', fontSize: 20 }}>Edit details </text>
                    </div>

                    <input placeholder='Email' value={edit_mail} onChange={(e) => setEditMail(e.currentTarget.value)} style={{ fontFamily: 'Montserrat', width: 350, alignSelf: 'center', height: 45, borderStyle: 'solid', outline: 'none', borderWidth: 1, borderColor: 'black', borderRadius: 6, marginTop: 15 }} />

                    <div style={{ width: 354, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: 'white', borderRadius: 6, justifyContent: 'space-between', marginTop: 4, borderStyle: 'solid', borderWidth: 1, borderColor: 'black', }}>
                        <input placeholder='Password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ borderStyle: 'none', paddingLeft: 4, fontFamily: 'Montserrat', outline: 'none', height: 42, width: 295, outline: 'none', marginLeft: 4 }} />
                        <div style={{ width: 50 }}>
                            {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} />}
                        </div>
                    </div>

                    <div onClick={() => make_edit()} style={{ cursor: 'pointer', width: 175, padding: 10, backgroundColor: '#0D1C41', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                        <text style={{ color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Update</text>
                    </div>
                </div>
            </Modal>

            <Modal
                open={add_pop != false}
                onClose={() => { setAddPop(false) }}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontFamily: 'Montserrat', fontSize: 20 }}>Add User </text>
                    </div>

                    <input placeholder='Email' value={edit_mail} onChange={(e) => setEditMail(e.currentTarget.value)} style={{ fontFamily: 'Montserrat', paddingLeft: 4, width: 350, alignSelf: 'center', height: 45, borderStyle: 'solid', outline: 'none', borderWidth: 1, borderColor: 'black', borderRadius: 6, marginTop: 15 }} />


                    <div style={{ width: 354, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: 'white', borderRadius: 6, justifyContent: 'space-between', marginTop: 4, borderStyle: 'solid', borderWidth: 1, borderColor: 'black', }}>
                        <input placeholder='Password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ borderStyle: 'none', paddingLeft: 4, fontFamily: 'Montserrat', outline: 'none', height: 42, width: 295, outline: 'none', marginLeft: 4 }} />
                        <div style={{ width: 50 }}>
                            {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} />}
                        </div>
                    </div>

                    <div onClick={() => make_add()} style={{ cursor: 'pointer', width: 175, padding: 10, backgroundColor: '#0D1C41', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                        <text style={{ color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Add</text>
                    </div>
                </div>
            </Modal>

            {curr_folder != null && curr_folder.name == 'Admin Users' && <div onClick={() => setAddPop(true)} style={{ position: 'absolute', bottom: 30, right: 30, height: 46, width: 46, borderRadius: 23, backgroundColor: '#0D1C41', display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                <IoMdAdd color='white' size={25} style={{ marginTop: 10 }} />
            </div>}


        </div>
    );
}

const override = `
display: block;
margin: 0 auto;
border-color: red;
alignSelf:center
`;


const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps)(Users)

