
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/square_logo.png'
import logo1 from './promas1.png'
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast'
//import 'react-toastify/dist/ReactToastify.css';
import { db, firestore, storage } from '../config';
import { favourite_active, favourite_inactive, files_active_icon, files_inactive_icon, link_inactive, settings_inactive, tags_inactive, user_active, user_inactive } from '../assets/Icons';
import { FaAngleLeft, FaFile } from "react-icons/fa6";
import FlatList from 'flatlist-react';
import Pic from './Pic.png'
import Search from './search.png'
import menu1 from './menu1.png'

import arrow2 from './arrow2.png'
import arrow3 from './arrow3.png'
import rectangle from './Rectangle.png'
import Component1 from './Component1.png'
import Component2 from './Component2.png'
import Component3 from './Component3.png'

import ProgressBar from "@ramonak/react-progress-bar";

import D1 from './D1.png'
import D2 from './D2.png'
import D3 from './D3.png'
import D4 from './D4.png'
import D5 from './D5.png'
import D6 from './D6.png'
import D7 from './D7.png'
import D8 from './D8.png'
import ReactModal from 'react-modal';

import { FaPlus } from "react-icons/fa6";
import { FaPlusCircle } from 'react-icons/fa';
import { IoMdClose, IoMdSearch, IoMdShare } from 'react-icons/io';
import ReactiveButton from 'reactive-button';

import { Bounce } from "react-activity";
import "react-activity/dist/library.css";
import { FaFolder } from "react-icons/fa";

import { IoMdEye } from "react-icons/io";
import { BsUpload } from "react-icons/bs";
import Arrow from './arrow1.png'
import icon from './icon.png'
import { MdAdminPanelSettings, MdHistory } from 'react-icons/md';

import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import Select, { components } from 'react-select';
import { decrypt, encrypt, encryptPass } from '../Crypto';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { RxActivityLog } from 'react-icons/rx';

const fav_icon = <FaStar size={25} color='black' />
const non_fav_icon = <FaRegStar size={25} color='black' />

const activity_icon = <RxActivityLog size={17} color='white' />
const inactivity_icon = <RxActivityLog size={17} color='#A2B0BF' />

const admin_icon = <MdAdminPanelSettings size={20} color='white' />
const inactivity_admin = <MdAdminPanelSettings size={20} color='#A2B0BF' />

const left_bar = '#0D1C41'

const folder_db = db.ref('folders');
//const subfolder_db = db.ref('sub_folders');

const close_button = <IoMdClose size={20} color={'black'} />

const search_icons = <RiArrowDropDownLine color='#0b6d0f' size={27} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={27} />

const userdb = db.ref('userdb')

const company_sub_folder_db = db.ref('company_sub_folders');

function Activities({ items }) {

    console.log('Items ', items)

    let myobj = items.myid;

    const navigate = useNavigate();

    const [full_access_company, setFullAccessCompany] = useState([])

    const [sharing_curr_folder, setSharingCurrFolder] = useState(null)
    const [company_sub_folders, setCompanySubFolders] = useState(null)

    const arr1 = ['Asbestos', 'Fire Risk', 'Drawings & Plans', 'Health & Safety File', 'Safety Inspections', 'Other Surveys']


    useEffect(() => {
        if (sharing_curr_folder != null) {
            let subscribe = company_sub_folder_db.orderByChild('folder_id').equalTo(Number(sharing_curr_folder.ID)).on('value', (snapshot) => {
                if (snapshot != undefined && snapshot.val() != null) {
                    let arr = Object.values(snapshot.val())
                    setCompanySubFolders([...arr])
                }
                else setCompanySubFolders([])
            })
            return () => subscribe()
        }
    }, [sharing_curr_folder])

    const [width, setwidth] = useState(window.innerWidth);
    const [height, setWindowHeight] = useState(window.innerHeight);
    const [all_obj, setAllObj] = useState([])

    const [all_sub_users, setAllSubUsers] = useState(null)

    async function gettingAllUsersFromCompany() {
        let prom = [];
        let final_arr = [];
        let temp = [];
        for (let i = 0; i < company_sub_folders.length; i++) {
            const users_db = firestore.collection(`users_db`).doc(String(company_sub_folders[i].ID))
            let dat = await users_db.get();
            dat = dat.exists ? dat.data() : null
            temp = (dat != null ? Object.values(dat) : [])
            for (let j = 0; j < temp.length; j++) { temp[j].company_sub_id = company_sub_folders[i].ID; temp[j].company_sub_folder = company_sub_folders[i].name }
            final_arr = final_arr.concat(temp)
        }
        setAllSubUsers([...final_arr]);
    }

    async function gettingAllFoldersFromCompanySub() {
        let prom = [];
        let final_arr = [];
        let temp = [];
        for (let i = 0; i < company_sub_folders.length; i++) {
            const users_db = firestore.collection(`sub_folder`).doc(String(company_sub_folders[i].ID))
            let dat = await users_db.get();
            dat = dat.exists ? dat.data() : null
            temp = (dat != null ? Object.values(dat) : [])
            for (let j = 0; j < temp.length; j++) { temp[j].company_sub_id = company_sub_folders[i].ID; temp[j].company_sub_folder = company_sub_folders[i].name }
            final_arr = final_arr.concat(temp)
        }
        setSubFolders([...final_arr]);
    }

   

    useEffect(() => {
        if (company_sub_folders != null) {
           // gettingAllUsersFromCompany()
           gettingAllFoldersFromCompanySub()
        }
    }, [company_sub_folders])

    function getFullCompanyUsers(company_id) {

        let temp = [];
        let arrt = [...full_access_company]
        for (let i = 0; i < arrt.length; i++) {
            if (arrt[i] != null && arrt[i].company_id == company_id) {
                arrt[i].path = `/ All folders`
                temp.push(arrt[i])
            }
        }

        let arrs = all_sub_users != null ? [...all_sub_users] : [];

        for (let i = 0; i < arrs.length; i++) {
            if (arrs[i] != null) {
                if (arrs[i].full_folder == true) {
                    arrs[i].path = `/${arrs[i].company_sub_folder}`
                }
                else {
                    arrs[i].path = ` / ${arrs[i].company_sub_folder} / ${arrs[i].par_id != null ? (arrs[i].par_id == 0 ? 'Building Services Management' : arrs[i].par_id == 1 ? 'Building Information Management' : 'Building Projects') : null}  /  ${arrs[i].par_id == 0 ? arr[arrs[i].sub_id] : arr1[arrs[i].sub_id]}`
                }
            }
        }

        temp = temp.concat(arrs);

        return temp;

    }

    const [upload_report, setUploadReport] = useState([])

    let report_arr = useRef([])

    function make_report(len) {
        let arr = [];
        for (let i = 0; i < len; i++) arr.push(0)
        setUploadReport([...arr])
        report_arr.current = [...arr]
    }

    // Function to handle image drop
    const handleDrop = (e) => {
        e.preventDefault()
        let temp = [];
        if (e != undefined && e.dataTransfer != undefined && e.dataTransfer.files.length > 0) {
            let files = Array.from(e.dataTransfer.files);
            let temp = []
            files.forEach((file) => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    let name = String(file.name)
                    console.log('Files ', file)
                    name = String(name).substring(0, name.indexOf('.'))
                    let tim = new Date().getTime()
                    let new_obj = { company_id: curr_folder.ID, par_id: upload_obj.par_id, sub_id: upload_obj.sub_id, sub_folder_id: upload_obj.sub_folder_id.ID, author_id: myobj.ID, ID: tim, title: name, uri: null, picture: reader.result, imguri: file, file: String(file.name).substring(String(file.name).indexOf('.') + 1, String(file.name).length) }
                    temp.push(new_obj)
                    let new_temp = temp;
                    for (let i = 0; i < new_temp.length; i++) {
                        new_temp[i].ID = tim + i;
                    }
                    if (new_temp.length == files.length) {
                        setAllObj([...new_temp])
                    }
                    make_report(new_temp.length)
                }
                console.log('All temp ', temp)
                reader.readAsDataURL(file);
            });
            console.log('All temp ', temp)
        }
        else {
            console.log('select ', e.target.files[0])
            let files = Array.from(e.target.files);
            let temp = []
            files.forEach((file) => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    let tim = new Date().getTime()
                    let name = String(file.name)
                    console.log('Files ', file)
                    name = String(name).substring(0, name.indexOf('.'))
                    let new_obj = { company_id: curr_folder.ID, par_id: upload_obj.par_id, sub_id: upload_obj.sub_id, sub_folder_id: upload_obj.sub_folder_id.ID, author_id: myobj.ID, ID: tim, title: name, uri: null, picture: reader.result, imguri: file, file: String(file.name).substring(String(file.name).indexOf('.') + 1, String(file.name).length) }
                    temp.push(new_obj)

                    let new_temp = temp;
                    for (let i = 0; i < new_temp.length; i++) {
                        new_temp[i].ID = tim + i;
                    }

                    if (new_temp.length == files.length) {
                        setAllObj([...new_temp])
                    }
                    make_report(new_temp.length)
                    //setAllObj([...all_obj, new_obj])
                }
                console.log('All temp ', temp)
                reader.readAsDataURL(file);
            });
        }
        //console.log('All temp ', temp)
    }

    const refs = useRef()

    const openFileInput = () => {
        refs.current.click()
    }

    useEffect(() => {
        const windowSizeHandler = () => {
            setwidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [curr_folder, setCurrFolder] = useState(null)

    useEffect(() => {
        if (curr_folder != null) {
            const users_db = firestore.collection(`users_db`).doc(String(curr_folder.ID))
            let subscribe = users_db.onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.data();
                let arr = [];
                if (main != undefined && main != null) {
                    arr = Object.values(main)
                }
                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setAllSubUsers([...arr])
            })
            return () => subscribe()
        }
    }, [curr_folder])

    useEffect(() => {
        const users_db = firestore.collection(`users_db`).doc('admin')
        let subscribe = users_db.onSnapshot(async snapshot => {
            // console.log('Dats ',snapshot.docs[0].data())
            let main = snapshot.data();
            let arr = [];
            if (main != undefined && main != null) {
                arr = Object.values(main)
            }
            arr.sort(function (a, b) {
                return b.ID - a.ID
            })
            setFullAccessCompany([...arr])
        })
        return () => subscribe()
    }, [])

    const [subfolders, setSubFolders] = useState([])

    const [all_users_per_company, setAllUsersPerCompany] = useState([])


    useEffect(() => {
        if (curr_folder != null) {
            const users_db = firestore.collection(`users_db${curr_folder.ID}`)
            let subscribe = users_db.onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.docs;
                let arr = [];

                for (let i = 0; i < main.length; i++) {
                    arr.push(main[i].data());
                }
                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setAllUsersPerCompany([...arr])
            })
            return () => subscribe()
        }
    }, [curr_folder])


    useEffect(() => {
        let subscribe = folder_db.on('value', (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val())
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                })
                setFolders([...arr])
            }
            else setFolders([])
        })
        return () => subscribe()
    }, [])



    const renderPerson = (person, id) => {
        return (
            getCompanyAccess(person.ID) ? <div onClick={() => { setCurrFolder(person); setSharingCurrFolder(person) }} key={id} style={{ cursor: 'pointer', padding: 4 }} >
                <div style={{ display: 'flex', width: 270, height: 270, flexDirection: 'column', justifyContent: 'start', alignSelf: 'center', backgroundColor: 'white', borderRadius: 8 }}>
                    <img src={person.uri} onClick={() => ('/')} style={{ width: 230, height: 235, alignSelf: 'center', marginTop: 5, borderRadius: 8 }} />
                    <text style={{ textAlign: 'center', fontWeight: '600', color: 'black', fontSize: 16 }}>{person.name}</text>
                </div>
            </div> : null
        );
    }

    // const folders = [{ 'ID': 1, name: 'HSB' }, { 'ID': 2, name: 'HSBC' }, { 'ID': 2, name: 'HSC' }]
    const [folders, setFolders] = useState([])

    const ref = useRef()

    const arr = ['Lightening Protection', 'Fire', 'Electrical', 'Mechanical', 'Catering Equipment', 'Security Alarm', 'Legionella & Water Hygiene', 'Lifts & Lifting Equipment']

    function getName(indx) {
        return arr[indx];
    }


    const [upload_obj, setUploadObj] = useState(null)

    const [curr_company_docs, setCurrCompanyDocs] = useState([])

    async function gettingAllDocsPerSubCompany() {
        let temp_all = [];
        let temp = [];
        let fire_temp;

        const doc_db = firestore.collection(`documents`)

        for (let i = 0; i < company_sub_folders.length; i++) {
            fire_temp = await doc_db.where('company_sub_id', '==', company_sub_folders[i].ID).get()
            fire_temp = fire_temp.docs
            console.log('Fire temp ', fire_temp.length)
            for (let j = 0; j < fire_temp.length; j++) {
                temp.push(fire_temp[j].data())
            }
            temp_all = temp_all.concat(temp)
            temp = []
        }
        console.log('All ', temp_all)
        setCurrCompanyDocs([...temp_all])
    }

    useEffect(() => {
        if (company_sub_folders != null) {
            console.log('All folders ', company_sub_folders)
            gettingAllDocsPerSubCompany()
        }
    }, [company_sub_folders])


    function getCompanyAccess(id) {
        let arr = items.projects
        if (arr[id] == undefined && myobj.ID !== 12345678&& myobj.role!='Master') return false;
        else return true;
    }


    function getDocName(Id) {
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].ID == Id) return curr_company_docs[i].title
        }
        return null;
    }

    function getDocFile(Id) {
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].ID == Id) return curr_company_docs[i].file
        }
        return null;
    }


    function today_dats(str) {
        // return new Date(str).getDate() + " " + months[new Date(str).getMonth()] + " " + new Date().getFullYear() + " / " + moment(new Date(str)).format('hh : mm A')
        return moment(new Date(str)).format('DD-MM-YYYY') + " at " + moment(new Date(str)).format('hh : mm A')
    }

    const [histories, setHistories] = useState([])

    async function allCompanySubFoldersHistory() {

        let curr_year = new Date();
        curr_year.setMonth(0);
        curr_year.setDate(0);
        curr_year.setHours(0);
        curr_year.setMinutes(0)
        curr_year.setSeconds(0)
        curr_year.setMilliseconds(0)
        curr_year = curr_year.getTime()

        let all_temp = [];
        for (let i = 0; i < company_sub_folders.length; i++) {
            const users_db = firestore.collection(`history${company_sub_folders[i].ID}`).doc(String(curr_year))
            let temp = await users_db.get()
            temp = temp.data()
            if (temp != undefined && temp != null) {
                temp = Object.values(temp)
                all_temp = all_temp.concat(temp)
            }
        }
        console.log('Historyu ', all_temp)
        setHistories([...all_temp])
    }


    useEffect(() => {
        if (company_sub_folders != null) {
            allCompanySubFoldersHistory()
        }
    }, [company_sub_folders])



    function getHistory() {
        let temp = [];
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].company_id == curr_folder.ID) temp.push({ company_sub_id: curr_company_docs[i].company_sub_id, doc_id: curr_company_docs[i].ID, action: 'Upload', user_id: curr_company_docs[i].author_id, ID: curr_company_docs[i].ID, par_id: curr_company_docs[i].par_id, sub_id: curr_company_docs[i].sub_id, sub_folder_id: curr_company_docs[i].sub_folder_id,par_sub_folder:histories[i].par_sub_folder,par_sub_sub_folder:histories[i].par_sub_sub_folder })
        }
        for (let i = 0; i < histories.length; i++) {
            temp.push({ company_sub_id: histories[i].company_sub_id, doc_id: histories[i].doc_id, action: histories[i].action, user_id: histories[i].user_id, ID: histories[i].ID, par_id: histories[i].par_id, sub_id: histories[i].sub_id, sub_folder_id: histories[i].sub_folder_id,par_sub_folder:histories[i].par_sub_folder,par_sub_sub_folder:histories[i].par_sub_sub_folder })
        }
        temp.sort(function (a, b) {
            return b.ID - a.ID
        })
        return temp;
    }

    const par_arr = ['Building Services Management', 'Building Information Management', 'Building Projects']

    // Building Services Management
    // Building Information Management
    // Building Projects

    //function if

    function getUserName(Id) {
        for (let i = 0; i < all_sub_users.length; i++) {
            if (all_sub_users[i].user_id == Id) return all_sub_users[i].email
        }
        return Id == 5555 ? 'QR code users' : `Admin users`
    }

    function getDocFile(Id) {
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].ID == Id) return curr_company_docs[i].file
        }
        return null;
    }

    function getFolderName(Id) {
        for (let i = 0; i < subfolders.length; i++) {
            //console.log('fOLDER NAME ', subfolders[i].name, subfolders[i].ID, '  ', Id)
            if (subfolders[i].ID == Id) return subfolders[i].name
        }
        return null;
    }


    function getFolderNameParSub(Id) {
        for (let i = 0; i < subfolders.length; i++) {
            //console.log('fOLDER NAME ', subfolders[i].name, subfolders[i], '  ', Id)
            if (subfolders[i].ID == Id) return getFolderName(subfolders[i].sub_id)
        }
        return null;
    }

    function getFolderNameParSubSub(Id) {
        console.log('Pub ',Id)
        for (let i = 0; i < subfolders.length; i++) {
            //console.log('fOLDER NAME ', subfolders[i].name, subfolders[i], '  ', Id)
            if (subfolders[i].ID == Id) return getFolderName(subfolders[i].ID)
        }
        return 'Folder';
    }

    function getCompanySubFolderName(Id) {
        for (let i = 0; i < company_sub_folders.length; i++) {
            if (company_sub_folders[i].ID == Id) return company_sub_folders[i].name
        }
        return 'Folder';
    }

    function getFullPath(item) {
        let path = getCompanySubFolderName(item.company_sub_id) + '/' + item.par_id == 0 ? 'Building Services Management' : item.par_id == 1 ? 'Building Information Management' : 'Building Projects' + ' /' + item.par_id == 0 ? arr[item.sub_id] : arr1[item.sub_id] + item.sub_id != null ? '/' : null + getFolderName(item.sub_folder_id) + "/" + item.par_sub_sub_folder == null ? '' : getFolderName(item.par_sub_sub_folder)
        return path
    }


    return (
        <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', width: width, height: height, position: 'absolute', top: 0, left: 0, overflowX: 'hidden' }}>


            <input id="actual" multiple ref={refs} accept="/*" type="file" onChange={handleDrop} hidden={true} />


            <div style={{ backgroundColor: 'white', display: 'flex', position: 'absolute', top: 0, left: 0, flexDirection: 'column', height: height, width: width * 0.20, textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: left_bar, width: width * 0.20, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src={logo1}
                        alt="Logo"
                        style={{ width: 230, marginTop: 1, height: 68, alignSelf: 'center', objectFit: 'contain', borderRadius: 4 }}
                    />
                </div>

                <div onClick={() => { navigate('/AllFiles') }} style={{ cursor: 'pointer', marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 0.18 * width, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{files_inactive_icon}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '600', fontFamily: 'Montserrat' }}>All files</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Favourites')} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{favourite_inactive}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Favourites</text>
                    </div>
                </div>



                <div onClick={() => { navigate('/Users') }} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Users</text>
                    </div>
                </div>

                <div onClick={() => { setCurrFolder(null) }} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center', backgroundColor: '#0D1C41' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{activity_icon}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Activities</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Admin')} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * width, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * width, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{inactivity_admin}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Admin</text>
                    </div>
                </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: width * 0.20, width: width * 0.80, position: 'absolute', top: 0, height: height, backgroundColor: 'white' }}>

                <div style={{ height: 100, width: width * 0.80, justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 5, height: 54, width: 450 }}>
                        {curr_folder ? <div style={{ cursor: 'pointer', height: 46, width: 46, borderRadius: 8, justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F5F5', display: 'flex' }}>
                            {curr_folder ? <FaAngleLeft onClick={() => setCurrFolder(null)} color='black' size={20} /> : null}
                        </div> : null}
                        <text style={{ marginLeft: 8, fontSize: 22, fontWeight: '600', letterSpacing: 1.01, color: 'black', textAlign: 'left', fontFamily: 'Montserrat' }}>{curr_folder == null ? 'All files' : curr_folder.name + " / Activities"}</text>
                    </div>
                    <div style={{ display: 'flex', width: 0.2618 * width, height: 46, alignSelf: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                        <div style={{ width: 0.2159 * width, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 0 }}>
                            <div style={{ height: 46, width: 46, borderRadius: 8, justifyContent: 'center', alignItems: 'center', display: 'flex', marginRight: 8 }}>

                            </div>
                            <input placeholder='Search' style={{ alignSelf: 'center', fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 12, backgroundColor: '#F5F5F5', width: 0.1388 * width, height: 44, paddingLeft: 10, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, outline: 'none', borderStyle: 'none' }} />
                            <div style={{ width: 0.0215 * width, height: 46, display: 'flex', alignSelf: 'center', alignItems: 'center', backgroundColor: '#F5F5F5', borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
                                <img src={Search} style={{ width: 24, height: 24, alignSelf: 'center' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: width * 0.80, display: 'flex', flexDirection: 'column',  alignSelf: 'center', backgroundColor: '#F5F6FA', height: height - 100, overflowY: 'scroll' }}>
                    {curr_folder == null && (folders.length > 0 ? <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, width: '95%', alignSelf: 'center', maxHeight: '95%', flexWrap: 'wrap'  }}>
                        <FlatList
                            list={folders}
                            renderItem={renderPerson}
                        />
                    </div> : <text style={{ marginTop: 40, textAlign: 'center', fontSize: 20, fontWeight: '600', fontFamily: 'Montserrat' }}>You have no folder yet</text>)}

                    {curr_folder != null && <div style={{ width: '95%', alignSelf: 'center', display: 'flex', flexDirection: 'column', height: height - 200 }}>
                        {getHistory().length > 0 ? <div style={{ width: '98%', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 60, display: 'flex' }}>
                            <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>User</text>
                            <text style={{ width: '15%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Folder</text>
                            <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Title</text>
                            <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Date</text>
                            <text style={{ width: '10%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Action</text>
                        </div> : null}
                        <div style={{ width: '99%', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: height - 350, overflowY: 'scroll', marginLeft: 20, overflowX: 'hidden' }}>
                            {getHistory().map((item, indx) => <div style={{ marginTop: 6, width: '100%', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', minHeight: 100, backgroundColor: 'white', justifyContent: 'space-between' }}>
                                <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{getUserName(item.user_id)}</text>
                                <text style={{ width: '15%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{getCompanySubFolderName(item.company_sub_id)} / {item.par_id == 0 ? 'Building Services Management' : item.par_id == 1 ? 'Building Information Management' : 'Building Projects'}  / {item.par_id == 0 ? arr[item.sub_id] : arr1[item.sub_id]} {(arr[item.sub_id] != undefined||arr1[item.sub_id] != undefined) ? '/' : null} {getFolderNameParSub(item.sub_folder_id)} / {getFolderName(item.sub_folder_id)}  {item.par_sub_sub_folder != null ? '/' : null} {item.par_sub_sub_folder == null ? '' : getFolderName(item.par_sub_sub_folder)} {item.par_sub_sub_folder != null ? '/' : null} </text>
                                <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{getDocName(item.doc_id)}.{getDocFile(item.doc_id)}</text>
                                <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{today_dats(item.ID)}</text>
                                <text style={{ width: '10%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{item.action}</text>
                            </div>)}
                        </div>

                        {getHistory().length == 0 ? <text style={{ marginTop: 200, textAlign: 'center', fontWeight: '600', fontSize: 16, color: 'black' }}>This folder has no activities</text> : null}

                    </div>}

                </div>

            </div>


        </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps)(Activities)

