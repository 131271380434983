
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast'
//import 'react-toastify/dist/ReactToastify.css';
import { db, firestore, storage } from '../config';
import { FaAngleLeft, FaFile } from "react-icons/fa6";
import FlatList from 'flatlist-react';

import arrow2 from './arrow2.png'
import arrow3 from './arrow3.png'
import Component1 from './Component1.png'
import Component2 from './Component2.png'
import Component3 from './Component3.png'

import ProgressBar from "@ramonak/react-progress-bar";

import D1 from './D1.png'
import D2 from './D2.png'
import D3 from './D3.png'
import D4 from './D4.png'
import D5 from './D5.png'
import D6 from './D6.png'
import D7 from './D7.png'
import D8 from './D8.png'
import ReactModal from 'react-modal';

import { FaPlus } from "react-icons/fa6";
import { IoMdClose, IoMdSearch, IoMdShare } from 'react-icons/io';
import ReactiveButton from 'reactive-button';

import { Bounce } from "react-activity";
import "react-activity/dist/library.css";
import { FaFolder } from "react-icons/fa";

import { IoMdEye } from "react-icons/io";
import Arrow from './arrow1.png'
import icon from './icon.png'
import { MdAdminPanelSettings, MdHistory } from 'react-icons/md';

import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import Select, { components } from 'react-select';
import { encrypt } from '../Crypto';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

import { GoAlert } from "react-icons/go";

import D22 from './D22.png'
import D33 from './D33.png'
import D44 from './D44.png'
import D55 from './D55.png'
import D66 from './D66.png'
import { RxActivityLog } from 'react-icons/rx';

import 'reactjs-popup/dist/index.css';

import { Collapse } from 'react-collapse';
import Collapsible from 'react-collapsible';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { RiseLoader } from 'react-spinners';
import { isSafari } from 'react-device-detect';
//import PDFViewer from 'pdf-viewer-reactjs'
//import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
//import "@cyntler/react-doc-viewer/dist/index.css";
//import Pdf from './Pdfs';
//import Pdfs from './Pdfs';
import FileViewer from 'react-file-viewer';
import PdfViewer from './PdfViewer';
import { AiOutlineClose } from "react-icons/ai";
//import Pdfs from './Pdfs';
//import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

//import DocViewer from "react-doc-viewer";


const D11 = <GoAlert size={95} color='#ffc5a1' />

const fav_icon = <FaStar size={25} color='black' />
const non_fav_icon = <FaRegStar size={25} color='black' />

const activity_icon = <RxActivityLog size={17} color='white' />
const inactivity_icon = <RxActivityLog size={17} color='#A2B0BF' />

const admin_icon = <MdAdminPanelSettings size={20} color='white' />
const inactivity_admin = <MdAdminPanelSettings size={20} color='#A2B0BF' />

const left_bar = '#0D1C41'

const folder_db = db.ref('folders');
const company_sub_folder_db = db.ref('company_sub_folders');
//const subfolder_db = db.ref('sub_folders');

const close_button = <IoMdClose size={20} color={'black'} />

const search_icons = <RiArrowDropDownLine color='#0b6d0f' size={27} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={27} />

const userdb = db.ref('userdb')

const override = `
display: block;
margin: 0 auto;
border-color: red;
alignSelf:center
`;

function Particular_folder({ items }) {


    let myobj = { ID: 5555 }


    const navigate = useNavigate();

    const [user_access, setUserAccess] = useState('')

    const [permission_main_folder, setPermissionMainFolder] = useState(123)
    const [permission_main_sub_folder, setPermissionMainSubFolder] = useState(123)
    const [scan_sub, setScanSub] = useState(null)
    const [particular, setParticular] = useState(null)

    const url = window.location.href;

    useEffect(() => {
        if (url.includes('?')) {
            let id = String(url).substring(url.indexOf('?') + 1, String(url).indexOf('%'))
            setPermissionMainFolder(id)
            id = String(url).substring(url.indexOf('%') + 1, String(url).indexOf('&'))
            setPermissionMainSubFolder(id)
            id = String(url).substring(url.indexOf('&') + 1, String(url).indexOf('&&'))
            setScanSub(id)
            id = String(url).substring(url.indexOf('&&') + 2, String(url).length)
            console.log('Particular ', id)
            setParticular(id)
        }
    }, [url])


    const [width, setwidth] = useState(window.innerWidth);
    const [height, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const windowSizeHandler = () => {
            setwidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [loading, setLoading] = useState(false)
    const [all_obj, setAllObj] = useState([])

    const [upload_report, setUploadReport] = useState([])

    const updateAllTitle = (index, e) => {
        let newArr = [...all_obj]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        let obj = newArr[index]
        obj.title = e
        newArr[index] = obj; // replace e.target.value with whatever you want to change it to

        setAllObj([...newArr]);
    }

    let report_arr = useRef([])

    function make_report(len) {
        let arr = [];
        for (let i = 0; i < len; i++) arr.push(0)
        setUploadReport([...arr])
        report_arr.current = [...arr]
    }


    const uploadImage1 = async (key, imguri, indx) => {
        const upload = storage.ref(`/images/${key}`).put(imguri)
        upload.on("state_changed", (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progress = Math.trunc(progress)
            updateAllUpload(indx, progress)
            console.log("upload is" + progress + "% done");
            switch (snapshot.state) {
                case "paused":
                    console.log("Upload paused");
                    break;
                case "running":
                    console.log("Upload running");
                    break;
                default:
                    break;
            }
        },
            (error) => {
                console.log(error);
                return null;
            }
        );

        await Promise.resolve(upload)
        let uri = await storage.ref(`/images/${key}`).getDownloadURL();
        console.log('Uri ', uri)
        return uri

    };

    const updateAllUpload = (index, val) => {
        let newArr = report_arr.current; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index] = val; // replace e.target.value with whatever you want to change it to
        setUploadReport([...newArr]);
    }

    async function firstUpload(indx) {
        if (all_obj[indx].title != '') {

            let final_obj = all_obj[indx]

            let uri = await uploadImage1(all_obj[indx].ID, all_obj[indx].imguri, indx)
            console.log('Got uri ', uri)
            final_obj.uri = uri

            delete final_obj.imguri
            delete final_obj.upload
            delete final_obj.picture
            delete final_obj.mark

            delete final_obj.replace
            delete final_obj.parent


            // create docs
            const doc_db = firestore.collection(`documents`)
            await doc_db.doc(String(all_obj[indx].ID)).set(final_obj)


            toast(`${final_obj.title} has been uploaded successfully`)
        }
        else {
            toast('Empty fields are not accepted !')
        }
    }

    async function all_uploading() {
        let temp = []
        for (let i = 0; i < all_obj.length; i++) {
            temp.push(firstUpload(i))
        }
        await Promise.all(temp)
        setUploadObj(null)
        setAllObj([])
    }


    const handleDragOver = (event) => {
        event.preventDefault()
    }

    // Function to handle image drop
    const handleDrop = (e) => {
        e.preventDefault()
        let temp = [];
        if (e != undefined && e.dataTransfer != undefined && e.dataTransfer.files.length > 0) {
            let files = Array.from(e.dataTransfer.files);
            let temp = []
            files.forEach((file) => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    let name = String(file.name)
                    console.log('Files ', file)
                    name = String(name).substring(0, name.indexOf('.'))
                    let tim = new Date().getTime()
                    let new_obj = { company_sub_id: curr_company_sub_folder.ID, company_id: curr_folder.ID, par_id: upload_obj.par_id, sub_id: upload_obj.sub_id, sub_folder_id: upload_obj.sub_folder_id.ID, author_id: myobj.ID, ID: tim, title: name, uri: null, picture: reader.result, imguri: file, file: String(file.name).substring(String(file.name).indexOf('.') + 1, String(file.name).length) }
                    temp.push(new_obj)
                    let new_temp = temp;
                    for (let i = 0; i < new_temp.length; i++) {
                        new_temp[i].ID = tim + i;
                    }
                    if (new_temp.length == files.length) {
                        setAllObj([...new_temp])
                    }
                    make_report(new_temp.length)
                }
                console.log('All temp ', temp)
                reader.readAsDataURL(file);
            });
            console.log('All temp ', temp)
        }
        else {
            console.log('select ', e.target.files[0])
            let files = Array.from(e.target.files);
            let temp = []
            files.forEach((file) => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    let tim = new Date().getTime()
                    let name = String(file.name)
                    console.log('Files ', file)
                    name = String(name).substring(0, name.indexOf('.'))
                    let new_obj = { company_sub_id: curr_company_sub_folder.ID, company_id: curr_folder.ID, par_id: upload_obj.par_id, sub_id: upload_obj.sub_id, sub_folder_id: upload_obj.sub_folder_id.ID, author_id: myobj.ID, ID: tim, title: name, uri: null, picture: reader.result, imguri: file, file: String(file.name).substring(String(file.name).indexOf('.') + 1, String(file.name).length) }
                    temp.push(new_obj)

                    let new_temp = temp;
                    for (let i = 0; i < new_temp.length; i++) {
                        new_temp[i].ID = tim + i;
                    }

                    if (new_temp.length == files.length) {
                        setAllObj([...new_temp])
                    }
                    make_report(new_temp.length)
                    //setAllObj([...all_obj, new_obj])
                }
                console.log('All temp ', temp)
                reader.readAsDataURL(file);
            });
        }
        //console.log('All temp ', temp)
    }

    const refs = useRef()

    const openFileInput = () => {
        refs.current.click()
    }

    useEffect(() => {
        const windowSizeHandler = () => {
            setwidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [curr_folder, setCurrFolder] = useState(null)
    const [curr_company_sub_folder, setCurrCompanySubFolder] = useState(null)

    const uploadImage = async (key, imguri) => {
        console.log('Keys ', key, imguri)

        await storage.ref(`/folders/${key}`).put(imguri);
        let uri = await storage.ref(`/folders/${key}`).getDownloadURL();
        return uri
    };

    async function create_folder_db() {
        if (folder_name != '') {
            setLoading(true)
            let ID = new Date().getTime()
            let obj = { ID: ID, name: folder_name, uri: await uploadImage(ID, imguri) }
            await folder_db.child(String(ID)).set(obj)
            toast('Folder has been created !')
            setLoading(false)
            setCreateFolder(false)
            setPicture(null)
            setimguri(null)
            setFolderName('')
        }
        else toast('Empty values are not accepted !')
    }

    async function delete_folder_db(id) {
        await folder_db.child(String(id)).remove()
        setDeleteCompany(false)
        toast('This folder has been deleted')
    }

    async function edit_folder_db(id) {
        if (folder_name != '') {
            setLoading(true)
            let ID = id
            let obj;
            if (imguri) obj = { name: folder_name, uri: await uploadImage(ID, imguri), update: new Date().getTime(), updated_by: myobj.ID }
            else obj = { name: folder_name, update: new Date().getTime(), updated_by: myobj.ID }
            await folder_db.child(String(ID)).update(obj)
            toast('Folder has been updated !')
            setLoading(false)
            setCreateFolder(false)
            setPicture(null)
            setimguri(null)
            setFolderName('')
            setEditCompany(false)
        }
        else toast('Empty values are not accepted !')
    }

    async function delete_company_sub_folder_db(id) {
        await company_sub_folder_db.child(String(id)).remove()
        setDeleteSubCompany(false)
        toast('Sub folder has been deleted')
    }

    async function create_company_sub_folder_db(id) {
        if (folder_name != '') {
            setLoading(true)
            let ID = new Date().getTime()
            let obj = { ID: ID, name: folder_name, uri: await uploadImage(ID, imguri), folder_id: id, user_id: myobj.ID }
            await company_sub_folder_db.child(String(ID)).set(obj)
            toast('Folder has been created !')
            setLoading(false)
            setCreateCompanySubFolder(false)
            setPicture(null)
            setimguri(null)
            setFolderName('')
        }
        else toast('Empty values are not accepted !')
    }

    async function edit_company_sub_folder_db(sub_folder_id) {
        if (folder_name != '') {
            setLoading(true)
            let ID = sub_folder_id
            let obj;
            if (imguri) obj = { name: folder_name, uri: await uploadImage(ID, imguri), update: new Date().getTime(), updated_by: myobj.ID }
            else obj = { name: folder_name, update: new Date().getTime(), updated_by: myobj.ID }
            await company_sub_folder_db.child(String(ID)).update(obj)
            toast('Folder has been updated !')
            setLoading(false)
            setEditSubCompany(false)
            setPicture(null)
            setimguri(null)
            setFolderName('')
        }
        else toast('Empty values are not accepted !')
    }

    async function create_sub_folder_db() {
        if (folder_name != '') {
            let already = subfolders.length == 0 ? false : true
            if (already) {

                const subfolder_db = firestore.collection('sub_folder')
                setLoading(true)
                let ID = new Date().getTime()
                let obj = { ID: ID, name: folder_name, sub_id: sub_id, par_id: parent_folder, company_id: curr_folder.ID, company_sub_id: curr_company_sub_folder.ID, user_id: myobj.ID }
                await subfolder_db.doc(String(obj.company_sub_id)).update({ [String(ID)]: obj })
                setLoading(false)
                setCreateSubFolder(false)
                setFolderName('')
            }
            else {
                setLoading(true)
                let ID = new Date().getTime()
                let obj = { ID: ID, name: folder_name, sub_id: sub_id, par_id: parent_folder, company_id: curr_folder.ID, company_sub_id: curr_company_sub_folder.ID, user_id: myobj.ID }
                let main_obj = { [String(obj.ID)]: obj }
                const subfolder_db = firestore.collection('sub_folder')
                await subfolder_db.doc(String(obj.company_sub_id)).set(main_obj)
                toast('Folder has been created !')
                setLoading(false)
                setCreateSubFolder(false)
                setFolderName('')
            }
        }
        else toast('Empty values are not accepted !')
    }

    async function create_third_folder_db() {
        if (folder_name != '') {
            let already = subfolders.length == 0 ? false : true
            if (already) {

                const subfolder_db = firestore.collection('sub_folder')
                setLoading(true)
                let ID = new Date().getTime()
                let obj = { ID: ID, name: folder_name, sub_id: null, par_id: 2, company_id: curr_folder.ID, company_sub_id: curr_company_sub_folder.ID, user_id: myobj.ID }
                await subfolder_db.doc(String(obj.company_sub_id)).update({ [String(ID)]: obj })
                setLoading(false);
                setCreateThirdFolder(false)
                setFolderName('')
            }
            else {
                setLoading(true)
                let ID = new Date().getTime()
                let obj = { ID: ID, name: folder_name, sub_id: null, par_id: 2, company_id: curr_folder.ID, company_sub_id: curr_company_sub_folder.ID, user_id: myobj.ID }
                let main_obj = { [String(obj.ID)]: obj }
                const subfolder_db = firestore.collection('sub_folder')
                await subfolder_db.doc(String(obj.company_sub_id)).set(main_obj)
                toast('Folder has been created !')
                setLoading(false)
                setCreateThirdFolder(false)
                setFolderName('')
            }
        }
        else toast('Empty values are not accepted !')
    }

    const [subfolders, setSubFolders] = useState([])

    const [company_sub_folders, setCompanySubFolders] = useState(null)

    const [all_users_per_company, setAllUsersPerCompany] = useState([])
    const [full_access_company, setFullAccessCompany] = useState([])

    const [sharing_curr_folder, setSharingCurrFolder] = useState(null)
    const [sharing_curr_sub_folder, setSharingCurrSubFolder] = useState(null)

    useEffect(() => {
        if (curr_company_sub_folder != null) {
            const users_db = firestore.collection(`users_db`).doc(String(curr_company_sub_folder.ID))
            let subscribe = users_db.onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.data();
                let arr = [];
                if (main != undefined && main != null) {
                    arr = Object.values(main)
                }
                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setAllUsersPerCompany([...arr])
            })
            return () => subscribe()
        }
    }, [curr_company_sub_folder])

    // full access of company users 

    useEffect(() => {
        const users_db = firestore.collection(`users_db`).doc('admin')
        let subscribe = users_db.onSnapshot(async snapshot => {
            // console.log('Dats ',snapshot.docs[0].data())
            let main = snapshot.data();
            let arr = [];
            if (main != undefined && main != null) {
                arr = Object.values(main)
            }
            arr.sort(function (a, b) {
                return b.ID - a.ID
            })
            setFullAccessCompany([...arr])
        })
        return () => subscribe()
    }, [])

    useEffect(() => {
        for (let i = 0; i < all_users_per_company.length; i++) {
            console.log('user ', all_users_per_company[i])
        }
        // console.log('All users ',all_users_per_company)
    }, [all_users_per_company])

    useEffect(() => {
        let subscribe = folder_db.on('value', (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val())
                setFolders([...arr])
            }
            else setFolders([])
        })
        return () => subscribe()
    }, [url])



    useEffect(() => {
        if (curr_folder != null) {
            let subscribe = company_sub_folder_db.orderByChild('folder_id').equalTo(Number(curr_folder.ID)).on('value', (snapshot) => {
                if (snapshot != undefined && snapshot.val() != null) {
                    let arr = Object.values(snapshot.val())
                    setCompanySubFolders([...arr])
                }
                else setCompanySubFolders([])
            })
            return () => subscribe()
        }
    }, [curr_folder])

    useEffect(() => {
        if (sharing_curr_folder != null) {
            let subscribe = company_sub_folder_db.orderByChild('folder_id').equalTo(Number(sharing_curr_folder.ID)).on('value', (snapshot) => {
                if (snapshot != undefined && snapshot.val() != null) {
                    let arr = Object.values(snapshot.val())
                    setCompanySubFolders([...arr])
                }
                else setCompanySubFolders([])
            })
            return () => subscribe()
        }
    }, [sharing_curr_folder])

    const [all_sub_users, setAllSubUsers] = useState(null)

    async function gettingAllUsersFromCompany() {
        let prom = [];
        let final_arr = [];
        let temp = [];
        for (let i = 0; i < company_sub_folders.length; i++) {
            const users_db = firestore.collection(`users_db`).doc(String(company_sub_folders[i].ID))
            let dat = await users_db.get();
            dat = dat.exists ? dat.data() : null
            temp = (dat != null ? Object.values(dat) : [])
            for (let j = 0; j < temp.length; j++) { temp[j].company_sub_id = company_sub_folders[i].ID; temp[j].company_sub_folder = company_sub_folders[i].name }
            final_arr = final_arr.concat(temp)
        }
        setAllSubUsers([...final_arr]);
    }

    useEffect(() => {
        if (company_sub_folders != null) {
            gettingAllUsersFromCompany()
        }
    }, [company_sub_folders])

    useEffect(() => {
        if (curr_company_sub_folder != null) {
            const subfolder_db = firestore.collection('sub_folder').doc(String(curr_company_sub_folder.ID))
            let subscribe = subfolder_db.onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.data();
                let arr = []
                if (main !== undefined && main != null) {
                    arr = Object.values(main)
                }
                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setSubFolders([...arr])
            })
            return () => subscribe()
        }
    }, [curr_company_sub_folder])

    function ifexist(mail, par_id, sub_id) {
        for (let i = 0; i < all_users_per_company.length; i++) {
            if (all_users_per_company[i].email == mail && all_users_per_company[i].par_id == par_id && all_users_per_company[i].sub_id == sub_id) {
                return all_users_per_company[i].ID;
            }
        }
        return false;
    }


    function ifexistFullAccess(mail) {
        for (let i = 0; i < full_access_company.length; i++) {
            if (full_access_company[i].email == mail && ((sharing_curr_folder != null && sharing_curr_folder.ID == full_access_company[i].company_id) || (curr_folder != null && curr_folder.ID == full_access_company[i].company_id))) {
                return full_access_company[i].ID;
            }
        }
        return false;
    }

    function getSubFoldername(id) {
        if (company_sub_folders != null) {
            for (let i = 0; i < company_sub_folders.length; i++) {
                if (company_sub_folders[i].ID == id) {
                    return company_sub_folders[i].name
                }
            }
        }
        else return ''
    }

    function getFullCompanyUsers(company_id) {

        let temp = [];
        let arrt = [...full_access_company]
        for (let i = 0; i < arrt.length; i++) {
            if (arrt[i] != null && arrt[i].company_id == company_id) {
                arrt[i].path = `/ All folders`
                temp.push(arrt[i])
            }
        }

        let arrs = all_sub_users != null ? [...all_sub_users] : [];

        for (let i = 0; i < arrs.length; i++) {
            if (arrs[i] != null) {
                if (arrs[i].full_folder == true) {
                    arrs[i].path = `/${arrs[i].company_sub_folder}`
                }
                else {
                    arrs[i].path = ` / ${arrs[i].company_sub_folder} / ${arrs[i].par_id != null ? (arrs[i].par_id == 0 ? 'Building Services Management' : arrs[i].par_id == 1 ? 'Building Information Management' : 'Building Projects') : null}  /  ${arrs[i].par_id == 0 ? arr[arrs[i].sub_id] : arr1[arrs[i].sub_id]}`
                }
            }
        }

        temp = temp.concat(arrs);

        return temp;

    }

    function getFullSubCompanyUsers(company_sub_id) {

        if (curr_folder != null) {

            let temp = [];
            let arrt = [...full_access_company]
            for (let i = 0; i < arrt.length; i++) {
                if (arrt[i] != null && arrt[i].company_id == curr_folder.ID) {
                    arrt[i].path = `/ All folders`
                    temp.push(arrt[i])
                }
            }

            let arrs = all_sub_users != null ? [...all_sub_users] : [];

            for (let i = 0; i < arrs.length; i++) {
                console.log('sub matching ', arrs[i].company_sub_id, company_sub_id)
                if (arrs[i] != null && arrs[i].company_sub_id == company_sub_id) {
                    if (arrs[i].full_folder == true) {
                        arrs[i].path = `/${arrs[i].company_sub_folder}`
                        temp.push(arrs[i])
                    }
                    else {
                        arrs[i].path = ` / ${arrs[i].company_sub_folder} / ${arrs[i].par_id != null ? (arrs[i].par_id == 0 ? 'Building Services Management' : arrs[i].par_id == 1 ? 'Building Information Management' : 'Building Projects') : null}  /  ${arrs[i].par_id == 0 ? arr[arrs[i].sub_id] : arr1[arrs[i].sub_id]}`
                        temp.push(arrs[i])
                    }
                }
            }

            return temp;
        }
        else return []

    }

    // full_access_company

    function addUserToFolder() {

    }

    const [full_company, setFullCompany] = useState(false)
    const [full_folder, setFullFolder] = useState(false)

    const [delete_company, setDeleteCompany] = useState(false)
    const [edit_company, setEditCompany] = useState(false)

    const [delete_sub_company, setDeleteSubCompany] = useState(false)
    const [edit_sub_company, setEditSubCompany] = useState(false)


    // setUserModalCompany
    const renderPerson = (person, id) => {
        return (
            permission_main_folder == person.ID ? <div style={{ cursor: 'pointer', padding: 4 }} >
                <div style={{ display: 'flex', width: isMobile ? '98%' : 290, height: isMobile ? 350 : 280, flexDirection: 'column', justifyContent: 'start', alignSelf: 'center', backgroundColor: 'white', borderRadius: 8 }}>

                    <img src={person.uri} onClick={() => setCurrFolder(person)} style={{ display: 'flex', width: isMobile ? 200 : 260, height: isMobile ? 250 : 245, alignSelf: 'center', marginTop: 5, borderRadius: 8 }} />
                    <text onClick={() => setCurrFolder(person)} style={{ textAlign: 'center', fontWeight: '600', color: 'black', fontSize: 16 }}>{person.name}</text>


                </div>
            </div> : null
        );
    }

    const renderPersonSub = (person, id) => {
        return (
            permission_main_sub_folder == person.ID ? <div style={{ cursor: 'pointer', padding: 4 }} >
                <div style={{ display: 'flex', width: isMobile ? '98%' : 290, height: 300, flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', backgroundColor: 'white', borderRadius: 8 }}>

                    <img src={person.uri} onClick={() => { setCurrCompanySubFolder(person) }} style={{ width: 150, height: 150, alignSelf: 'center', marginTop: 5, borderRadius: 8 }} />
                    <text onClick={() => { setCurrCompanySubFolder(person) }} style={{ textAlign: 'center', fontWeight: '600', color: 'black', fontSize: 16, marginTop: 8 }}>{person.name}</text>

                </div>
            </div> : null
        );
    }

    // const folders = [{ 'ID': 1, name: 'HSB' }, { 'ID': 2, name: 'HSBC' }, { 'ID': 2, name: 'HSC' }]
    const [folders, setFolders] = useState([])
    const [first_expand, setFirstExpand] = useState(false)
    const [second_expand, setSecondExpand] = useState(false)
    const [third_expand, setThirdExpand] = useState(false)

    useEffect(() => {
        if (scan_sub != null) {
            if (scan_sub == 0) {
                setFirstExpand(true)
            }
            else if (scan_sub == 1) {
                setSecondExpand(true)
            }
            else setThirdExpand(true)
        }
    }, [scan_sub])

    const [create_folder, setCreateFolder] = useState(false)
    const [create_company_sub_folder, setCreateCompanySubFolder] = useState(false)

    const [folder_name, setFolderName] = useState('')

    const [picture, setPicture] = useState(null)
    const [imguri, setimguri] = useState(null)

    useEffect(() => {
        for (let i = 0; i < folders.length; i++) {
            if (folders[i].ID == permission_main_folder) {
                setCurrFolder(folders[i])
            }
        }
    }, [folders, permission_main_folder])

    useEffect(() => {
        if (company_sub_folders != null) {
            for (let i = 0; i < company_sub_folders.length; i++) {
                if (company_sub_folders[i].ID == permission_main_sub_folder) {
                    setCurrCompanySubFolder(company_sub_folders[i])
                }
            }
        }
    }, [company_sub_folders, permission_main_sub_folder])

    const ref = useRef()

    const handleClick = (e) => {
        ref.current.click()
    }

    const onChangePicture = e => {
        if (e.target.files[0]) {
            // console.log("picture: ", e.target.files);
            let file = e.target.files[0]; // get the supplied file
            //setPicture(e.target.files[0]);
            const reader = new FileReader();
            if (file) {
                reader.addEventListener("load", () => {
                    // uploadToFirebase(file);
                    setPicture(reader.result);
                    setimguri(file)
                    console.log('Upload ', file)
                });
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const [create_sub_folder, setCreateSubFolder] = useState(false)
    const [sub_id, setSubId] = useState(null)
    const [parent_folder, setParentFolder] = useState(null)
    const [curr_sub_folder, setCurrSubFolder] = useState(null)

    const arr = ['Lightening Protection', 'Fire', 'Electrical', 'Mechanical', 'Catering Equipment', 'Security Alarm', 'Legionella & Water Hygiene', 'Lifts & Lifting Equipment']
    const arr1 = ['Asbestos', 'Fire Risk', 'Drawings & Plans', 'Health & Safety File', 'Safety Inspections', 'Other Surveys']


    function getName(indx) {
        return arr[indx];
    }

    const [first_obj, setFirstObj] = useState({ one: false, two: false, three: false, four: false, five: false, six: false, seven: false, eight: false })

    const [second_obj, setSecondObj] = useState({ one: false, two: false, three: false, four: false, five: false, six: false, seven: false, eight: false })

    const [third_obj, setThirdObj] = useState({ one: false, two: false, three: false, four: false, five: false, six: false, seven: false, eight: false })

    function getsubfolders(par_id, sub_id) {
        let temp = []
        for (let i = 0; i < subfolders.length; i++) {
            if (subfolders[i].sub_id == sub_id && subfolders[i].par_id == par_id && subfolders[i].company_id == curr_folder.ID && subfolders[i].company_sub_id == curr_company_sub_folder.ID) {
                temp.push(subfolders[i])
            }
        }
        if (par_id == 2) {
            temp.sort(function (a, b) {
                if (a.id != undefined) return a.id - b.id
                else return a.ID - b.ID
            })
        }
        return temp;
    }

    function getDocs(par_id, sub_id, sub_folder_id) {
        let temp = []
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].sub_id == sub_id && curr_company_docs[i].par_id == par_id && curr_company_docs[i].sub_folder_id == sub_folder_id) {
                temp.push(curr_company_docs[i])
            }
        }
        return temp;
    }

    const [upload_obj, setUploadObj] = useState(null)

    const [curr_company_docs, setCurrCompanyDocs] = useState([])

    const [open_subfolder, setOpenSubFolder] = useState([])

    useEffect(() => {
        if (curr_company_sub_folder != null) {
            const doc_db = firestore.collection(`documents`)
            let subscribe = doc_db.where('company_sub_id', '==', curr_company_sub_folder.ID).onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.docs;
                let arr = [];

                for (let i = 0; i < main.length; i++) {
                    arr.push(main[i].data());
                }

                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setCurrCompanyDocs([...arr])
            })
            return () => subscribe()
        }
    }, [curr_company_sub_folder])

    function closesubfolder(id) {
        let temp = []
        for (let i = 0; i < open_subfolder.length; i++) {
            if (open_subfolder[i] !== id) temp.push(open_subfolder[i])
        }
        console.log('Closing subfolder ', temp, id)
        setOpenSubFolder([...temp])
    }

    const [obj, setObj] = useState({ x: -10, y: -10, par_id: null, sub_id: null })
    const [curr_hover, setCurrHover] = useState(-1)

    const [share_modal, setShareModal] = useState(false)

    const DropdownIndicator1 = props => {
        console.log('Props ', props)
        if (props.isFocused) return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 40, width: 30, borderRadius: 4, marginTop: -2, alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: -40, backgroundColor: '#c3c8d0' }}>

                        {search_icon_another}
                    </div>
                </components.DropdownIndicator>
            )
        );
        else return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 40, width: 30, borderRadius: 4, marginTop: -2, alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: -40, backgroundColor: '#c3c8d0' }}>
                        {search_icons}
                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const [mail, setMail] = useState('')


    async function sharing_folder(full_company = false, full_folder = null) {
        let id = new Date().getTime()
        let user_id = ifexist(mail, obj.par_id, obj.sub_id) == false ? ifexistFullAccess(mail) : ifexist(mail, obj.par_id, obj.sub_id)

        console.log('found id ', user_id)

        // not in found , folder
        if (user_id == false) {
            setLoading(true)
            let found = await userdb.orderByChild('email').equalTo(encrypt(mail)).once('value')
            let parent = obj.par_id == 0 ? 'Building Services Management' : obj.par_id == 1 ? 'Building Information Management' : 'Building Projects'
            if (full_folder) { parent = sharing_curr_sub_folder.name }
            // not found , needs to be created
            if (found.val() == null) {

                let permission_obj = {
                    ID: id,
                    par_id: obj.par_id,
                    sub_id: obj.sub_id,
                    by: myobj.ID,
                    user_id: id,
                    role: user_access,
                    company_id: curr_folder !== null ? curr_folder.ID : sharing_curr_folder.ID,
                    full_company: full_company,
                    full_folder: full_folder,
                    company_sub_id: sharing_curr_sub_folder == null ? null : sharing_curr_sub_folder.ID
                }
                let companyid = curr_folder != null ? curr_folder.ID : sharing_curr_folder.ID
                let user_obj = {
                    ID: id,
                    email: encrypt(mail),
                }
                await userdb.child(String(id)).set(user_obj)
                await userdb.child(String(id)).child('permission').child(String(companyid)).set(permission_obj)
                permission_obj.email = mail
                let users_db;
                // give full access of particular company
                if (full_company) {
                    users_db = firestore.collection(`users_db`).doc('admin')
                }
                // give folder access of particular company
                else if (full_folder) {
                    users_db = firestore.collection(`users_db`).doc(String(sharing_curr_sub_folder.ID))
                }
                // give access of particular older from a companies folder
                else {
                    users_db = firestore.collection(`users_db`).doc(String(curr_company_sub_folder.ID))
                }

                let exist = (await users_db.get()).exists
                if (exist) {
                    await users_db.update({ [String(permission_obj.ID)]: permission_obj })
                }
                else {
                    await users_db.set({ [String(permission_obj.ID)]: permission_obj })
                }
                // https://us-central1-sqauredcircle.cloudfunctions.net/app
                if (full_company) await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_add_company_new?company_name=${curr_folder !== null ? curr_folder.name : sharing_curr_folder.name}&mail=${mail}`)
                else if (full_folder) await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_add_folder_new?company_name=${curr_folder !== null ? curr_folder.name : sharing_curr_folder.name}&folder=${sharing_curr_sub_folder.name}&mail=${mail}`)
                else await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_add_new?company_name=${curr_folder !== null ? curr_folder.name : sharing_curr_folder.name}&parent=${parent}&folder=${getName(obj.sub_id)}&mail=${mail}`)

                toast(`This folder has been shared with ${mail}`)
            }
            else {
                let arr = Object.values(found.val())
                // existing user 
                let permission_obj = {
                    ID: id,
                    par_id: obj.par_id,
                    sub_id: obj.sub_id,
                    by: myobj.ID,
                    user_id: arr[0].ID,
                    role: user_access,
                    company_id: curr_folder !== null ? curr_folder.ID : sharing_curr_folder.ID,
                    full_company: full_company,
                    full_folder: full_folder,
                    company_sub_id: sharing_curr_sub_folder == null ? null : sharing_curr_sub_folder.ID
                }
                await userdb.child(String(arr[0].ID)).child('permission').child(String(permission_obj.company_id)).set(permission_obj)
                permission_obj.email = mail
                let users_db;
                // give full access of particular company
                if (full_company) {
                    users_db = firestore.collection(`users_db`).doc('admin')
                }
                // give folder access of particular company
                else if (full_folder) {
                    users_db = firestore.collection(`users_db`).doc(String(sharing_curr_sub_folder.ID))
                }
                // give access of particular older from a companies folder
                else {
                    users_db = firestore.collection(`users_db`).doc(String(curr_company_sub_folder.ID))
                }
                let exist = (await users_db.get()).exists
                if (exist) {
                    await users_db.update({ [String(permission_obj.ID)]: permission_obj })
                }
                else {
                    await users_db.set({ [String(permission_obj.ID)]: permission_obj })
                }
                if (full_company) await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_add_company?company_name=${curr_folder !== null ? curr_folder.name : sharing_curr_folder.name}&mail=${mail}`)
                else if (full_folder) await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_add_folder?company_name=${curr_folder !== null ? curr_folder.name : sharing_curr_folder.name}&folder=${sharing_curr_sub_folder.name}&mail=${mail}`)
                else await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_add?company_name=${curr_folder !== null ? curr_folder.name : sharing_curr_folder.name}&parent=${parent}&folder=${getName(obj.sub_id)}&mail=${mail}`)
                toast(`This folder has been shared with ${mail}`)
                // https://us-central1-sqauredcircle.cloudfunctions.net/app
            }
            setLoading(false)
            setFullCompany(false)
            setFullFolder(false)
            setShareModal(false)
            //setSharingCurrFolder(null)
            //setSharingCurrSubFolder(null)
        }
        else {
            toast('This user already exist')
        }

    }


    function getParentAccess(par_id) {
        return true
    }

    function getSubAccess(par_id, sub_id) {
        return true;
    }

    function handleDownload(url, name, file) {

        toast('Please wait , processing your file')

        try {
            fetch(url, {
                method: 'GET',
            })
                .then((response) => response.blob())
                .then(async (blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${name}.${file}`,
                    );
                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    // await uploadHistory('Download', id)
                });
        }
        catch {
            toast('Your browser blocked downloads')
        }
    }

    const [show_doc, setShowDoc] = useState(false)

    async function uploadHistory(action, id, folder_id, par_id, sub_id, sub_folder_id, par_sub_folder = null, par_sub_sub_folder = null) {
        let curr_year = new Date();
        curr_year.setMonth(0);
        curr_year.setDate(0);
        curr_year.setHours(0);
        curr_year.setMinutes(0)
        curr_year.setSeconds(0)
        curr_year.setMilliseconds(0)
        curr_year = curr_year.getTime()
        const history_db = firestore.collection(`history${curr_company_sub_folder.ID}`).doc(String(curr_year))
        let time = new Date().getTime()
        let obj = {
            ID: time,
            action: action,
            user_id: myobj.ID,
            doc_id: id,

            company_id: folder_id,
            par_id: par_id,
            sub_id: sub_id,
            sub_folder_id: sub_folder_id,
            company_sub_id: curr_company_sub_folder.ID,
            par_sub_folder: par_sub_folder,
            par_sub_sub_folder: par_sub_sub_folder
        }
        let main_obj = { [String(obj.ID)]: obj }
        let exist = (await history_db.get()).exists
        if (exist != true) { await history_db.set(main_obj) }
        else {
            history_db.update({ [String(obj.ID)]: obj })
        }
    }

    async function uploadFavouriteMain(par_id, sub_id) {
        let isfav = isFavourite(par_id, sub_id)
        if (isfav != false) {
            const favourites_db = firestore.collection(`Favourites`)
            await favourites_db.doc(String(myobj.ID)).update({ [String(isfav)]: null })
            toast('This folder is removed from favourites')
        }
        else {
            let adding_obj = {
                ID: new Date().getTime(),
                main_folder: curr_folder.ID,
                par_id: par_id,
                sub_id: sub_id,
                company_sub_id: curr_company_sub_folder.ID
            }
            const favourites_db = firestore.collection(`Favourites`)
            let got_obj = (await favourites_db.doc(String(myobj.ID)).get()).data()
            console.log('Got ', got_obj)
            if (got_obj != undefined) {
                got_obj[String(adding_obj.ID)] = adding_obj
            }
            else got_obj = { [String(adding_obj.ID)]: adding_obj }
            await favourites_db.doc(String(myobj.ID)).set(got_obj)
            toast('This folder is marked as favourite')
        }
    }

    const [main_favourite, setMainFavourite] = useState([])

    function isFavourite(par_id, sub_id) {
        for (let i = 0; i < main_favourite.length; i++) {
            if (main_favourite[i] != null && main_favourite[i].par_id == par_id && main_favourite[i].sub_id == sub_id && main_favourite[i].main_folder == curr_folder.ID && main_favourite[i].company_sub_id == curr_company_sub_folder.ID) {
                return main_favourite[i].ID;
            }
        }
        return false;
    }

    useEffect(() => {
        if (curr_folder != null && curr_folder.ID != undefined && myobj.ID != undefined) {
            const favourites_db = firestore.collection(`Favourites`)
            let subscribe = favourites_db.doc(String(myobj.ID)).onSnapshot(snapshot => {
                if (snapshot != undefined || snapshot != null) {
                    let obj = snapshot.data()
                    if (obj != undefined && obj != null) {
                        let arr = Object.values(obj);
                        setMainFavourite([...arr])
                    }
                    else setMainFavourite([])
                }
            })
            return () => subscribe()
        }
    }, [curr_folder])

    async function uploadFavouriteSub(main_id, par_id, sub_id, sub_folder_id) {
        let adding_obj = {
            ID: new Date().getTime(),
            main_folder: curr_folder.ID,
            par_id: par_id,
            sub_id: sub_id,
            sub_folder_id: sub_folder_id
        }
        const favourites_db = firestore.collection(`FavouriteSub`)
        await favourites_db.doc(String(myobj.ID)).update({ [String(adding_obj.ID)]: adding_obj })
    }

    useEffect(() => {
        if (show_doc != false) {
            uploadHistory('View', show_doc.item.ID, curr_folder.ID, show_doc.par_id, show_doc.sub_id, show_doc.folder.ID, show_doc.par_sub_folder == undefined ? null : show_doc.par_sub_folder.ID, show_doc.par_sub_sub_folder == undefined ? null : show_doc.par_sub_sub_folder.ID)
        }
    }, [show_doc])

    function today_dats(str) {
        // return new Date(str).getDate() + " " + months[new Date(str).getMonth()] + " " + new Date().getFullYear() + " / " + moment(new Date(str)).format('hh : mm A')
        return moment(new Date(str)).format('DD-MM-YYYY') + " at " + moment(new Date(str)).format('hh : mm A')
    }

    const [histories, setHistories] = useState([])
    const [history_obj, setHistoryObj] = useState({
        par_id: null, sub_id: null
    })

    useEffect(() => {
        if (curr_folder != null && curr_company_sub_folder != null) {
            let curr_year = new Date();
            curr_year.setMonth(0);
            curr_year.setDate(0);
            curr_year.setHours(0);
            curr_year.setMinutes(0)
            curr_year.setSeconds(0)
            curr_year.setMilliseconds(0)
            curr_year = curr_year.getTime()
            const users_db = firestore.collection(`history${curr_company_sub_folder.ID}`).doc(String(curr_year))
            let subscribe = users_db.onSnapshot(async snapshot => {
                // console.log('Dats ',snapshot.docs[0].data())
                let main = snapshot.data();
                let arr = [];

                if (main != undefined && main != null) arr = Object.values(main)

                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })

                if (arr.length > 0) setHistories([...arr])
                else setHistories([])
            })
            return () => subscribe()
        }
    }, [curr_folder, curr_company_sub_folder])

    function getFolderName(Id) {
        for (let i = 0; i < subfolders.length; i++) {
            if (subfolders[i].ID == Id) return subfolders[i].name
        }
        return 'Folder';
    }

    function getDocName(Id) {
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].ID == Id) return curr_company_docs[i].title
        }
        return null;
    }

    function getDocFile(Id) {
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].ID == Id) return curr_company_docs[i].file
        }
        return null;
    }

    function getUserName(Id) {
        for (let i = 0; i < all_users_per_company.length; i++) {
            if (all_users_per_company[i].user_id == Id) return all_users_per_company[i].email
        }
        return `boom@gmail.com`
    }

    function getHistory() {
        let temp = [];
        for (let i = 0; i < curr_company_docs.length; i++) {
            if (curr_company_docs[i].par_id == history_obj.par_id && curr_company_docs[i].sub_id == history_obj.sub_id) temp.push({ doc_id: curr_company_docs[i].ID, action: 'Upload', user_id: curr_company_docs[i].author_id, ID: curr_company_docs[i].ID, par_id: curr_company_docs[i].par_id, sub_id: curr_company_docs[i].sub_id, sub_folder_id: curr_company_docs[i].sub_folder_id })
        }
        for (let i = 0; i < histories.length; i++) {
            if (history_obj.par_id == histories[i].par_id && history_obj.sub_id == histories[i].sub_id) temp.push({ doc_id: histories[i].doc_id, action: histories[i].action, user_id: histories[i].user_id, ID: histories[i].ID, par_id: histories[i].par_id, sub_id: histories[i].sub_id, sub_folder_id: histories[i].sub_folder_id })
        }
        temp.sort(function (a, b) {
            return b.ID - a.ID
        })
        return temp;
    }


    const [create_third_folder, setCreateThirdFolder] = useState(null)

    const [user_modal_company, setUserModalCompany] = useState(false)
    const [user_modal_company_sub, setUserModalCompanySub] = useState(false)

    const [edit_third_sub_folder, setEditThirdSubFolder] = useState(false)
    const [delete_third_sub_folder, setDeleteThirdSubFolder] = useState(false)

    async function edit_third_sub(id) {
        if (folder_name != '') {
            const subfolder_db = firestore.collection('sub_folder')
            let full_obj = (await subfolder_db.doc(String(curr_company_sub_folder.ID)).get()).data()
            full_obj[String(id)].name = folder_name
            await subfolder_db.doc(String(curr_company_sub_folder.ID)).set(full_obj)
            toast('This folder has been updated')
            setEditThirdSubFolder(false)
        }
        else toast('Empty fields are not accepted')
    }

    async function delete_third_sub(id) {
        const subfolder_db = firestore.collection('sub_folder')
        let full_obj = (await subfolder_db.doc(String(curr_company_sub_folder.ID)).get()).data()
        delete full_obj[String(id)]
        await subfolder_db.doc(String(curr_company_sub_folder.ID)).set(full_obj)
        setDeleteThirdSubFolder(false)
        toast('This folder has been deleted')
    }

    const isMobile = width <= 768;

    const [mobile_view, setMobileView] = useState(false)

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (folders.length > 0) {

            }
            else {
                if (isSafari) window.location.reload()
            }
        }, 3000)
        return () => {
            clearInterval(myInterval);
        };
    }, [folders, isSafari]);

    function GetpdfUri(pdf_uri) {
        const doc = React.useMemo(() => ({ url: pdf_uri }), [pdf_uri])
        return doc;
    }

    useEffect(() => {
        if (show_doc != null && show_doc.item != undefined) console.log('Show ', show_doc != null ? show_doc.item.uri : null)
    }, [show_doc])


    return (
        mobile_view == false ? <div style={{ opacity: 0.99, backgroundColor: 'white', display: 'flex', flexDirection: 'row', width: width, height: height, position: 'absolute', top: 0, left: 0, overflowX: 'hidden' }}>

            <input id="actual" ref={ref} accept="image/*" onChange={onChangePicture} type="file" hidden={true} />
            <input id="actual" multiple ref={refs} accept="/*" type="file" onChange={handleDrop} hidden={true} />


            <div style={{ display: 'flex', flexDirection: 'column', width: width, height: height, backgroundColor: 'white', alignSelf: 'center' }}>

                <div style={{ height: 100, width: width * 0.90, justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 5, height: 54, width: 580 }}>
                        {curr_folder ? <div onClick={() => { if (curr_company_sub_folder) setCurrCompanySubFolder(null); else { setCurrFolder(null) } }} style={{ cursor: 'pointer', height: 46, width: 46, borderRadius: 8, justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F5F5', display: 'flex' }}>
                            {curr_folder ? <FaAngleLeft color='black' size={20} /> : null}
                        </div> : <div style={{ width: 20, height: 20 }} />}
                        <text style={{ marginLeft: 8, fontSize: 16, fontWeight: '600', letterSpacing: 1.01, color: 'black', textAlign: 'left', fontFamily: 'Montserrat' }}>{curr_folder == null ? 'All files' : curr_company_sub_folder == null ? curr_folder.name : curr_folder.name + " / " + curr_company_sub_folder.name}</text>
                    </div>
                </div>

                <div style={{ width: width * 0.95, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', backgroundColor: '#F5F6FA', height: height - 100, overflowY: isMobile ? 'scroll' : 'scroll' }}>
                    {curr_folder == null && (folders.length > 0 ? <div style={{ opacity: 0.99, display: 'flex', flexDirection: 'row', marginTop: 20, width: '95%', alignSelf: 'center', height: '95%', flexWrap: 'wrap' }}>
                        <FlatList
                            list={folders}
                            renderItem={renderPerson}
                        />
                    </div> : <div style={{ display: 'flex', alignSelf: 'center' }}> <RiseLoader color={'#36D7B7'} isLoading={true}
                        css={override} size={20} />
                    </div>)}
                    {curr_folder !== null && curr_company_sub_folder == null && company_sub_folders != null && (company_sub_folders.length > 0 ? <div style={{ opacity: 0.99, display: 'flex', flexDirection: 'row', marginTop: 20, width: '95%', alignSelf: 'center', height: '90%', flexWrap: 'wrap', marginBottom: 50 }}>
                        <FlatList
                            list={company_sub_folders}
                            renderItem={renderPersonSub}
                        />
                    </div> : <text style={{ marginTop: 40, textAlign: 'center', fontSize: 20, fontWeight: '600', fontFamily: 'Montserrat' }}>You have no folder of company {curr_folder.name}</text>)}


                    {curr_folder != null && curr_company_sub_folder != null && <div style={{ display: 'flex', flexDirection: 'column', width: 0.90 * width, backgroundColor: 'white', borderTopWidth: 1.5, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#0D1C4133', borderStyle: 'solid', height: '95%', alignSelf: 'center' }}>
                        {scan_sub == 0 ? <div onClick={() => { setFirstExpand(!first_expand); setParentFolder(0) }} style={{ backgroundColor: 'white', display: 'flex', width: 0.90 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>

                            <div style={{ height: 150, display: 'flex', width: 0.75 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginRight: 0.0694 * width }}>

                                <img src={first_expand ? arrow3 : arrow2} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'center', }} />
                                <img src={Component2} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 0.2006 * width, textAlign: 'left' }}>
                                    Building Services Management
                                </text>
                            </div>

                            <div style={{ display: 'flex', width: 0.10 * width, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>

                                <text style={{ fontWeight: 500, fontSize: 14, alignSelf: 'center', color: '#5E5E5E' }}>

                                </text>
                                <text style={{ fontWeight: 500, fontSize: 14, alignSelf: 'center', color: '#5E5E5E' }}>

                                </text>

                            </div>

                        </div> : null}

                        <Collapsible transitionTime={1300} open={first_expand} style={{ display: 'flex', flexDirection: 'column', width: 0.90 * width, maxHeight: 30600, backgroundColor: 'white' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.90 * width, maxHeight: 30600, backgroundColor: 'white' }}>

                                {particular == 0 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 20, backgroundColor: '#0D1C4133' }}>

                                </div> : null}

                                {particular == 0 ? <div onClick={() => { setFirstObj({ ...first_obj, one: !first_obj.one }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 30, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.one ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center' }} />
                                        <img src={D1} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Lightening Protection
                                        </text>

                                    </div>

                                </div> : null}



                                <Collapse isOpened={first_obj.one}>
                                    {getsubfolders(0, 0).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: isMobile ? 'center' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                    {item.name}
                                                </text>
                                            </div>

                                            <div style={{ width: 2, height: 20 }} />
                                        </div>
                                        {open_subfolder.includes(item.ID) ? getDocs(0, 0, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 0, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                <FaFile size={20} color={'#5E5E5E'} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 80, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 13, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye style={{ cursor: 'pointer' }} size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>


                                {particular == 1 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                </div> : null}

                                {particular == 1 ? <div onClick={() => { setFirstObj({ ...first_obj, two: !first_obj.two }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.two ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                        <img src={D2} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Fire
                                        </text>


                                    </div>




                                </div> : null}

                                <Collapse isOpened={first_obj.two}>
                                    {getsubfolders(0, 1).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                    {item.name}
                                                </text>
                                            </div>




                                            <div style={{ width: 2, height: 20 }} />

                                        </div>

                                        {open_subfolder.includes(item.ID) ? getDocs(0, 1, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 1, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                <FaFile size={20} color={'#5E5E5E'} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>

                                {particular == 2 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                </div> : null}

                                {particular == 2 ? <div onClick={() => { setFirstObj({ ...first_obj, three: !first_obj.three }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.three ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                        <img src={D3} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Electrical
                                        </text>


                                    </div>



                                </div> : null}

                                <Collapse isOpened={first_obj.three}>
                                    {getsubfolders(0, 2).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left', marginLeft: isMobile ? 10 : 0, }}>
                                                    {item.name}
                                                </text>
                                            </div>


                                            <div style={{ width: 2, height: 20 }} />
                                        </div>
                                        {open_subfolder.includes(item.ID) ? getDocs(0, 2, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 2, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                <FaFile size={20} color={'#5E5E5E'} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>

                                {particular == 3 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                </div> : null}
                                {particular == 3 ? <div onClick={() => { setFirstObj({ ...first_obj, four: !first_obj.four }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.four ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                        <img src={D4} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Mechanical
                                        </text>
                                    </div>



                                </div> : null}

                                <Collapse isOpened={first_obj.four}>
                                    {getsubfolders(0, 3).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left', marginLeft: isMobile ? 10 : 0, }}>
                                                    {item.name}
                                                </text>
                                            </div>


                                            <div style={{ width: 2, height: 20 }} />
                                        </div>
                                        {open_subfolder.includes(item.ID) ? getDocs(0, 3, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 3, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                <FaFile size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>

                                {particular == 4 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                </div> : null}
                                {particular == 4 ? <div onClick={() => { setFirstObj({ ...first_obj, five: !first_obj.five }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.five ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                        <img src={D5} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Catering Equipment
                                        </text>


                                    </div>



                                </div> : null}

                                <Collapse isOpened={first_obj.five}>
                                    {getsubfolders(0, 4).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left', marginLeft: isMobile ? 10 : 0, }}>
                                                    {item.name}
                                                </text>
                                            </div>


                                            <div style={{ width: 2, height: 20 }} />
                                        </div>
                                        {open_subfolder.includes(item.ID) ? getDocs(0, 4, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 4, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                <FaFile size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>

                                {particular == 5 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                </div> : null}
                                {particular == 5 ? <div onClick={() => { setFirstObj({ ...first_obj, six: !first_obj.six }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.six ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                        <img src={D6} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Security Alarm
                                        </text>


                                    </div>



                                </div> : null}

                                <Collapse isOpened={first_obj.six}>
                                    {getsubfolders(0, 5).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left', marginLeft: isMobile ? 10 : 0, }}>
                                                    {item.name}
                                                </text>
                                            </div>



                                            <div style={{ width: 2, height: 20 }} />
                                        </div>
                                        {open_subfolder.includes(item.ID) ? getDocs(0, 5, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 5, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                <FaFile size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left', wordBreak: 'break-all' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>

                                {particular == 6 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                </div> : null}

                                {particular == 6 ? <div onClick={() => { setFirstObj({ ...first_obj, seven: !first_obj.seven }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.seven ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                        <img src={D7} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Legionella & Water Hygiene
                                        </text>


                                    </div>



                                </div> : null}

                                <Collapse isOpened={first_obj.seven}>
                                    {getsubfolders(0, 6).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left', marginLeft: isMobile ? 10 : 0, }}>
                                                    {item.name}
                                                </text>
                                            </div>

                                            <div style={{ width: 2, height: 20 }} />
                                        </div>
                                        {open_subfolder.includes(item.ID) ? getDocs(0, 6, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 6, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                <FaFile size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left', wordBreak: 'break-all' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>

                                {particular == 7 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                </div> : null}
                                {particular == 7 ? <div onClick={() => { setFirstObj({ ...first_obj, eight: !first_obj.eight }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                    <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                        <img src={first_obj.eight ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                        <img src={D8} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                            Lifts & Lifting Equipment
                                        </text>

                                    </div>



                                </div> : null}

                                <Collapse isOpened={first_obj.eight}>
                                    {getsubfolders(0, 7).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>

                                        <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>

                                                <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left', marginLeft: isMobile ? 10 : 0, }}>
                                                    {item.name}
                                                </text>
                                            </div>


                                            <div style={{ width: 2, height: 20 }} />
                                        </div>
                                        {open_subfolder.includes(item.ID) ? getDocs(0, 7, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 0, sub_id: 7, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                <FaFile size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left', wordBreak: 'break-all' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                            </div>
                                        </div>) : null}

                                    </div>)}
                                </Collapse>

                            </div>
                        </Collapsible>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.90 * width }}>
                            {scan_sub == 1 ? <div style={{ display: 'flex', width: 0.90 * width, height: 0.5, alignSelf: 'center', marginTop: 1, backgroundColor: '#0D1C4133' }}>

                            </div> : null}

                            {scan_sub == 1 ? <div onClick={() => { setSecondExpand(!second_expand); setParentFolder(1) }} style={{ display: 'flex', width: 0.90 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 1, height: 150, backgroundColor: 'white' }}>


                                <div style={{ display: 'flex', width: 0.75 * width, height: 150, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginRight: 0.0694 * width, backgroundColor: 'white' }}>

                                    <img src={second_expand ? arrow3 : arrow2} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'center', }} />
                                    <img src={Component1} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 0.2006 * width, textAlign: 'left' }}>
                                        Building Information Management
                                    </text>

                                </div>


                                <div style={{ display: 'flex', width: 0.10 * width, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>

                                    <text style={{ fontWeight: 500, fontSize: 14, alignSelf: 'center', color: '#5E5E5E' }}>

                                    </text>
                                    <text style={{ fontWeight: 500, fontSize: 14, alignSelf: 'center', color: '#5E5E5E' }}>

                                    </text>


                                </div>

                            </div> : null}

                            <Collapsible transitionTime={1300} open={second_expand}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.90 * width, maxHeight: 2900, backgroundColor: 'white' }}>

                                    {particular == 0 ? <div style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 20, backgroundColor: '#0D1C4133' }}>

                                    </div> : null}

                                    {particular == 0 ? <div onClick={() => { setSecondObj({ ...second_obj, one: !second_obj.one }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 30, height: 150 }}>


                                        <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                            <img src={second_obj.one ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                            <div style={{ width: 110, height: 110, alignSelf: 'center', }}>{D11}</div>
                                            <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                                Asbestos
                                            </text>


                                        </div>


                                    </div> : null}

                                    <Collapse isOpened={second_obj.one}>
                                        {getsubfolders(1, 0).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                            <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>

                                                    <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                    <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                        {item.name}
                                                    </text>
                                                </div>


                                                <div style={{ width: 2, height: 20 }} />
                                            </div>
                                            {open_subfolder.includes(item.ID) ? getDocs(1, 0, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 1, sub_id: 0, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50 }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                    <FaFile size={20} color={'#5E5E5E'} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                        {itemm.title}
                                                    </text>
                                                </div>

                                                <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                    <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                    <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                                </div>
                                            </div>) : null}

                                        </div>)}
                                    </Collapse>

                                    {particular == 1 ? <div style={{ display: 'flex', width: 0.75 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                    </div> : null}

                                    {particular == 1 ? <div onClick={() => { setSecondObj({ ...second_obj, two: !second_obj.two }); }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                        <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                            <img src={second_obj.two ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                            <img src={D22} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                            <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                                Fire Risk
                                            </text>


                                        </div>




                                    </div> : null}

                                    <Collapse isOpened={second_obj.two}>
                                        {getsubfolders(1, 1).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                            <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', }}>

                                                    <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                    <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                        {item.name}
                                                    </text>
                                                </div>


                                                <div style={{ width: 2, height: 20 }} />
                                            </div>
                                            {open_subfolder.includes(item.ID) ? getDocs(1, 1, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 1, sub_id: 1, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50 }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                    <FaFile size={20} color={'#5E5E5E'} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                        {itemm.title}
                                                    </text>
                                                </div>

                                                <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                    <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                    <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                                </div>
                                            </div>) : null}

                                        </div>)}
                                    </Collapse>

                                    {particular == 2 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                    </div> : null}

                                    {particular == 2 ? <div onClick={() => { setSecondObj({ ...second_obj, three: !second_obj.three }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                        <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                            <img src={second_obj.three ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />

                                            <img src={D33} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                            <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                                Drawings & Plans
                                            </text>

                                        </div>



                                    </div> : null}

                                    <Collapse isOpened={second_obj.three}>
                                        {getsubfolders(1, 2).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                            <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row', }}>

                                                    <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                    <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                        {item.name}
                                                    </text>
                                                </div>


                                                <div style={{ width: 2, height: 20 }} />
                                            </div>
                                            {open_subfolder.includes(item.ID) ? getDocs(1, 2, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 1, sub_id: 2, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50 }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                    <FaFile size={20} color={'#5E5E5E'} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                        {itemm.title}
                                                    </text>
                                                </div>

                                                <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                    <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                    <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                                </div>
                                            </div>) : null}

                                        </div>)}
                                    </Collapse>

                                    {particular == 3 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                    </div> : null}
                                    {particular == 3 ? <div onClick={() => { setSecondObj({ ...second_obj, four: !second_obj.four }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                        <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                            <img src={second_obj.four ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />

                                            <img src={D44} style={{ width: 105, height: 105, alignSelf: 'center', marginLeft: 6 }} />
                                            <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 10, width: 200, textAlign: 'left' }}>
                                                Health & Safety File
                                            </text>
                                        </div>



                                    </div> : null}

                                    <Collapse isOpened={second_obj.four}>
                                        {getsubfolders(1, 3).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                            <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row', }}>

                                                    <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                    <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                        {item.name}
                                                    </text>
                                                </div>

                                                <div style={{ width: 2, height: 20 }} />
                                            </div>
                                            {open_subfolder.includes(item.ID) ? getDocs(1, 3, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 1, sub_id: 3, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50 }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                    <FaFile size={20} color={'#5E5E5E'} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                        {itemm.title}
                                                    </text>
                                                </div>

                                                <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                    <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                    <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                                </div>
                                            </div>) : null}

                                        </div>)}
                                    </Collapse>

                                    {particular == 4 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                    </div> : null}
                                    {particular == 4 ? <div onClick={() => { setSecondObj({ ...second_obj, five: !second_obj.five }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>


                                        <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>

                                            <img src={second_obj.five ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                            <img src={D55} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                            <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                                Safety Inspections
                                            </text>
                                        </div>


                                    </div> : null}

                                    <Collapse isOpened={second_obj.five}>
                                        {getsubfolders(1, 4).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                            <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                    <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                    <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                        {item.name}
                                                    </text>
                                                </div>

                                                <div style={{ width: 2, height: 20 }} />
                                            </div>
                                            {open_subfolder.includes(item.ID) ? getDocs(1, 4, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 1, sub_id: 4, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50 }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                    <FaFile size={20} color={'#5E5E5E'} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left', wordBreak: 'break-all' }}>
                                                        {itemm.title}
                                                    </text>
                                                </div>

                                                <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                    <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                    <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                                </div>
                                            </div>) : null}

                                        </div>)}
                                    </Collapse>

                                    {particular == 5 ? <div style={{ display: 'flex', width: !isMobile ? 0.72 * width : 0.90 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 15, backgroundColor: '#0D1C4133' }}>

                                    </div> : null}
                                    {particular == 5 ? <div onClick={() => { setSecondObj({ ...second_obj, six: !second_obj.six }) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.72 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 15, height: 150 }}>

                                        <div style={{ display: 'flex', width: isMobile ? 0.72 * width : 0.35 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 10 : 0 }}>
                                            <img src={second_obj.six ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', }} />
                                            <img src={D66} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                            <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 200, textAlign: 'left' }}>
                                                Other Surveys
                                            </text>
                                        </div>

                                    </div> : null}

                                    <Collapse isOpened={second_obj.six}>
                                        {getsubfolders(1, 5).map((item, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 1000 }}>
                                            <div onClick={() => { if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: (curr_sub_folder != null && curr_sub_folder.ID == item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                                    <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                                    <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                        {item.name}
                                                    </text>
                                                </div>


                                                <div style={{ width: 2, height: 20 }} />
                                            </div>
                                            {open_subfolder.includes(item.ID) ? getDocs(1, 5, item.ID).map((itemm, index) => <div onClick={() => { setShowDoc({ ...show_doc, par_id: 1, sub_id: 5, item: itemm, folder: item }); setMobileView(true) }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50 }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                    <FaFile size={20} color={'#5E5E5E'} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 150, textAlign: 'left' }}>
                                                        {itemm.title}
                                                    </text>
                                                </div>

                                                <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                    <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                    <IoMdEye size={isMobile ? 30 : 25} color='#5E5E5E' />
                                                </div>
                                            </div>) : null}

                                        </div>)}
                                    </Collapse>

                                </div>
                            </Collapsible>

                            {scan_sub == 2 ? <div style={{ display: 'flex', width: 0.90 * width, height: 0.5, alignSelf: 'center', marginTop: 1, backgroundColor: '#0D1C4133' }}>

                            </div> : null}

                            {scan_sub == 2 ? <div onClick={() => { setThirdExpand(!third_expand); setParentFolder(2) }} style={{ display: 'flex', width: 0.90 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 1, height: 150, backgroundColor: 'white' }}>


                                <div style={{ height: 150, display: 'flex', width: 0.75 * width, height: 'auto', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginRight: 0.0694 * width }}>

                                    <img src={third_expand ? arrow3 : arrow2} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'center', }} />
                                    <img src={Component3} style={{ width: 110, height: 110, alignSelf: 'center', }} />
                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 0.2006 * width, textAlign: 'left' }}>
                                        Building Projects
                                    </text>

                                </div>

                                <div style={{ display: 'flex', width: 0.10 * width, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>



                                </div>

                            </div> : null}

                            <div style={{ display: 'flex', width: 0.90 * width, height: 0.5, alignSelf: 'center', marginTop: 1, backgroundColor: '#0D1C4133', marginBottom: 1 }}>

                            </div>

                            <Collapsible transitionTime={1000} open={third_expand}>
                                {getsubfolders(2, null).map((item, index) => Number(particular) == Number(item.ID) ? <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 9000, backgroundColor: 'white' }}> <div onClick={() => { if (curr_sub_folder == null) setCurrSubFolder(item); else { setCurrSubFolder(null) }; if (open_subfolder.includes(item.ID)) { closesubfolder(item.ID) } else { setOpenSubFolder([...open_subfolder, item.ID]) } }} style={{ cursor: 'pointer', width: isMobile ? 0.90 * width : 0.72 * width, height: 70, alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: open_subfolder.includes(item.ID) ? '#e7e9ec' : 'white', display: 'flex' }}>

                                    <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row' }}>

                                        <img src={(open_subfolder.includes(item.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />
                                        <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                            {item.name}
                                        </text>
                                    </div>

                                    <div style={{ width: 2, height: 20 }} />
                                </div>
                                    <Collapsible transitionTime={400} open={open_subfolder.includes(item.ID)}>

                                        {item.contain_folder == false ? getDocs(2, null, item.ID).map((itemm, index) => <div style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, marginLeft: isMobile ? 0 : 0.15 * width }}>

                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                                                <FaFile size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                <text style={{ fontWeight: 600, fontSize: 15, alignSelf: 'center', color: '#000000', marginLeft: 0, marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                    {itemm.title}
                                                </text>
                                            </div>

                                            <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemm.file).toUpperCase()} File</text>
                                                <IoMdEye onClick={() => { setShowDoc({ ...show_doc, par_id: 2, sub_id: null, item: itemm, folder: item }); setMobileView(true) }} style={{ cursor: 'pointer' }} size={20} color='#5E5E5E' />
                                            </div>
                                        </div>) : getsubfolders(2, item.ID).map((itemm, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 2000 }}>
                                            <div onClick={() => { if (open_subfolder.includes(itemm.ID)) { closesubfolder(itemm.ID) } else { setOpenSubFolder([...open_subfolder, itemm.ID]) } }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.64 * width, height: '130', alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, height: 50, backgroundColor: open_subfolder.includes(itemm.ID) ? '#e7e9ec' : 'white', }}>

                                                <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 15 : 0 }}>
                                                    <img src={(open_subfolder.includes(itemm.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 10 : 0 }} />

                                                    <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                    <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                        {itemm.name}
                                                    </text>
                                                </div>

                                            </div>

                                            <Collapsible transitionTime={400} open={open_subfolder.includes(itemm.ID)}>
                                                {itemm.contain_folder == false ? getDocs(2, itemm.ID, item.ID).map((itemmm, index) =>
                                                    <div style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, marginLeft: isMobile ? 0 : 0.15 * width }}>

                                                        <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 20 : 0 }}>
                                                            <FaFile size={20} color={'#5E5E5E'} />
                                                            <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 0.1590 * width, textAlign: 'left' }}>
                                                                {itemmm.title}
                                                            </text>
                                                        </div>

                                                        <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                            <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemmm.file).toUpperCase()} File</text>
                                                            <IoMdEye onClick={() => { setShowDoc({ ...show_doc, par_id: 2, sub_id: itemm.ID, item: itemmm, folder: itemm, par_sub_folder: item }); setMobileView(true) }} style={{ cursor: 'pointer' }} size={20} color='#5E5E5E' />
                                                        </div>
                                                    </div>) : getsubfolders(2, itemm.ID).map((itemmm, index) => <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 2000 }}>

                                                        <div onClick={() => { if (open_subfolder.includes(itemmm.ID)) { closesubfolder(itemmm.ID) } else { setOpenSubFolder([...open_subfolder, itemmm.ID]) } }} style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.64 * width, height: '130', alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, height: 50, backgroundColor: open_subfolder.includes(itemmm.ID) ? '#e7e9ec' : 'white', }}>

                                                            <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginRight: 0.1694 * width }}>
                                                                <img src={(open_subfolder.includes(itemmm.ID)) ? arrow3 : arrow2} style={{ width: 40, height: 40, alignSelf: 'center', marginLeft: isMobile ? 35 : 0 }} />

                                                                <FaFolder size={20} color={'#5E5E5E'} style={{ marginLeft: isMobile ? 10 : 0 }} />
                                                                <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: isMobile ? 10 : 0, width: 200, textAlign: 'left' }}>
                                                                    {itemmm.name}
                                                                </text>
                                                            </div>

                                                        </div>

                                                        <Collapsible transitionTime={400} open={open_subfolder.includes(itemmm.ID)}>
                                                            {getDocs(2, itemmm.ID, itemm.ID, 'debug').map((itemmmm, index) =>
                                                                <div style={{ display: 'flex', width: isMobile ? 0.90 * width : 0.62 * width, height: '130', alignSelf: isMobile ? 'flex-start' : 'flex-end', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, maxHeight: 150, minHeight: 50, marginLeft: isMobile ? 0 : 0.15 * width }}>

                                                                    <div style={{ display: 'flex', width: isMobile ? 0.75 * width : 0.35 * width, height: '130', alignSelf: 'center', alignItems: 'center', justifyContent: isMobile ? 'left' : 'space-evenly', flexDirection: 'row', marginLeft: isMobile ? 20 : 0 }}>
                                                                        <FaFile size={20} color={'#5E5E5E'} />
                                                                        <text style={{ fontWeight: 600, fontSize: 16, alignSelf: 'center', color: '#000000', marginLeft: 0, width: 0.1590 * width, textAlign: 'left' }}>
                                                                            {itemmmm.title}
                                                                        </text>
                                                                    </div>

                                                                    <div style={{ display: 'flex', width: 100, height: 52, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', marginRight: 10 }}>
                                                                        <text style={{ fontWeight: 600, fontSize: 14, alignSelf: 'center', color: '#000000', marginLeft: 0, textAlign: 'left', marginRight: 20 }}>{String(itemmmm.file).toUpperCase()} File</text>
                                                                        <IoMdEye onClick={() => { setShowDoc({ ...show_doc, par_id: 2, sub_id: itemmm.ID, item: itemmmm, folder: itemm, par_sub_folder: item, par_sub_sub_folder: itemmm }); setMobileView(true) }} style={{ cursor: 'pointer' }} size={20} color='#5E5E5E' />
                                                                    </div>
                                                                </div>)}
                                                        </Collapsible>

                                                    </div>)}
                                            </Collapsible>


                                        </div>)}
                                        <div style={{ width: 0.90 * width, height: 1, backgroundColor: '#0D1C4133', alignSelf: 'center' }} />


                                    </Collapsible>

                                </div> : null)}

                            </Collapsible>

                            <div style={{ display: 'flex', width: 0.90 * width, height: 0.5, alignSelf: 'center', marginTop: 1, backgroundColor: '#0D1C4133' }}>

                            </div>

                        </div>
                        <div style={{ display: 'flex', width: 0.75 * width, height: 0.5, alignSelf: 'flex-end', marginTop: 10 }}>

                        </div>
                    </div>}
                </div>

            </div >

            {
                obj.x > 0 ? <div style={{ boxShadow: `0px 0px 1px 2px lightgray`, position: 'absolute', top: obj.y - 100, left: obj.x - 230, display: 'flex', backgroundColor: 'white', flexDirection: 'column', height: 150, width: 210, borderRadius: 6, justifyContent: 'space-around' }}>
                    <div onClick={() => setObj({ ...obj, x: -10, sub_id: null })} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -3 }}>{close_button}</div>
                    <div onClick={() => { setShareModal(true); setFullCompany(false); setFullFolder(false) }} onMouseEnter={() => setCurrHover(0)} onMouseLeave={() => setCurrHover(-1)} style={{ cursor: 'pointer', flexDirection: 'row', alignItems: 'center', display: 'flex', alignSelf: 'center', width: 210, justifyContent: 'space-between', backgroundColor: curr_hover == 0 ? '#cfd2d9' : null, height: 50 }}>
                        <IoMdShare size={22} color='#0D1C41' style={{ marginLeft: 10 }} />
                        <text style={{ color: '#0D1C41', fontWeight: '600', fontSize: 16, fontFamily: 'Montserrat', marginRight: 10 }}>Share</text>
                    </div>
                    <div onMouseEnter={() => setCurrHover(1)} onMouseLeave={() => setCurrHover(-1)} onClick={() => setHistoryObj({ ...history_obj, par_id: obj.par_id, sub_id: obj.sub_id })} style={{ cursor: 'pointer', flexDirection: 'row', alignItems: 'center', display: 'flex', alignSelf: 'center', width: 210, justifyContent: 'space-between', backgroundColor: curr_hover == 1 ? '#cfd2d9' : null, height: 50 }}>
                        <MdHistory size={22} color='#0D1C41' style={{ marginLeft: 10 }} />
                        <text style={{ color: '#0D1C41', fontWeight: '600', fontSize: 16, fontFamily: 'Montserrat', marginRight: 10 }}>History</text>
                    </div>
                    <div onMouseEnter={() => setCurrHover(2)} onMouseLeave={() => setCurrHover(-1)} style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', alignSelf: 'center', width: 210, justifyContent: 'space-between', backgroundColor: curr_hover == 2 ? '#cfd2d9' : null, height: 50 }}>
                        <IoMdSearch size={22} color='#0D1C41' style={{ marginLeft: 10 }} />
                        <text style={{ color: '#0D1C41', fontWeight: '600', fontSize: 16, fontFamily: 'Montserrat', marginRight: 10 }}>Tags</text>
                    </div>
                </div> : null
            }

            <wc-toast></wc-toast>

            <ReactModal
                isOpen={create_folder}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 420, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setCreateFolder(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                <div style={{ display: 'flex', width: 660, height: 420, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setCreateFolder(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 22, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Create folder</text>
                    <wc-toast></wc-toast>
                    <div onClick={() => handleClick()} style={{ cursor: 'pointer', display: 'flex', width: 150, height: 150, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderStyle: 'solid', borderWidth: 1, borderRadius: 6, borderColor: '#0D1C41' }}>
                        {picture != null ? <img src={picture} style={{ height: 120, width: 120, alignSelf: 'center' }} /> : <FaPlus size={35} color='black' />}
                    </div>
                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ width: 394, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <input placeholder=' Folder name ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#F5F5F5', marginTop: 6, width: 320, height: 44, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#c5e3eb', outline: 'none' }} value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)} />
                        </div>
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Create</text>}
                            successText="Done"
                            onClick={() => { create_folder_db() }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div>

            </ReactModal >

            <ReactModal
                isOpen={create_sub_folder}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 300, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setCreateSubFolder(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                <div style={{ display: 'flex', width: 660, height: 300, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setCreateSubFolder(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 22, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Create folder of {sub_id != null ? parent_folder == 0 ? getName(sub_id) : arr1[sub_id] : ''}</text>
                    <wc-toast></wc-toast>

                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ width: 394, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <input placeholder=' Folder name ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#F5F5F5', marginTop: 6, width: 320, height: 44, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#c5e3eb', outline: 'none' }} value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)} />
                        </div>
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Create</text>}
                            successText="Done"
                            onClick={() => { create_sub_folder_db() }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div>

            </ReactModal >

            <ReactModal
                isOpen={share_modal}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 300, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setShareModal(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                <div style={{ display: 'flex', width: 660, height: 300, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setShareModal(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 20, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Share this folder</text>
                    <text style={{ fontWeight: '500', fontSize: 13, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginBottom: 10 }}>{sub_id != null ? getName(sub_id) : ''}</text>

                    <wc-toast></wc-toast>

                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#E3E5E5', height: 60, backgroundColor: '#f3f5f6', width: 500, alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center', borderRadius: 6 }}>
                            <input placeholder=' Email ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#f3f5f6', marginTop: 6, width: 440, height: 50, borderRadius: 10, outline: 'none', borderStyle: 'none' }} value={mail} onChange={(e) => setMail(e.currentTarget.value)} />
                            <div style={{ marginRight: 6 }}>
                                <Select
                                    components={{ DropdownIndicator: DropdownIndicator1 }}
                                    onChange={(e) => { if (e != null) setUserAccess(e.value) }}
                                    options={[{ 'label': 'Admin', 'value': 'Admin' }, { 'label': 'Viewer', 'value': 'View' }, { 'label': 'Editor', 'value': 'Edit' }]}
                                    placeholder={'Select'}
                                    className="basic-single4"
                                    isClearable={true}
                                    classNamePrefix="select"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "left",
                                            fontWeight: '400',
                                            height: 48,
                                            backgroundColor: '#c3c8d0',
                                            fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                            borderRadius: 10,
                                            borderColor: 'white', borderWidth: 0, outline: 'none',
                                            marginTop: -14
                                        })
                                    }}
                                    maxMenuHeight={200}
                                    menuShouldScrollIntoView={true}
                                />
                            </div>
                        </div>
                    </div>


                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Share</text>}
                            successText="Done"
                            onClick={() => { sharing_folder(full_company, full_folder) }}
                            style={{ backgroundColor: '#0D1C41', width: 90, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div>

            </ReactModal >

            <ReactModal
                isOpen={upload_obj != null}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 0.85 * width) / 2, top: 20, display: 'flex', width: 0.85 * width, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 0.90 * height, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setUploadObj(null)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                <div style={{ display: 'flex', width: 0.85 * width, height: 0.90 * height, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setUploadObj(null)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>

                    {upload_obj != null ? <div style={{ display: 'flex', width: 0.8380 * width, height: 530, alignSelf: 'center', backgroundColor: 'white', flexDirection: 'column', padding: 10, borderRadius: 8 }}>

                        <text style={{ fontWeight: 600, fontSize: 24, alignSelf: 'center', color: '#000000' }}>
                            {upload_obj.sub_folder_id.name}
                        </text>

                        <div style={{ display: 'flex', width: 950, height: 20, alignSelf: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 20, alignItems: 'center' }}>


                            <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                                {curr_folder.name}
                            </text>
                            <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 5 }} />
                            <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                                {curr_company_sub_folder.name}
                            </text>
                            <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 5 }} />
                            <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                                {upload_obj.par_id == 0 ? 'Building Services Management' : upload_obj.par_id == 1 ? 'Building Information Management' : 'Building Projects'}
                            </text>

                            {upload_obj.sub_id !== null && <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 5 }} />}

                            <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                                {upload_obj.sub_id == null ? null : arr[upload_obj.sub_id]}
                            </text>

                            <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 5 }} />

                            <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                                {upload_obj.sub_folder_id.name}
                            </text>
                        </div>


                        <div onDragOver={handleDragOver}
                            onDrop={handleDrop} onClick={() => openFileInput()} style={{ display: 'flex', width: 0.5625 * width, height: 200, alignSelf: 'center', justifyContent: 'center', backgroundColor: '#e6e8ec', flexDirection: 'column', padding: 10, borderRadius: 8, marginTop: 20, borderWidth: 1.5, borderStyle: 'dashed', borderColor: 'black' }}>
                            <img src={icon} style={{ width: 0.0694 * width, height: 100, alignSelf: 'center', marginLeft: 0 }} />

                            <text style={{ fontWeight: 600, fontSize: 22, alignSelf: 'center', color: '#0D1C4180' }}>
                                Upload Document
                            </text>

                            <text style={{ fontWeight: 400, fontSize: 18, alignSelf: 'center', color: '#0D1C4180' }}>
                                Browse or drag and drop documents to begin.
                            </text>
                        </div>
                        <div style={{ marginTop: 6, alignSelf: 'center', width: '99%', overflowY: 'scroll', height: 340, display: 'flex', flexDirection: 'column' }}>
                            {all_obj.map((item, indx) => <div style={{ padding: 6, borderRadius: 8, borderStyle: 'solid', borderWidth: 1, borderColor: '#0D1C4133', width: '95%', alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', height: 88, marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ width: 60, height: 60, backgroundColor: '#efefef', borderRadius: 5, justifyContent: 'center', alignItems: 'center', marginLeft: 6, display: 'flex' }}>
                                    <FaFolder size={20} color={'#5E5E5E'} />
                                </div>
                                <div style={{ width: '88%', display: 'flex', flexDirection: 'column', height: 85, marginTop: 4 }}>
                                    <div style={{ width: 170, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                                        <input value={item.title} onChange={(e) => updateAllTitle(indx, e.currentTarget.value)} placeholder='Reference' style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'lightgray', backgroundColor: 'transparent', height: 35, textAlign: 'center', color: '#1d1d1d', fontSize: 16, fontWeight: '500', fontFamily: 'Poppins', outline: 'none', width: '95%', alignSelf: 'center', borderRadius: 4 }} />
                                        <text style={{ fontWeight: '500', fontSize: 16, fontFamily: 'Montserrat', color: 'black' }}>.{item.file}</text>
                                    </div>
                                    <ProgressBar completed={upload_report[indx]} maxCompleted={100} />
                                    <text style={{ fontWeight: '600', fontSize: 10, fontFamily: 'Montserrat', color: upload_report[indx] == 100 ? '#00880E' : 'white' }}>Completed</text>
                                </div>
                            </div>)}
                        </div>

                    </div> : null}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Done</text>}
                            successText="Done"
                            onClick={() => { all_uploading() }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div>

            </ReactModal >



            <ReactModal
                isOpen={history_obj.par_id != null}
                style={{ overlay: { backdropFilter: "blur(3px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 960) / 2, top: 80, display: 'flex', width: 940, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 500, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1 } }}
                onRequestClose={() => setHistoryObj({ ...history_obj, par_id: null, sub_id: null })}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                {history_obj.par_id != null ? <div id='divToPrint' style={{ display: 'flex', width: 940, height: 500, justifyContent: 'center', flexDirection: 'column' }}>

                    <div onClick={() => setHistoryObj({ ...history_obj, par_id: null, sub_id: null })} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>

                    <text style={{ fontWeight: '600', fontSize: 22, color: '#00186D', textAlign: 'center', fontFamily: 'Montserrat', marginTop: 10 }}>History</text>
                    <div style={{ display: 'flex', width: 700, height: 20, alignSelf: 'center', justifyContent: 'space-evenly', flexDirection: 'row', marginTop: 1, alignItems: 'center' }}>

                        <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                            {curr_folder.name}
                        </text>
                        <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 0 }} />
                        <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                            {curr_company_sub_folder.name}
                        </text>
                        <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 0 }} />
                        <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                            {history_obj.par_id == 0 ? 'Building Services Management' : history_obj.par_id == 1 ? 'Building Information Management' : 'Building Projects'}
                        </text>

                        <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 0 }} />

                        <text style={{ fontWeight: 500, fontSize: 15, alignSelf: 'center', color: '#000000' }}>
                            {arr[history_obj.sub_id]}
                        </text>

                    </div>

                    <div style={{ marginTop: 6, width: 915, alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', height: 58, backgroundColor: '#F5F6FA', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                        <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>User</text>
                        <text style={{ width: '15%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Folder</text>
                        <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Title</text>
                        <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Date</text>
                        <text style={{ width: '10%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Action</text>
                    </div>

                    <div style={{ backgroundColor: '#b1b8cf', width: 915, height: 2, display: 'flex', alignSelf: 'center' }} />
                    <div style={{ width: 915, alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 320, overflowY: 'scroll', marginLeft: 10, overflowX: 'hidden' }}>
                        {getHistory().map((item, indx) => <div style={{ marginTop: 6, width: 915, alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', height: 50, backgroundColor: 'white' }}>
                            <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>{getUserName(item.user_id)}</text>
                            <text style={{ width: '15%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>{getFolderName(item.sub_folder_id)}</text>
                            <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>{getDocName(item.doc_id)}.{getDocFile(item.doc_id)}</text>
                            <text style={{ width: '25%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>{today_dats(item.ID)}</text>
                            <text style={{ width: '10%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>{item.action}</text>
                        </div>)}
                    </div>

                </div> : null}

            </ReactModal >

            <ReactModal
                isOpen={create_company_sub_folder != false}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 420, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setCreateCompanySubFolder(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                <div style={{ display: 'flex', width: 660, height: 420, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setCreateCompanySubFolder(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 22, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Create folder of {curr_folder == null ? '' : curr_folder.name}</text>
                    <wc-toast></wc-toast>
                    <div onClick={() => handleClick()} style={{ cursor: 'pointer', display: 'flex', width: 150, height: 150, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderStyle: 'solid', borderWidth: 1, borderRadius: 6, borderColor: '#0D1C41' }}>
                        {picture != null ? <img src={picture} style={{ height: 120, width: 120, alignSelf: 'center' }} /> : <FaPlus size={35} color='black' />}
                    </div>
                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ width: 394, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <input placeholder=' Folder name ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#F5F5F5', marginTop: 6, width: 320, height: 44, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#c5e3eb', outline: 'none' }} value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)} />
                        </div>
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Create</text>}
                            successText="Done"
                            onClick={() => { create_company_sub_folder_db(curr_folder.ID) }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div>

            </ReactModal >

            <ReactModal
                isOpen={create_third_folder != false && create_third_folder != null}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 300, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setCreateThirdFolder(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                <div style={{ display: 'flex', width: 660, height: 300, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setCreateThirdFolder(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 22, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Create folder of Building Projects</text>
                    <wc-toast></wc-toast>

                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ width: 394, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <input placeholder=' Folder name ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#F5F5F5', marginTop: 6, width: 320, height: 44, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#c5e3eb', outline: 'none' }} value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)} />
                        </div>
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Create</text>}
                            successText="Done"
                            onClick={() => { create_third_folder_db() }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div>

            </ReactModal >


            <ReactModal
                isOpen={user_modal_company != false}
                style={{ overlay: { backdropFilter: "blur(3px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 1000) / 2, top: 80, display: 'flex', width: 1000, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 500, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1 } }}
                onRequestClose={() => setUserModalCompany(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                {user_modal_company != null ? <div id='divToPrint' style={{ display: 'flex', width: 1000, height: 500, justifyContent: 'center', flexDirection: 'column' }}>

                    <div onClick={() => setUserModalCompany(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>

                    <text style={{ fontWeight: '600', fontSize: 22, color: '#00186D', textAlign: 'center', fontFamily: 'Montserrat', marginTop: 10 }}>Users of {user_modal_company.name}</text>

                    <div style={{ marginTop: 6, width: 995, alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', height: 58, backgroundColor: '#F5F6FA', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                        <text style={{ width: '30%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>User</text>
                        <text style={{ width: '35%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Path</text>
                        <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Date</text>
                        <text style={{ width: '5%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Access</text>
                    </div>

                    <div style={{ backgroundColor: '#b1b8cf', width: 990, height: 2, display: 'flex', alignSelf: 'center' }} />
                    <div style={{ width: 990, alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 320, overflowY: 'scroll', marginLeft: 10, overflowX: 'hidden' }}>
                        {getFullCompanyUsers(user_modal_company.ID).map((item, indx) => <div style={{ marginTop: 6, width: 990, alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', height: 50, backgroundColor: 'white' }}>
                            <text style={{ width: '30%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{(item.email)}</text>
                            <text style={{ width: '35%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{(item.path)}</text>
                            <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{today_dats(item.ID)}</text>
                            <text style={{ width: '5%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{item.role}</text>
                        </div>)}
                    </div>

                </div> : null}

            </ReactModal >

            <ReactModal
                isOpen={user_modal_company_sub != false}
                style={{ overlay: { backdropFilter: "blur(3px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 1000) / 2, top: 80, display: 'flex', width: 1000, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 500, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1 } }}
                onRequestClose={() => setUserModalCompanySub(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                {user_modal_company_sub != null ? <div id='divToPrint' style={{ display: 'flex', width: 1000, height: 500, justifyContent: 'center', flexDirection: 'column' }}>

                    <div onClick={() => setUserModalCompanySub(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>

                    <text style={{ fontWeight: '600', fontSize: 22, color: '#00186D', textAlign: 'center', fontFamily: 'Montserrat', marginTop: 10 }}>Users of {user_modal_company_sub.name}</text>

                    <div style={{ marginTop: 6, width: 995, alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', height: 58, backgroundColor: '#F5F6FA', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                        <text style={{ width: '30%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>User</text>
                        <text style={{ width: '35%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Path</text>
                        <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Date</text>
                        <text style={{ width: '5%', alignSelf: 'center', textAlign: 'center', fontWeight: '600', fontSize: 14, fontFamily: 'Montserrat', color: '#5E5E5E' }}>Access</text>
                    </div>

                    <div style={{ backgroundColor: '#b1b8cf', width: 990, height: 2, display: 'flex', alignSelf: 'center' }} />
                    <div style={{ width: 990, alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 320, overflowY: 'scroll', marginLeft: 10, overflowX: 'hidden' }}>
                        {getFullSubCompanyUsers(user_modal_company_sub.ID).map((item, indx) => <div style={{ marginTop: 6, width: 990, alignSelf: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex', height: 50, backgroundColor: 'white' }}>
                            <text style={{ width: '30%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{(item.email)}</text>
                            <text style={{ width: '35%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{(item.path)}</text>
                            <text style={{ width: '20%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{today_dats(item.ID)}</text>
                            <text style={{ width: '5%', alignSelf: 'center', textAlign: 'center', fontWeight: '500', fontSize: 12, fontFamily: 'Montserrat', color: 'black' }}>{item.role}</text>
                        </div>)}
                    </div>

                </div> : null}

            </ReactModal >

            <ReactModal
                isOpen={edit_company}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 420, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setEditCompany(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                {edit_company != false ? <div style={{ display: 'flex', width: 660, height: 420, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setEditCompany(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 22, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Edit folder</text>
                    <wc-toast></wc-toast>
                    <div onClick={() => handleClick()} style={{ cursor: 'pointer', display: 'flex', width: 150, height: 150, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderStyle: 'solid', borderWidth: 1, borderRadius: 6, borderColor: '#0D1C41' }}>
                        {picture != null ? <img src={picture} style={{ height: 120, width: 120, alignSelf: 'center' }} /> : <img src={edit_company.uri} style={{ height: 120, width: 120, alignSelf: 'center' }} />}
                    </div>
                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ width: 394, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <input placeholder=' Folder name ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#F5F5F5', marginTop: 6, width: 320, height: 44, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#c5e3eb', outline: 'none' }} value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)} />
                        </div>
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>
                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Update</text>}
                            successText="Done"
                            onClick={() => { edit_folder_db(edit_company.ID) }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div> : null}

            </ReactModal >

            <ReactModal
                isOpen={edit_sub_company}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 420, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setEditSubCompany(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                {edit_sub_company != false ? <div style={{ display: 'flex', width: 660, height: 420, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setEditSubCompany(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 22, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Edit folder of {curr_folder == null ? '' : curr_folder.name}</text>
                    <wc-toast></wc-toast>
                    <div onClick={() => handleClick()} style={{ cursor: 'pointer', display: 'flex', width: 150, height: 150, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderStyle: 'solid', borderWidth: 1, borderRadius: 6, borderColor: '#0D1C41' }}>
                        {picture != null ? <img src={picture} style={{ height: 120, width: 120, alignSelf: 'center' }} /> : <img src={edit_sub_company.uri} style={{ height: 120, width: 120, alignSelf: 'center' }} />}
                    </div>
                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ width: 394, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <input placeholder=' Folder name ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#F5F5F5', marginTop: 6, width: 320, height: 44, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#c5e3eb', outline: 'none' }} value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)} />
                        </div>
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>
                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Update</text>}
                            successText="Done"
                            onClick={() => { edit_company_sub_folder_db(edit_sub_company.ID) }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div> : null}

            </ReactModal >

            <ReactModal
                isOpen={delete_company != false}
                contentLabel="Inline Styles Modal Example"
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        color: 'lightsteelblue',
                        width: 500,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        height: 200,
                        marginLeft: (width - 500) / 2,
                        backgroundColor: 'white'
                    }
                }}
            >

                {delete_company != false ? <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 500, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'black' }}>  Are you sure to delete {delete_company.name} ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={() => { delete_folder_db(delete_company.ID) }} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeleteCompany(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#0D1C41", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div> : null}

            </ReactModal>

            <ReactModal
                isOpen={delete_sub_company != false}
                contentLabel="Inline Styles Modal Example"
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        color: 'lightsteelblue',
                        width: 600,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        height: 200,
                        marginLeft: (width - 600) / 2,
                        backgroundColor: 'white'
                    }
                }}
            >

                {delete_sub_company != false ? <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 600, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'black' }}>  Are you sure to delete {delete_sub_company.name} of {curr_folder != null ? curr_folder.name : null} ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={() => { delete_company_sub_folder_db(delete_sub_company.ID) }} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeleteSubCompany(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#0D1C41", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div> : null}

            </ReactModal>

            <ReactModal
                isOpen={edit_third_sub_folder != false}
                style={{ overlay: { backdropFilter: "blur(1px)", backgroundColor: 'transparent', display: 'flex', alignSelf: 'center', height: height, width: width, justifyContent: 'right' }, content: { backgroundColor: 'white', position: 'absolute', left: (width - 650) / 2, top: 100, display: 'flex', width: 650, alignSelf: 'center', flexDirection: 'column', borderRadius: 8, height: 320, borderColor: '#E7E7E7', borderStyle: 'solid', borderWidth: 1, overflowX: 'hidden' } }}
                onRequestClose={() => setEditThirdSubFolder(false)}
                closeTimeoutMS={100}
                contentLabel={"DELETE CATEGORY"}
                ariaHideApp={true}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={true}
                role={"dialog"}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
            >
                {edit_third_sub_folder != false ? <div style={{ display: 'flex', width: 660, height: 320, flexDirection: 'column', overflowX: 'hidden' }}>
                    <div onClick={() => setEditThirdSubFolder(false)} style={{ alignSelf: 'end', padding: 6, cursor: 'pointer', marginTop: -10 }}>{close_button}</div>
                    <text style={{ fontWeight: '600', fontSize: 22, textAlign: 'center', color: '#00186D', fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10 }}>Edit folder </text>
                    <wc-toast></wc-toast>

                    <div style={{ width: 550, alignSelf: 'center', marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ width: 394, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <input placeholder=' Folder name ' style={{ alignSelf: 'center', paddingLeft: 8, fontFamily: 'Montserrat', color: 'black', fontWeight: '500', fontSize: 16, backgroundColor: '#F5F5F5', marginTop: 6, width: 320, height: 44, borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: '#c5e3eb', outline: 'none' }} value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)} />
                        </div>
                    </div>

                    {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60 }}>
                        <Bounce color='green' size={40} />
                    </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>
                    </div>}

                    <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
                        <ReactiveButton
                            idleText={<text style={{ fontWeight: '500', fontSize: 20, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Update</text>}
                            successText="Done"
                            onClick={() => { edit_third_sub(edit_third_sub_folder.ID) }}
                            style={{ backgroundColor: '#0D1C41', width: 220, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 14 }}
                        />
                    </div>
                </div> : null}

            </ReactModal >

            <ReactModal
                isOpen={delete_third_sub_folder != false}
                contentLabel="Inline Styles Modal Example"
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        color: 'lightsteelblue',
                        width: 500,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        height: 200,
                        marginLeft: (width - 500) / 2,
                        backgroundColor: 'white'
                    }
                }}
            >

                {delete_third_sub_folder != false ? <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 500, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'black' }}>  Are you sure to delete {delete_third_sub_folder.name} ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={() => { delete_third_sub(delete_third_sub_folder.ID) }} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeleteThirdSubFolder(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#0D1C41", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div> : null}

            </ReactModal>

        </div > : <div style={{ display: 'flex', width: isMobile ? width : 'auto', height: isMobile ? height : height - 100, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>

            {show_doc != false ? <div style={{ display: 'flex', width: isMobile ? width : 0.68 * width, height: isMobile ? height : height - 100, alignSelf: 'center', backgroundColor: 'white', flexDirection: 'column', padding: 5, borderRadius: 8, marginBottom: 1, justifyContent: 'center' }}>


                <div style={{ display: 'flex', width: (0.96 * width), height: 100, alignSelf: 'center', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'row', marginTop: 100, alignItems: 'center', marginBottom: 5 }}>


                    <text style={{ fontWeight: 500, fontSize: 11, alignSelf: 'center', color: '#000000' }}>
                        {curr_folder.name}
                    </text>
                    <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 3 }} />
                    <text style={{ fontWeight: 500, fontSize: 11, alignSelf: 'center', color: '#000000', marginLeft: 3 }}>
                        {curr_company_sub_folder.name}
                    </text>
                    <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 3 }} />

                    <text style={{ fontWeight: 500, fontSize: 11, alignSelf: 'center', color: '#000000', marginLeft: 3 }}>
                        {show_doc.par_id == 0 ? 'Building Services Management' : show_doc.par_id == 1 ? 'Building Information Management' : 'Building Projects'}
                    </text>

                    <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 3 }} />

                    <text style={{ fontWeight: 500, fontSize: 11, alignSelf: 'center', color: '#000000', marginLeft: 3 }}>
                        {arr[show_doc.sub_id]}
                    </text>

                    <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 3 }} />

                    <text style={{ fontWeight: 500, fontSize: 11, alignSelf: 'center', color: '#000000', marginLeft: 3 }}>
                        {show_doc.folder.name}
                    </text>
                    <img src={Arrow} style={{ width: 14, height: 14, alignSelf: 'center', marginLeft: 3 }} />

                    <text style={{ fontWeight: 500, fontSize: 11, alignSelf: 'center', color: '#000000', marginLeft: 3 }}>
                        {show_doc.item.title}.{show_doc.item.file}
                    </text>
                </div>


                <div style={{ display: 'flex', width: isMobile ? width : 0.68 * width, height: '96%', alignSelf: 'center', justifyContent: 'center', backgroundColor: '#e6e8ec', flexDirection: 'column', padding: 5, borderRadius: 8, marginTop: 25, borderWidth: 1.5, borderColor: 'black' }}>
                    {(show_doc.item.file !== 'png' && show_doc.item.file !== 'gif' && show_doc.item.file !== 'jpg') ?
                        <PdfViewer
                            pdfUrl={show_doc.item.uri}
                        />
                        : <img style={{ objectFit: 'contain', alignSelf: 'center' }} src={show_doc.item.uri} width={0.78 * width} height={height - 220} />}
                </div>

            </div> : null}

            {mobile_view != false ? <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 15, right: 5 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 17, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Download</text>}
                    successText="Done"
                    onClick={() => { handleDownload(show_doc.item.uri, show_doc.item.title, show_doc.item.file); uploadHistory('Download', show_doc.item.ID, curr_folder.ID, show_doc.item.par_id, show_doc.item.sub_id, show_doc.folder.ID, show_doc.par_sub_folder == undefined ? null : show_doc.par_sub_folder.ID, show_doc.par_sub_sub_folder == undefined ? null : show_doc.par_sub_sub_folder.ID) }}
                    style={{ backgroundColor: '#0D1C41', width: 180, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 2 }}
                />
            </div> : null}

            {mobile_view != false ? <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 15, left: 5 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 17, color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Back</text>}
                    successText="Done"
                    onClick={() => { setMobileView(false) }}
                    style={{ backgroundColor: '#0D1C41', width: 180, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center', marginTop: 2 }}
                />
            </div> : null}
        </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps)(React.memo(Particular_folder))

/*

   <Popup
                                                trigger={<div className="menu-item"><VscThreeBars color='black' size={25} /></div>}
                                                position="left top"
                                                on="hover"
                                                closeOnDocumentClick
                                                mouseLeaveDelay={300}
                                                mouseEnterDelay={30}
                                                contentStyle={{ padding: '0px', border: 'none', marginRight: 50, width: 135, display: 'flex' }}
                                                arrow={true}
                                                overlayStyle={{ marginRight: 30 }}
                                                offsetX={5}
                                            >
                                                <div style={{ width: 130, display: 'flex', justifyContent: 'space-evenly', padding: 10, flexDirection: 'column', alignItems: 'center', height: 100 }}>
                                                    <div onClick={() => { setUploadObj({ ...upload_obj, company: curr_folder, par_id: 1, sub_id: 5, sub_folder_id: item }) }} style={{ width: 100, backgroundColor: '#0D1C41', height: 30, borderRadius: 6, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                        <BsUpload size={15} color='white' />
                                                        <text style={{ fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: 'white' }}>Upload</text>
                                                    </div>
                                                    <div onClick={() => { setEditThirdSubFolder(item); setFolderName(item.name) }} style={{ cursor: 'pointer', width: 100, backgroundColor: '#0D1C41', height: 30, borderRadius: 6, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row' }}>
                                                        <CiEdit size={15} color='white' />
                                                        <text style={{ fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: 'white', textAlign: 'left' }}>Edit <text style={{ color: '#0D1C41' }}>it</text></text>
                                                    </div>
                                                    <div onClick={() => { setDeleteThirdSubFolder(item) }} style={{ cursor: 'pointer', width: 100, backgroundColor: '#0D1C41', height: 30, borderRadius: 6, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row' }}>
                                                        <MdDelete size={15} color='white' />
                                                        <text style={{ fontWeight: '500', fontSize: 14, fontFamily: 'Montserrat', color: 'white' }}>Delete<text style={{ color: '#0D1C41' }}></text></text>
                                                    </div>
                                                </div>
                                            </Popup>

                                            */