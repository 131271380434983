

// Import the functions you need from the SDKs you need

import firebase from 'firebase/compat/app';
//import firebase from "firebase";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore"
import "firebase/compat/messaging"
import "firebase/compat/app-check"
//import "firebase/compat/messaging"

// https://sqauredcircle-default-rtdb.firebaseio.com/

const config = {
  apiKey: "AIzaSyAJ7Wv1z9-kCEaG_8Fr_IHQP6OmPtY53Ig",
  authDomain: "sqauredcircle.firebaseapp.com",
  databaseURL: "https://sqauredcircle-default-rtdb.firebaseio.com",
  projectId: "sqauredcircle",
  storageBucket: "sqauredcircle.appspot.com",
  messagingSenderId: "65075327945",
  appId: "1:65075327945:web:e54ce4f4b1dffa14086e18",
  measurementId: "G-FTCDSEL98D"
};

firebase.initializeApp(config);
// const db=firebase.database();
export const db = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
//export const auth = firebase.auth();
//export const firestore = firebase.firestore()
//export const firebases = firebase;