import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import '@react-pdf-viewer/zoom/lib/styles/index.css';
import CustomZoomPlugin from './CustomZoomPlugin.tsx';

const PdfViewer = ({ pdfUrl }) => {

  const CustomZoomPluginInstance = CustomZoomPlugin();
 // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div style={{ height: '750px' }}>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
        <Viewer
          fileUrl={pdfUrl}
          plugins={[CustomZoomPluginInstance]}
        />
      </Worker>
    </div>
  );
};

export default PdfViewer;