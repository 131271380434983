
import './App.css';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
    HashRouter
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { auth } from './config';
//import { ToastContainer, toast } from 'react-toastify';
import { toast } from 'wc-toast'
import Login from './screens/Login';

import { Provider } from "react-redux";
import store from "./screens/redux/stores/index";
import AllFiles from './screens/AllFiles';
import Signup from './screens/Signup';
import Favourites from './screens/Favourites';
import Users from './screens/Users';
import Activities from './screens/Activities';
import Admins from './screens/Admins';
import Main_folder from './screens/main_folder';
import Main_sub_folder from './screens/main_sub_folder';
import Sub_folders from './screens/sub_folders';
import Particular_folder from './screens/particular_folder';
import Forget from './screens/Forget';

//import 'react-toastify/dist/ReactToastify.css';

export default function App() {


    const ___doSingIn = async () => {
        try {
            let response = await auth.signInWithEmailAndPassword("n.joy@boomsoftware.co.uk", "2103199j");
            if (response && response.user) {
                console.log('User ', response)
            }
        } catch (e) {
            console.error(e.message);
        }
    };

    useEffect(() => {
        ___doSingIn()
    }, [])

    return (
        <div className="App">
            <Provider store={store}>
                <HashRouter>
                    <Routes>

                        <Route path="/" element={<Login />} />
                        <Route path="/Signup" element={<Signup />} />
                        <Route path="/Forget" element={<Forget />} />
                        <Route path="/AllFiles" element={<AllFiles />} />
                        <Route path="/Favourites" element={<Favourites />} />
                        <Route path="/Users" element={<Users />} />
                        <Route path="/Activities" element={<Activities />} />
                        <Route path="/Admin" element={<Admins />} />
                        <Route path="/Main_folder" element={<Main_folder />} />
                        <Route path="/Main_sub_folder" element={<Main_sub_folder />} />
                        <Route path="/Sub_folders" element={<Sub_folders />} />
                        <Route path="/Particular_folder" element={<Particular_folder />} />

                    </Routes>
                </HashRouter>
            </Provider>
            <wc-toast></wc-toast>
        </div>
    );
}