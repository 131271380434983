
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/square_logo.png'
import logo1 from './promas1.png'
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast'
//import 'react-toastify/dist/ReactToastify.css';
import { db, firestore, storage } from '../config';
import { favourite_active, favourite_inactive, files_active_icon, files_inactive_icon, link_inactive, settings_inactive, tags_inactive, user_active, user_inactive } from '../assets/Icons';
import { FaAngleLeft, FaFile, FaRegEye, FaRegSquare, FaSquareCheck } from "react-icons/fa6";
import FlatList from 'flatlist-react';
import Pic from './Pic.png'
import Search from './search.png'
import menu1 from './menu1.png'
import Modal from 'react-responsive-modal';
import arrow2 from './arrow2.png'
import arrow3 from './arrow3.png'
import rectangle from './Rectangle.png'
import Component1 from './Component1.png'
import Component2 from './Component2.png'
import Component3 from './Component3.png'

import ProgressBar from "@ramonak/react-progress-bar";

import D1 from './D1.png'
import D2 from './D2.png'
import D3 from './D3.png'
import D4 from './D4.png'
import D5 from './D5.png'
import D6 from './D6.png'
import D7 from './D7.png'
import D8 from './D8.png'
import ReactModal from 'react-modal';

import { FaPlus } from "react-icons/fa6";
import { FaPlusCircle } from 'react-icons/fa';
import { IoMdClose, IoMdSearch, IoMdShare } from 'react-icons/io';
import ReactiveButton from 'reactive-button';

import { Bounce } from "react-activity";
import "react-activity/dist/library.css";
import { FaFolder } from "react-icons/fa";

import { IoMdEye } from "react-icons/io";
import { BsUpload } from "react-icons/bs";
import Arrow from './arrow1.png'
import icon from './icon.png'
import { MdAdminPanelSettings, MdHistory, MdOutlineDelete } from 'react-icons/md';

import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import Select, { components } from 'react-select';
import { encrypt, encryptPass } from '../Crypto';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { RxActivityLog } from 'react-icons/rx';
import { AiTwotoneEdit } from 'react-icons/ai';


const fav_icon = <FaStar size={25} color='black' />
const non_fav_icon = <FaRegStar size={25} color='black' />

const activity_icon = <RxActivityLog size={17} color='white' />
const inactivity_icon = <RxActivityLog size={17} color='#A2B0BF' />

const admin_icon = <MdAdminPanelSettings size={20} color='white' />
const inactivity_admin = <MdAdminPanelSettings size={20} color='#A2B0BF' />

const left_bar = '#0D1C41'

const folder_db = db.ref('folders');
//const subfolder_db = db.ref('sub_folders');

const close_button = <IoMdClose size={20} color={'black'} />

const search_icons = <RiArrowDropDownLine color='#0b6d0f' size={27} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={27} />

const admindb = db.ref('admindb')

const company_sub_folder_db = db.ref('company_sub_folders');


function Admins({ items }) {

    console.log('Items ', window.innerHeight)

    let myobj = items.myid;

    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(Math.max(950, window.innerHeight));

    const windowSizeHandler = () => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(Math.max(950, window.innerHeight))
    };


    useEffect(() => {
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [all, setAll] = useState([])

    useEffect(() => {
        let subscribe = admindb.on('value', (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val());
                arr.sort(function (a, b) {
                    return b.reference - a.reference
                })
                setAll([...arr]);
            }
            else setAll([])
        })
        return () => subscribe()
    }, [])

    const [quote, setQuote] = useState(false);
    const [request, setRequest] = useState(false)
    const [general, setGeneral] = useState(false)
    const [problem, setProblem] = useState(false)

    const [company, setCompany] = useState('')
    const [contact, setContact] = useState('')
    const [company_telephone, setCompanyTelephone] = useState('')
    const [company_mail, setCompanyMail] = useState('')

    const [service_type, setServiceType] = useState('')
    const [survey, setSurvey] = useState('')

    const [address, setAddress] = useState('')
    const [post, setPost] = useState('')
    const [site, setSite] = useState('')
    const [mail, setMail] = useState('')
    const [telephone, setTelephone] = useState('')

    const [brief, setBrief] = useState('')
    const [comment, setComment] = useState('')

    const [time, setTime] = useState(new Date().getTime())

    const [assign, setAssign] = useState([])

    const DropdownIndicator1 = props => {
        console.log('Props ', props)
        if (props.isFocused) return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 30, width: 35, borderRadius: 4, marginTop: -2, alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: -40, backgroundColor: 'white' }}>

                    </div>
                </components.DropdownIndicator>
            )
        );
        else return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 30, width: 35, borderRadius: 4, marginTop: -2, alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: -40, backgroundColor: 'white' }}>

                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const dott = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',
        fontFamily: 'Montserrat', color: '#00186D', fontWeight: '700', fontSize: 13,
    });

    const dot11 = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',
        fontFamily: 'Montserrat', color: 'black', fontWeight: '700', fontSize: 13,
        textAlign: 'left',
        width: '100%'
    });

    const dot2 = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',
        fontFamily: 'Montserrat', color: '#112776', fontWeight: '700', fontSize: 20,
        backgroundColor: '#d9d9e2',
        textAlign: 'left',
        width: '100%',
        height: 300,
        flexDirection: 'column'
    });

    const dot22 = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',
        fontFamily: 'Montserrat', color: 'black', fontWeight: '700', fontSize: 13,
        textAlign: 'left',
        width: '100%',
        flexDirection: 'column'
    });

    function make_date(id) {
        return moment(new Date(Number(id))).format('DD / MM / YYYY') + " " + moment(new Date(Number(id))).format('HH : mm ')
    }

    function changeCharacter(str, indx, val) {
        // Replace the character at index 4 with ','
        str = str.split(''); // Convert the string to an array of characters
        str.splice(indx, 1, val); // Replace the character at index 4 with ','
        str = str.join('');
        return str;
    }

    function make_id(val) {
        let str = String(val);
        let ans = str;
        for (let i = 0; i < (5 - str.length); i++) {
            ans = '0' + ans
        }
        return changeCharacter(ans, 0, '1');
    }

    useEffect(() => {
        console.log('assign ', assign)
    }, [assign])

    async function sendMail() {
        // console.log('Assign ',assign)

        if (assign.length != 0) {

            let myobj = {
                company_name: company,
                contact: contact,
                company_telephone: company_telephone,
                company_mail: company_mail,
                service_type: service_type,
                survey: survey,
                address: address,
                post: post,
                site: site,
                telephone: telephone,
                mail: mail,
                brief: brief,
                comment: comment,
                reference: time,

                quote: quote,
                request: request,
                general: general,
                problem: problem,
                job: job,

                time: make_date(time),
                assign: assign,
                id: make_id(all.length + 1)
            }

            console.log('assign ', assign)

            await admindb.child(String(myobj.reference)).set(myobj)

            
            if (assign.length == 1 && assign[0].label != 'All') await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_table?mail=${assign[0].label}&obj=${JSON.stringify(myobj)}`)
            else if (assign.length == 1 && assign[0].label == 'All') {
                let all = getEmails()
                for (let i = 0; i < all.length; i++) {
                    await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_table?mail=${all[i].label}&obj=${JSON.stringify(myobj)}`)
                }
            }
            else {
                for (let i = 0; i < assign.length; i++) {
                    await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_table?mail=${assign[i].label}&obj=${JSON.stringify(myobj)}`)
                }
            }
            
            setAddMail(false);

            toast('Mail has been sent')
        }
        else toast('You must need to assign to someone')
    }

    const [loading, setLoading] = useState('idle')

    async function ResendMail() {
        // console.log('Assign ',assign)

        if (assign != '') {

            setLoading('loading')

            let myobj = {
                company_name: company,
                contact: contact,
                company_telephone: company_telephone,
                company_mail: company_mail,
                service_type: service_type,
                survey: survey,
                address: address,
                post: post,
                site: site,
                telephone: telephone,
                mail: mail,
                brief: brief,
                comment: comment,
                reference: edit_pop.reference,

                quote: quote,
                request: request,
                general: general,
                problem: problem,
                job: job,

                time: make_date(time),
                assign: assign,
                id: edit_pop.id
            }

            await admindb.child(String(myobj.reference)).set(myobj)

            if (assign.length == 1 && assign[0].label != 'All') await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_table?mail=${assign[0].label}&obj=${JSON.stringify(myobj)}`)
            else if (assign.length == 1 && assign[0].label == 'All') {
                let all = getEmails()
                for (let i = 0; i < all.length; i++) {
                    await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_table?mail=${all[i].label}&obj=${JSON.stringify(myobj)}`)
                }
            }
            else {
                for (let i = 0; i < assign.length; i++) {
                    await fetch(`https://us-central1-sqauredcircle.cloudfunctions.net/app/send_table?mail=${assign[i].label}&obj=${JSON.stringify(myobj)}`)
                }
            }
            //setAddMail(false);
            setLoading('success')
            // setEditPop(false)
            toast('Mail has been resent')
        }
        else toast('You must need to assign to someone')
    }

    const [curr_mail, setCurrMail] = useState(null)
    const [add_mail, setAddMail] = useState(false)

    const ref = useRef()

    const service_types = [
        { label: 'Asbestos Survey', value: 'Asbestos Survey' },
        { label: 'Asbestos Removal', value: 'Asbestos Removal' },
        { label: 'Building Surveying', value: 'Building Surveying' },
        { label: 'Building & Facilities Management', value: 'Building & Facilities Management' },
        { label: 'CDM Principal Designer', value: 'CDM Principal Designer' },
        { label: 'Fire Risk Assessment', value: 'Fire Risk Assessment' },
        { label: 'Fire Door Survey', value: 'Fire Door Survey' },
        { label: 'Fire Protection', value: 'Fire Protection' },
        { label: 'Health & Safety', value: 'Health & Safety' },
        { label: 'Lead Sampling', value: 'Lead Sampling' },
        { label: 'Legionella / Water Testing', value: 'Legionella / Water Testing' },
        { label: 'Pigeon Guano', value: 'Pigeon Guano' },
        { label: 'Portable Appliance Testing', value: 'Portable Appliance Testing' }
    ]

    const survey_types = [
        { label: 'Asbestos Management', value: 'Asbestos Management' },
        { label: 'Asbestos Refurb/Demo', value: 'Asbestos Refurb/Demo' },
        { label: 'Asbestos Reinspection', value: 'Asbestos Reinspection' },
        { label: 'Asbestos Removals Audit', value: 'Asbestos Removals Audit' },
        { label: 'Building Condition', value: 'Building Condition' },
        { label: 'Building Delaps', value: 'Building Delaps' },
        { label: 'Measured Survey', value: 'Measured Survey' },
        { label: 'Property Valuation', value: 'Property Valuation' },
        { label: 'Risk Assessment', value: 'Risk Assessment' },
        { label: 'Site Safety Audit', value: 'Site Safety Audit' },
    ]

    const [job, setJob] = useState(false)

    const [edit_pop, setEditPop] = useState(false)

    useEffect(() => {
        if (edit_pop != false) {
            setQuote(edit_pop.quote)
            setGeneral(edit_pop.general)
            setRequest(edit_pop.request)
            setProblem(edit_pop.problem)
            setJob(edit_pop.job)
            setCompany(edit_pop.company_name)
            setCompanyMail(edit_pop.company_mail)
            setCompanyTelephone(edit_pop.company_telephone)
            setContact(edit_pop.contact)
            setServiceType(edit_pop.service_type)
            setSurvey(edit_pop.survey)
            setAddress(edit_pop.address)
            setPost(edit_pop.post)
            setSite(edit_pop.site)
            setTelephone(edit_pop.telephone)
            setMail(edit_pop.mail)
            setBrief(edit_pop.brief)
            setComment(edit_pop.comment)
        }
    }, [edit_pop])

    const [delete_pop, setDeletePopUp] = useState(false)
    const [edit_pop_mail, setEditPopMail] = useState(false)
    const [edit_mail, setEditMail] = useState('')
    const [add_pop, setAddPop] = useState(false)

    function getUsers(user_obj) {
        let arr = Object.values(user_obj);
        let ans = '';
        for (let i = 0; i < arr.length; i++) {
            if (i != arr.length - 1) {
                ans += arr[i].label + ","
            }
            else ans += arr[i].label
        }
        return ans;
    }

    const [admin_users, setAdminUsers] = useState([])

    useEffect(() => {
        const admin_users = db.ref('admin_emails')
        let subscribe = admin_users.on('value', (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val())
                arr.sort(function (a, b) {
                    return b.ID - a.ID
                })
                setAdminUsers([...arr])
            }
            else setAdminUsers([])
        })
        return () => subscribe()
    }, [])

    function make_see(id, val) {
        let temp = [...admin_users]
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].ID == id) {
                temp[i].see = val;
                break;
            }
        }
        setAdminUsers([...temp])
    }

    const [search, setSearch] = useState('')

    function make_date(id) {
        return moment(new Date(Number(id))).format('DD/MM/YYYY')
    }

    function getSearchUser() {
        let temp = [];
        for (let i = 0; i < admin_users.length; i++) {
            if (String((admin_users[i].email)).toLowerCase().includes(search.toLowerCase())) {
                temp.push(admin_users[i])
            }
        }
        return temp;
    }

    const [curr_user, setCurrUser] = useState(null)

    async function deleteUser() {
        const admin_users = db.ref('admin_emails');
        await admin_users.child(String(delete_pop.ID)).remove()
        toast('This user has been deleted')
        setDeletePopUp(false)
    }

    async function make_edit() {
        const admin_users = db.ref('admin_emails');
        await admin_users.child(String(edit_pop.ID)).update({ email: (edit_mail) })
        toast('This user has been updated')
        setEditPopMail(false)
        setEditMail('')
    }

    async function make_add() {
        const admin_users = db.ref('admin_emails');
        let ID = new Date().getTime()
        await admin_users.child(String(ID)).set({ ID: ID, email: (edit_mail) })
        toast('This user has been added')
        setAddPop(false)
        setEditMail('')
    }

    function getEmails() {
        let arr = [...admin_users];
        let temp = [];
        temp.push({ label: 'All', value: 'All' })
        for (let i = 0; i < arr.length; i++) {
            temp.push({ label: arr[i].email, value: arr[i].email })
        }
        return temp;
    }

    const [all_user_pop, setAllUserPop] = useState(false)

    return (
        <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', width: windowWidth, height: windowHeight, position: 'absolute', top: 0, left: 0, overflowX: 'hidden' }}>


            <div style={{ backgroundColor: 'white', display: 'flex', position: 'absolute', top: 0, left: 0, flexDirection: 'column', height: windowHeight, width: windowWidth * 0.20, textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: left_bar, width: windowWidth * 0.20, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src={logo1}
                        alt="Logo"
                        style={{ width: 230, marginTop: 1, height: 68, alignSelf: 'center', objectFit: 'contain', borderRadius: 4 }}
                    />
                </div>

                <div onClick={() => { navigate('/AllFiles') }} style={{ cursor: 'pointer', marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 0.18 * windowWidth, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ width: 0.14 * windowWidth, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{files_inactive_icon}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '600', fontFamily: 'Montserrat' }}>All files</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Favourites')} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * windowWidth, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * windowWidth, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{favourite_inactive}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Favourites</text>
                    </div>
                </div>


                <div onClick={() => { navigate('/Users') }} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * windowWidth, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * windowWidth, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Users</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Activities')} style={{ cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * windowWidth, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * windowWidth, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{inactivity_icon}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Activities</text>
                    </div>
                </div>

                <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 0.18 * windowWidth, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 0.14 * windowWidth, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center', backgroundColor: '#0D1C41' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{admin_icon}</div>
                        <text style={{ color: '#A2B0BF', marginLeft: 30, fontWeight: '500', fontFamily: 'Montserrat' }}>Admin</text>
                    </div>
                </div>


            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: windowWidth * 0.20, width: windowWidth * 0.80, position: 'absolute', top: 0, height: windowHeight, backgroundColor: 'white' }}>

                <div style={{ height: 100, width: windowWidth * 0.80, justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 5, height: 54, width: 450 }}>

                        <text style={{ marginLeft: 8, fontSize: 22, fontWeight: '600', letterSpacing: 1.01, color: 'black', textAlign: 'left', fontFamily: 'Montserrat' }}>Admin</text>
                    </div>
                    <div style={{ display: 'flex', width: 0.2618 * windowWidth, height: 46, alignSelf: 'center', justifyContent: 'space-evenly', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: 130, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 0 }}>

                            <ReactiveButton
                                idleText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>Send mail</text>}
                                successText="Done"
                                disabled={add_mail == false}
                                onClick={() => { sendMail() }}
                                style={{ backgroundColor: left_bar, width: 120, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center' }}
                            />
                        </div>
                        <div style={{ width: 130, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 0 }}>
                            <ReactiveButton
                                idleText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>{all_user_pop ? 'All mails' : 'All Users'}</text>}
                                successText="Done"
                                onClick={() => { setAllUserPop(!all_user_pop) }}
                                style={{ backgroundColor: all_user_pop ? '#3F3D87' : left_bar, width: 120, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center' }}
                            />
                        </div>
                        <FaPlusCircle onClick={() => {
                            setMail('');
                            setCompanyMail('');
                            setCompany('');
                            setContact('');
                            setCompanyTelephone('');
                            setServiceType('');
                            setSurvey('');
                            setJob(false); setQuote(false); setRequest(false); setProblem(false); setJob(false); setGeneral(false)
                            setAddress(''); setPost(''); setSite(''); setTelephone(''); setBrief(''); setComment(''); setTime(new Date().getTime()); setAddMail(true)
                        }} style={{ cursor: 'pointer' }} size={25} color={left_bar} />
                    </div>
                </div>

                <div style={{ width: windowWidth * 0.80, display: 'flex', flexDirection: 'column', alignSelf: 'center', backgroundColor: '#F5F6FA', height: windowHeight - 100, overflowY: 'scroll' }}>

                    {(add_mail || curr_mail || edit_pop != false) ? <div onClick={() => { setAddMail(false); setCurrMail(null) }} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 5, height: 54, width: 350 }}>
                        <div onClick={() => { setAddMail(false); setCurrMail(null); setEditPop(false) }} style={{ cursor: 'pointer', height: 46, width: 46, borderRadius: 8, justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F5F5', display: 'flex' }}>
                            <FaAngleLeft onClick={() => { setAddMail(false); setCurrMail(null) }} color='black' size={20} />
                        </div>
                        <text onClick={() => { setEditPop(false) }} style={{ marginLeft: 8, fontSize: 22, fontWeight: '600', letterSpacing: 1.01, color: 'black', textAlign: 'left', fontFamily: 'Montserrat' }}>Back</text>
                        {edit_pop != false ? <div style={{ width: 180, height: 46, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 0, marginLeft: 50 }}>
                            <ReactiveButton
                                idleText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>Resend mail</text>}
                                successText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>✅️ Resent</text>}
                                loadingText={<text style={{ fontSize: 14, textAlign: 'center', fontWeight: '600', color: 'white', fontFamily: 'Montserrat' }}>⏳ Resending mail</text>}
                                onClick={() => { ResendMail() }}
                                buttonState={loading}
                                style={{ backgroundColor: left_bar, width: 170, borderRadius: 6, alignSelf: 'center', height: 44, display: 'flex', justifyContent: 'center' }}
                            />
                        </div> : null}
                    </div> : null}

                    {(add_mail && edit_pop == false) ? <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, alignSelf: 'center', backgroundColor: 'white', marginTop: 20, height: windowHeight - 220 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: 0.57 * windowWidth, height: 'auto', alignSelf: 'center', backgroundColor: 'white' }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: '#0D1C41', borderLeftWidth: 1, borderBottomWidth: 0, borderTopWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>


                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Reference No.
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 65, alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Enquiry Type:
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Company Name:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Contact Name:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Telephone No:
                                    </text>
                                </div>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: 'white', borderLeftWidth: 0, borderBottomWidth: 0, borderTopWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>



                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 300, fontSize: 14, marginLeft: 10, textAlign: 'left' }}>
                                     SC- {make_id(all.length + 1)}
                                    </text>
                                </div>


                                <div style={{ display: 'flex', flexWrap: 'wrap', width: 0.44 * windowWidth, height: 65, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 60, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!quote ? <FaRegSquare onClick={() => { if (quote) setQuote(!quote); else { setQuote(true); setRequest(false); setProblem(false); setGeneral(false); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setQuote(true); setRequest(false); setProblem(false); setGeneral(false) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Quote
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 130, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!request ? <FaRegSquare onClick={() => { if (request) setRequest(!request); else { setQuote(false); setRequest(true); setProblem(false); setGeneral(false); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setQuote(false); setRequest(true); setProblem(false); setGeneral(false) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Call Back Request
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 150, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!problem ? <FaRegSquare onClick={() => { if (problem) setProblem(!problem); else { setQuote(false); setRequest(false); setProblem(true); setGeneral(false); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setQuote(false); setRequest(false); setProblem(true); setGeneral(false) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Problem / Complaint
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 150, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!job ? <FaRegSquare onClick={() => { { setQuote(false); setRequest(false); setProblem(false); setGeneral(false); setJob(true) } }} /> : <FaSquareCheck onClick={() => { setGeneral(!general) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            New Job Registration
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 0.34 * windowWidth, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!general ? <FaRegSquare onClick={() => { { setQuote(false); setRequest(false); setProblem(false); setGeneral(true); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setGeneral(!general) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            General Enquiry / Request for Information
                                        </text>
                                    </div>


                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={company} onChange={(e) => setCompany(e.currentTarget.value)} placeholder='Company name' style={{ alignSelf: 'center', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={contact} onChange={(e) => setContact(e.currentTarget.value)} placeholder='Contact name' style={{ alignSelf: 'center', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>


                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <input value={company_telephone} onChange={(e) => setCompanyTelephone(e.currentTarget.value)} placeholder='Telephone no.' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', paddingLeft: 5, fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 35, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black', marginLeft: 5 }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Email:
                                        </text>
                                    </div>

                                    <input value={company_mail} onChange={(e) => setCompanyMail(e.currentTarget.value)} placeholder='Company email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', paddingLeft: 10, fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C4133', borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                PROJECT DETAILS
                            </text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: 0.57 * windowWidth, height: 'auto', alignSelf: 'center', backgroundColor: 'white' }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: '#0D1C41', borderLeftWidth: 1, borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 40, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Service Type
                                    </text>
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 40, alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Project Address
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Postcode:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Site Contact:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Telephone No:
                                    </text>
                                </div>



                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: 'white', borderLeftWidth: 0, borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>




                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 40, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>


                                    <div style={{ alignSelf: 'center', justifyContent: 'left', fontFamily: 'Roboto', paddingLeft: 10, color: 'black', fontWeight: '400', width: 0.20 * windowWidth, height: 40, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }}>

                                        <Select
                                            components={{ DropdownIndicator: DropdownIndicator1 }}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setServiceType(e.label)
                                                }
                                            }}
                                            options={service_types}
                                            placeholder={'Choose an item.'}
                                            className="basic-single4"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textAlign: "left",
                                                    fontWeight: '400',
                                                    height: 30,
                                                    backgroundColor: 'white',
                                                    fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                                    borderRadius: 0,
                                                    borderWidth: 0, outline: 'none',
                                                    marginLeft: -8
                                                }),
                                                placeholder: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                singleValue: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                menu: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot11('white')

                                                }),
                                                menuList: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot22('white')

                                                }),
                                            }}
                                            maxMenuHeight={200}
                                            menuShouldScrollIntoView={true}
                                        />

                                    </div>


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 40, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black' }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Survey:
                                        </text>
                                    </div>

                                    <div style={{ alignSelf: 'center', justifyContent: 'left', fontFamily: 'Roboto', paddingLeft: 10, color: 'black', fontWeight: '400', width: 0.20 * windowWidth, height: 40, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }}>

                                        <Select
                                            components={{ DropdownIndicator: DropdownIndicator1 }}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setSurvey(e.label)
                                                }
                                            }}
                                            options={survey_types}
                                            placeholder={'Choose an item.'}
                                            className="basic-single4"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textAlign: "left",
                                                    fontWeight: '400',
                                                    height: 30,
                                                    backgroundColor: 'white',
                                                    fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                                    borderRadius: 0,
                                                    borderWidth: 0, outline: 'none',
                                                    marginLeft: -8
                                                }),
                                                placeholder: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                singleValue: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                menu: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot11('white')

                                                }),
                                                menuList: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot22('white')

                                                }),
                                            }}
                                            maxMenuHeight={200}
                                            menuShouldScrollIntoView={true}
                                        />

                                    </div>
                                </div>




                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, justifyContent: 'flex-start', height: 40, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={address} autoComplete="off" onChange={(e) => setAddress(e.currentTarget.value)} placeholder='Project address' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 59, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={post} autoComplete="off" onChange={(e) => setPost(e.currentTarget.value)} placeholder='Post Code' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={site} autoComplete="off" onChange={(e) => setSite(e.currentTarget.value)} placeholder='Site Contact' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <input autoComplete="off" value={telephone} onChange={(e) => setTelephone(e.currentTarget.value)} placeholder='Telephone no.' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 35, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black' }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Email:
                                        </text>
                                    </div>

                                    <input autoComplete="off" value={mail} onChange={(e) => setMail(e.currentTarget.value)} placeholder='Email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8, paddingLeft: 10 }} />

                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                BRIEF DESCRIPTION OF PROJECT & REQUIREMENTS
                            </text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'center', justifyContent: 'center', height: 90, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <textarea value={brief} onChange={(e) => setBrief(e.currentTarget.value)} placeholder='Write ' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.555 * windowWidth, height: 80, backgroundColor: 'white', outline: 'none', borderStyle: 'none', }} />

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                ADDITIONAL COMMENTS / INFORMATION (INCLUDING KEY DATES)
                            </text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'center', justifyContent: 'center', height: 90, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <textarea value={comment} onChange={(e) => setComment(e.currentTarget.value)} placeholder='Write ' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.555 * windowWidth, height: 80, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 50, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                ASSIGNED TO:
                            </text>
                        </div>

                        <div onClick={() => ref?.current.scrollIntoView()} style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'flex-start', justifyContent: 'flex-start', height: 70, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <div style={{ width: 600, display: 'flex', flexDirection: 'column' }}>
                                <Select
                                    components={{ DropdownIndicator: DropdownIndicator1 }}
                                    onChange={(e) => {
                                        if (e != null) {
                                            setAssign([...e])
                                        }
                                    }}
                                    options={getEmails()}
                                    placeholder={'Choose an email from list .'}
                                    className="basic-singlemulti"
                                    classNamePrefix="select"
                                    isMulti={true}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "left",
                                            fontWeight: '400',
                                            height: 50,
                                            backgroundColor: 'white',
                                            fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                            borderRadius: 0,
                                            borderWidth: 0, outline: 'none',
                                            marginLeft: 2
                                        }),
                                        placeholder: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dott('white')

                                        }),
                                        singleValue: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dott('white')

                                        }),
                                        menu: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dot11('white')

                                        }),
                                        menuList: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dot22('white')

                                        }),
                                    }}
                                    maxMenuHeight={200}
                                    menuShouldScrollIntoView={true}
                                    menuPlacement='top'
                                />
                            </div>
                        </div>

                        <div ref={ref} />


                    </div> : (curr_mail && edit_pop == false) ? <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, alignSelf: 'center', backgroundColor: 'white', marginTop: 20, height: windowHeight - 220 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: 0.57 * windowWidth, height: 'auto', alignSelf: 'center', backgroundColor: 'white' }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: '#0D1C41', borderLeftWidth: 1, borderBottomWidth: 0, borderTopWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>


                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Reference No.
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 65, alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Enquiry Type:
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Company Name:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Contact Name:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Telephone No:
                                    </text>
                                </div>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: 'white', borderLeftWidth: 0, borderBottomWidth: 0, borderTopWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>



                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 300, fontSize: 14, marginLeft: 10, textAlign: 'left' }}>
                                      SC- {changeCharacter(curr_mail.id, 0, '1')}
                                    </text>
                                </div>


                                <div style={{ display: 'flex', flexWrap: 'wrap', width: 0.44 * windowWidth, height: 65, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 60, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!curr_mail.quote ? <FaRegSquare /> : <FaSquareCheck />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Quote
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 130, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!curr_mail.request ? <FaRegSquare /> : <FaSquareCheck />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Call Back Request
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 150, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!curr_mail.problem ? <FaRegSquare /> : <FaSquareCheck />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Problem / Complaint
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 150, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!curr_mail.job ? <FaRegSquare /> : <FaSquareCheck />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            New Job Registration
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 0.34 * windowWidth, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!curr_mail.general ? <FaRegSquare /> : <FaSquareCheck />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            General Enquiry / Request for Information
                                        </text>
                                    </div>


                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={curr_mail.company_name} disabled placeholder='Company name' style={{ alignSelf: 'center', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={curr_mail.contact} disabled placeholder='Contact name' style={{ alignSelf: 'center', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>


                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <input value={curr_mail.company_telephone} disabled placeholder='Telephone no.' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', paddingLeft: 5, fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 35, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black', marginLeft: 5 }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Email:
                                        </text>
                                    </div>

                                    <input value={curr_mail.company_mail} disabled placeholder='Company email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', paddingLeft: 10, fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C4133', borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                PROJECT DETAILS
                            </text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: 0.57 * windowWidth, height: 'auto', alignSelf: 'center', backgroundColor: 'white' }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: '#0D1C41', borderLeftWidth: 1, borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 40, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Service Type
                                    </text>
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 40, alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Project Address
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Postcode:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Site Contact:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Telephone No:
                                    </text>
                                </div>



                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: 'white', borderLeftWidth: 0, borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>




                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 40, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>


                                    <div style={{ alignSelf: 'center', justifyContent: 'left', fontFamily: 'Roboto', paddingLeft: 10, color: 'black', fontWeight: '400', width: 0.20 * windowWidth, height: 40, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }}>

                                        <Select
                                            components={{ DropdownIndicator: DropdownIndicator1 }}
                                            isDisabled={true}
                                            onChange={(e) => {

                                            }}
                                            options={[{ 'label': 'Service 1', 'value': 'Service 1' }, { 'label': 'Service 2', 'value': 'Service 2' }, { 'label': 'Service 3', 'value': 'Service 3' }]}
                                            placeholder={curr_mail.service_type}
                                            className="basic-single4"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textAlign: "left",
                                                    fontWeight: '400',
                                                    height: 30,
                                                    backgroundColor: 'white',
                                                    fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                                    borderRadius: 0,
                                                    borderWidth: 0, outline: 'none',
                                                    marginLeft: -8
                                                }),
                                                placeholder: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                singleValue: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                menu: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot11('white')

                                                }),
                                                menuList: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot22('white')

                                                }),
                                            }}
                                            maxMenuHeight={200}
                                            menuShouldScrollIntoView={true}
                                        />

                                    </div>


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 40, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black' }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Survey:
                                        </text>
                                    </div>

                                    <div style={{ alignSelf: 'center', justifyContent: 'left', fontFamily: 'Roboto', paddingLeft: 10, color: 'black', fontWeight: '400', width: 0.20 * windowWidth, height: 40, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }}>

                                        <Select
                                            components={{ DropdownIndicator: DropdownIndicator1 }}
                                            onChange={(e) => {

                                            }}
                                            isDisabled={true}
                                            options={[{ 'label': 'Survey 1', 'value': 'Survey 1' }, { 'label': 'Survey 2', 'value': 'Survey 2' }, { 'label': 'Survey 3', 'value': 'Survey 3' }]}
                                            placeholder={curr_mail.survey}
                                            className="basic-single4"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textAlign: "left",
                                                    fontWeight: '400',
                                                    height: 30,
                                                    backgroundColor: 'white',
                                                    fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                                    borderRadius: 0,
                                                    borderWidth: 0, outline: 'none',
                                                    marginLeft: -8
                                                }),
                                                placeholder: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                singleValue: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                menu: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot11('white')

                                                }),
                                                menuList: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot22('white')

                                                }),
                                            }}
                                            maxMenuHeight={200}
                                            menuShouldScrollIntoView={true}
                                        />

                                    </div>
                                </div>




                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, justifyContent: 'flex-start', height: 40, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={curr_mail.address} autoComplete="off" disabled placeholder='Project address' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 59, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={curr_mail.post} autoComplete="off" disabled placeholder='Post Code' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={curr_mail.site} autoComplete="off" disabled placeholder='Site Contact' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <input autoComplete="off" value={curr_mail.telephone} disabled placeholder='Telephone no.' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 35, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black' }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Email:
                                        </text>
                                    </div>

                                    <input autoComplete="off" value={curr_mail.mail} disabled placeholder='Email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8, paddingLeft: 10 }} />

                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                BRIEF DESCRIPTION OF PROJECT & REQUIREMENTS
                            </text>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, alignItems: 'center', justifyContent: 'center', height: 90, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <textarea value={curr_mail.brief} disabled onChange={(e) => setBrief(e.currentTarget.value)} placeholder='Write ' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.555 * windowWidth, height: 80, backgroundColor: 'white', outline: 'none', borderStyle: 'none', }} />

                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                ADDITIONAL COMMENTS / INFORMATION (INCLUDING KEY DATES)
                            </text>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, alignItems: 'center', justifyContent: 'center', height: 90, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <textarea value={curr_mail.comment} disabled onChange={(e) => setComment(e.currentTarget.value)} placeholder='Write ' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.555 * windowWidth, height: 80, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 50, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                ASSIGNED TO:
                            </text>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, alignItems: 'flex-start', justifyContent: 'flex-start', height: 50, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <Select
                                components={{ DropdownIndicator: DropdownIndicator1 }}
                                onChange={(e) => {

                                }}
                                // value={typeof curr_mail.assign=='string'?{label:curr_mail.assign,value:curr_mail.assign}:curr_mail.assign}
                                options={getEmails()}
                                isDisabled
                                placeholder={typeof curr_mail.assign == 'string' ? curr_mail.assign : getUsers(curr_mail.assign)}
                                className="basic-single44"
                                classNamePrefix="select"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        textAlign: "left",
                                        fontWeight: '400',
                                        height: 30,
                                        backgroundColor: 'white',
                                        fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                        borderRadius: 0,
                                        borderWidth: 0, outline: 'none',
                                        marginLeft: 2
                                    }),
                                    placeholder: (baseStyles, state) => ({

                                        ...baseStyles,
                                        ...dott('white')

                                    }),
                                    singleValue: (baseStyles, state) => ({

                                        ...baseStyles,
                                        ...dott('white')

                                    }),
                                    menu: (baseStyles, state) => ({

                                        ...baseStyles,
                                        ...dot11('white')

                                    }),
                                    menuList: (baseStyles, state) => ({

                                        ...baseStyles,
                                        ...dot22('white')

                                    }),
                                }}
                                // inputValue={typeof curr_mail.assign=='string'?{label:curr_mail.assign,value:curr_mail.assign}:curr_mail.assign}
                                maxMenuHeight={200}
                                menuShouldScrollIntoView={true}
                            />
                        </div>


                    </div> : null}

                    {edit_pop != false && <div style={{ display: 'flex', flexDirection: 'column', width: 0.57 * windowWidth, alignSelf: 'center', backgroundColor: 'white', marginTop: 20, height: windowHeight - 220 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: 0.57 * windowWidth, height: 'auto', alignSelf: 'center', backgroundColor: 'white' }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: '#0D1C41', borderLeftWidth: 1, borderBottomWidth: 0, borderTopWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>


                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Reference No.
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 65, alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Enquiry Type:
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Company Name:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Contact Name:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Telephone No:
                                    </text>
                                </div>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: 'white', borderLeftWidth: 0, borderBottomWidth: 0, borderTopWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>



                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 300, fontSize: 14, marginLeft: 10, textAlign: 'left' }}>
                                      SC- {edit_pop.id}
                                    </text>
                                </div>


                                <div style={{ display: 'flex', flexWrap: 'wrap', width: 0.44 * windowWidth, height: 65, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 60, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!quote ? <FaRegSquare onClick={() => { if (quote) setQuote(!quote); else { setQuote(true); setRequest(false); setProblem(false); setGeneral(false); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setQuote(true); setRequest(false); setProblem(false); setGeneral(false) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Quote
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 130, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!request ? <FaRegSquare onClick={() => { if (request) setRequest(!request); else { setQuote(false); setRequest(true); setProblem(false); setGeneral(false); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setQuote(false); setRequest(true); setProblem(false); setGeneral(false) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Call Back Request
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 150, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!problem ? <FaRegSquare onClick={() => { if (problem) setProblem(!problem); else { setQuote(false); setRequest(false); setProblem(true); setGeneral(false); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setQuote(false); setRequest(false); setProblem(true); setGeneral(false) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            Problem / Complaint
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 150, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!job ? <FaRegSquare onClick={() => { { setQuote(false); setRequest(false); setProblem(false); setGeneral(false); setJob(true) } }} /> : <FaSquareCheck onClick={() => { setGeneral(!general) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            New Job Registration
                                        </text>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', width: 0.34 * windowWidth, justifyContent: 'flex-start', height: 20, alignSelf: 'center', alignItems: 'center', marginLeft: 10 }}>
                                        {!general ? <FaRegSquare onClick={() => { { setQuote(false); setRequest(false); setProblem(false); setGeneral(true); setJob(false) } }} /> : <FaSquareCheck onClick={() => { setGeneral(!general) }} />}
                                        <text style={{ alignSelf: 'center', fontWeight: 300, fontSize: 14, marginLeft: 5, textAlign: 'left' }}>
                                            General Enquiry / Request for Information
                                        </text>
                                    </div>


                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={company} onChange={(e) => setCompany(e.currentTarget.value)} placeholder='Company name' style={{ alignSelf: 'center', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={contact} onChange={(e) => setContact(e.currentTarget.value)} placeholder='Contact name' style={{ alignSelf: 'center', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>


                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <input value={company_telephone} onChange={(e) => setCompanyTelephone(e.currentTarget.value)} placeholder='Telephone no.' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', paddingLeft: 5, fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 35, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black', marginLeft: 5 }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Email:
                                        </text>
                                    </div>

                                    <input value={company_mail} onChange={(e) => setCompanyMail(e.currentTarget.value)} placeholder='Company email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', paddingLeft: 10, fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C4133', borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                PROJECT DETAILS
                            </text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: 0.57 * windowWidth, height: 'auto', alignSelf: 'center', backgroundColor: 'white' }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: '#0D1C41', borderLeftWidth: 1, borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 40, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Service Type
                                    </text>
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 40, alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Project Address
                                    </text>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Postcode:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'white' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Site Contact:
                                    </text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.13 * windowWidth, height: 35, alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderStyle: 'solid', borderColor: 'black' }}>
                                    <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, color: 'white', textAlign: 'left' }}>
                                        Telephone No:
                                    </text>
                                </div>



                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 'auto', alignSelf: 'flex-start', backgroundColor: 'white', borderLeftWidth: 0, borderBottomWidth: 0, borderTopWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>




                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 40, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>


                                    <div style={{ alignSelf: 'center', justifyContent: 'left', fontFamily: 'Roboto', paddingLeft: 10, color: 'black', fontWeight: '400', width: 0.20 * windowWidth, height: 40, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }}>

                                        <Select
                                            components={{ DropdownIndicator: DropdownIndicator1 }}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setServiceType(e.label)
                                                }
                                            }}
                                            options={service_types}
                                            placeholder={service_type}
                                            className="basic-single4"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textAlign: "left",
                                                    fontWeight: '400',
                                                    height: 30,
                                                    backgroundColor: 'white',
                                                    fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                                    borderRadius: 0,
                                                    borderWidth: 0, outline: 'none',
                                                    marginLeft: -8
                                                }),
                                                placeholder: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                singleValue: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                menu: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot11('white')

                                                }),
                                                menuList: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot22('white')

                                                }),
                                            }}
                                            maxMenuHeight={200}
                                            menuShouldScrollIntoView={true}
                                        />

                                    </div>


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 40, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black' }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Survey:
                                        </text>
                                    </div>

                                    <div style={{ alignSelf: 'center', justifyContent: 'left', fontFamily: 'Roboto', paddingLeft: 10, color: 'black', fontWeight: '400', width: 0.20 * windowWidth, height: 40, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }}>

                                        <Select
                                            components={{ DropdownIndicator: DropdownIndicator1 }}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setSurvey(e.label)
                                                }
                                            }}
                                            options={survey_types}
                                            placeholder={survey}
                                            className="basic-single4"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textAlign: "left",
                                                    fontWeight: '400',
                                                    height: 30,
                                                    backgroundColor: 'white',
                                                    fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                                    borderRadius: 0,
                                                    borderWidth: 0, outline: 'none',
                                                    marginLeft: -8
                                                }),
                                                placeholder: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                singleValue: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dott('white')

                                                }),
                                                menu: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot11('white')

                                                }),
                                                menuList: (baseStyles, state) => ({

                                                    ...baseStyles,
                                                    ...dot22('white')

                                                }),
                                            }}
                                            maxMenuHeight={200}
                                            menuShouldScrollIntoView={true}
                                        />

                                    </div>
                                </div>




                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, justifyContent: 'flex-start', height: 40, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={address} autoComplete="off" onChange={(e) => setAddress(e.currentTarget.value)} placeholder='Project address' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 59, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={post} autoComplete="off" onChange={(e) => setPost(e.currentTarget.value)} placeholder='Post Code' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 0.44 * windowWidth, height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                                    <input value={site} autoComplete="off" onChange={(e) => setSite(e.currentTarget.value)} placeholder='Site Contact' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, paddingLeft: 5, width: 0.43 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: 0.44 * windowWidth, alignItems: 'center', justifyContent: 'space-evenly', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>

                                    <input autoComplete="off" value={telephone} onChange={(e) => setTelephone(e.currentTarget.value)} placeholder='Telephone no.' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                                    <div style={{ display: 'flex', flexDirection: 'column', width: 106, height: 35, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: '#0D1C41', borderStyle: 'solid', borderColor: 'black' }}>
                                        <text style={{ alignSelf: 'center', fontWeight: 'bold', color: 'white' }}>
                                            Email:
                                        </text>
                                    </div>

                                    <input autoComplete="off" value={mail} onChange={(e) => setMail(e.currentTarget.value)} placeholder='Email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', fontSize: 14, width: 0.13 * windowWidth, height: 30, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8, paddingLeft: 10 }} />

                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                BRIEF DESCRIPTION OF PROJECT & REQUIREMENTS
                            </text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'center', justifyContent: 'center', height: 90, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <textarea value={brief} onChange={(e) => setBrief(e.currentTarget.value)} placeholder='Write ' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.555 * windowWidth, height: 80, backgroundColor: 'white', outline: 'none', borderStyle: 'none', }} />

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 35, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                ADDITIONAL COMMENTS / INFORMATION (INCLUDING KEY DATES)
                            </text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'center', justifyContent: 'center', height: 90, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <textarea value={comment} onChange={(e) => setComment(e.currentTarget.value)} placeholder='Write ' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'black', fontWeight: '400', paddingLeft: 5, fontSize: 14, width: 0.555 * windowWidth, height: 80, backgroundColor: 'white', outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, backgroundColor: '#0D1C4133', alignItems: 'flex-start', justifyContent: 'center', height: 50, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', borderColor: 'black' }}>
                            <text style={{ alignSelf: 'flex-start', fontWeight: 'bold', marginLeft: 10, textAlign: 'left' }}>
                                ASSIGNED TO:
                            </text>
                        </div>

                        <div onClick={() => ref?.current.scrollIntoView()} style={{ display: 'flex', flexDirection: 'column', width: (0.57 * windowWidth) + 1, alignItems: 'flex-start', justifyContent: 'flex-start', height: 70, alignSelf: 'flex-start', borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: 'solid', backgroundColor: 'white', borderColor: 'black' }}>
                            <div style={{ width: 600, display: 'flex', flexDirection: 'column' }}>
                                <Select
                                    components={{ DropdownIndicator: DropdownIndicator1 }}
                                    onChange={(e) => {
                                        if (e != null) {
                                            setAssign([...e])
                                        }
                                    }}
                                    isMulti
                                    options={getEmails()}
                                    placeholder={assign}
                                    className="basic-singlemulti"
                                    classNamePrefix="select"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textAlign: "left",
                                            fontWeight: '400',
                                            height: 50,
                                            backgroundColor: 'white',
                                            fontFamily: 'Montserrat', color: '#0D1C41', fontWeight: '500', fontSize: 12,
                                            borderRadius: 0,
                                            borderWidth: 0, outline: 'none',
                                            marginLeft: 2
                                        }),
                                        placeholder: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dott('white')

                                        }),
                                        singleValue: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dott('white')

                                        }),
                                        menu: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dot11('white')

                                        }),
                                        menuList: (baseStyles, state) => ({

                                            ...baseStyles,
                                            ...dot22('white')

                                        }),
                                    }}
                                    maxMenuHeight={200}
                                    menuShouldScrollIntoView={true}
                                    menuPlacement='top'
                                    //inputValue={typeof assign == 'string' ? assign : Object.values(assign)}
                                    defaultValue={typeof assign == 'string' ? { label: assign, value: assign } : assign}
                                />
                            </div>
                        </div>

                        <div ref={ref} />


                    </div>}

                    {!add_mail && curr_mail == null && edit_pop == false && !all_user_pop && <div style={{ display: 'flex', flexDirection: 'row', width: 0.7704 * windowWidth, alignSelf: 'center', backgroundColor: 'white', marginTop: 20, height: 50, justifyContent: 'space-between', alignItems: 'center' }}>
                        <text style={{ width: '15%', fontWeight: '600' }}>Reference</text>
                        <text style={{ width: '15%', fontWeight: '600' }}>Date / time</text>
                        <text style={{ width: '15%', fontWeight: '600' }}>Enquiry type</text>
                        <text style={{ width: '25%', fontWeight: '600' }}>Company name</text>
                        <text style={{ width: '15%', fontWeight: '600' }}>Assigned to.</text>
                        <text style={{ width: 50, fontWeight: '600' }}>Action</text>
                    </div>}

                    {!add_mail && curr_mail == null && edit_pop == false && !all_user_pop && <div style={{ display: 'flex', flexDirection: 'column', width: 0.7704 * windowWidth, alignSelf: 'center', marginTop: 20, maxHeight: windowHeight - 250, overflowY: 'scroll', overflowX: 'hidden' }}>
                        {all.map((item, index) =>
                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.7704 * windowWidth, alignSelf: 'center', marginTop: 20, height: 40, justifyContent: 'space-between', maxHeight: 100, padding: 6 }}>
                                <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}>{item.id}</text>
                                <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}>{make_date(item.reference)}</text>
                                {item.quote && <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}>Quote</text>}
                                {item.request && <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}>Call Back Request</text>}
                                {item.problem && <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}>Problem / Complaint</text>}
                                {item.job && <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}>New Job Registration</text>}
                                {item.general && <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}>General Enquiry / Request for Information</text>}
                                {!item.job && !item.general && !item.problem && !item.request && !item.quote && <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%' }}></text>}
                                <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '25%' }}>{item.company_name}</text>
                                <text onClick={() => { setEditPop(item); setAssign(item.assign) }} style={{ cursor: 'pointer', width: '15%', fontSize: 12 }}>{typeof item.assign == 'string' ? item.assign : getUsers(item.assign)}</text>
                                <FaRegEye onClick={() => { setCurrMail(item); setAssign(item.assign) }} style={{ cursor: 'pointer', marginRight: 10 }} size={20} color='black' />
                            </div>)}
                    </div>}

                    {all_user_pop && !add_mail && curr_mail == null && edit_pop == false && <div style={{ display: 'flex', flexDirection: 'row', width: 0.7704 * windowWidth, alignSelf: 'center', marginTop: 40, maxHeight: 100, alignItems: 'center' }}>
                        <text style={{ color: '#0D1C41', textAlign: 'left', fontWeight: '600', fontSize: 20 }}>Total users</text>
                        <FaPlusCircle onClick={() => setAddPop(true)} size={20} style={{ marginLeft: 15, marginTop: 5, cursor: 'pointer' }} color='black' />
                    </div>}

                    {all_user_pop && !add_mail && curr_mail == null && edit_pop == false ? <div style={{ display: 'flex', flexDirection: 'column', width: 0.7704 * windowWidth, alignSelf: 'center', marginTop: 20, height: 400, overflowY: 'scroll', overflowX: 'hidden' }}>
                        {admin_users.map((item, indx) => <div style={{ backgroundColor: 'white', marginLeft: 30, marginBottom: 6, width: 0.50 * windowWidth, alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', height: 50, marginTop: 5, justifyContent: 'normal', borderRadius: 10, padding: 10 }}>

                            <div style={{ width: '45%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <text style={{ color: (item.suspend == false || item.suspend == undefined) ? 'black' : '#ddb19e', fontSize: 13, fontWeight: '500', fontFamily: 'Montserrat', marginLeft: 10 }}>{(item.email)}</text>
                            </div>

                            <div style={{ width: '25%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <text style={{ color: (item.suspend == false || item.suspend == undefined) ? 'black' : '#ddb19e', fontSize: 13, fontWeight: '500', fontFamily: 'Montserrat', marginLeft: 10 }}>Added : {make_date(item.ID)}</text>
                            </div>

                            <AiTwotoneEdit onClick={() => { setEditPopMail(item); setEditMail((item.email)); }} size={20} color='black' />
                            <MdOutlineDelete onClick={() => setDeletePopUp(item)} size={20} color='black' style={{ marginLeft: 15 }} />


                        </div>)}</div> : null}

                </div>

            </div>

            <Modal
                open={edit_pop_mail != false}
                onClose={() => setEditPopMail(false)}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontFamily: 'Montserrat', fontSize: 20 }}>Edit details </text>
                    </div>

                    <input placeholder='Email' value={edit_mail} onChange={(e) => setEditMail(e.currentTarget.value)} style={{ fontFamily: 'Montserrat', width: 350, alignSelf: 'center', height: 45, borderStyle: 'solid', outline: 'none', borderWidth: 1, borderColor: 'black', borderRadius: 6, marginTop: 15 }} />


                    <div onClick={() => make_edit()} style={{ cursor: 'pointer', width: 175, padding: 10, backgroundColor: '#0D1C41', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                        <text style={{ color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Update</text>
                    </div>
                </div>
            </Modal>

            <Modal
                open={add_pop != false}
                onClose={() => { setAddPop(false) }}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontFamily: 'Montserrat', fontSize: 20 }}>Add New User </text>
                    </div>

                    <input placeholder='Email' value={edit_mail} onChange={(e) => setEditMail(e.currentTarget.value)} style={{ fontFamily: 'Montserrat', paddingLeft: 4, width: 350, alignSelf: 'center', height: 45, borderStyle: 'solid', outline: 'none', borderWidth: 1, borderColor: 'black', borderRadius: 6, marginTop: 15 }} />


                    <div onClick={() => make_add()} style={{ cursor: 'pointer', width: 175, padding: 10, backgroundColor: '#0D1C41', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                        <text style={{ color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Add</text>
                    </div>
                </div>
            </Modal>

            <Modal
                open={delete_pop != false}
                onClose={() => setDeletePopUp(false)}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20 }}>  Are you sure you want to delete this ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={() => deleteUser()} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeletePopUp(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#8455f6", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div>
            </Modal>

        </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps)(Admins)

