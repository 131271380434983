
import './App.css';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
    HashRouter
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { auth } from './config';
//import { ToastContainer, toast } from 'react-toastify';
import { toast } from 'wc-toast'
import Login from './screens/Login';

import { Provider } from "react-redux";
import store from "./screens/redux/stores/index";
import App from './App';

//import 'react-toastify/dist/ReactToastify.css';

export default function Main() {


    const ___doSingIn = async () => {
        try {
            let response = await auth.signInWithEmailAndPassword("n.joy@boomsoftware.co.uk", "2103199j");
            if (response && response.user) {
                console.log('User ', response)
            }
        } catch (e) {
            console.error(e.message);
        }
    };

    useEffect(() => {
        ___doSingIn()
    }, [])

    return (
        <div className="App">
            <Provider store={store}>
                <App/>
            </Provider>
            <wc-toast></wc-toast>
        </div>
    );
}