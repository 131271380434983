import { MdStar } from "react-icons/md";
import files_active from './files_icon_active.png'
import files_inactive from './files_icon_inactive.png'
import { GrAttachment } from "react-icons/gr";
import { IoSearchOutline } from "react-icons/io5";

import { MdOutlineSettings } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";

export const settings_active=<MdOutlineSettings color='white' size={20}/>
export const settings_inactive=<MdOutlineSettings color='#A2B0BF' size={20}/>

export const user_active=<FaRegUser color='white' size={20}/>
export const user_inactive=<FaRegUser color='#A2B0BF' size={20}/>

export const favourite_active=<MdStar color='white' size={20}/>
export const favourite_inactive=<MdStar color='#A2B0BF' size={20}/>

export const link_active=<GrAttachment color='white' size={20}/>
export const link_inactive=<GrAttachment color='#A2B0BF' size={20}/>

export const tags_active=<IoSearchOutline color='white' size={20}/>
export const tags_inactive=<IoSearchOutline color='#A2B0BF' size={20}/>

export const files_active_icon=<img src={files_active} style={{width:20,height:20}}/>
export const files_inactive_icon=<img src={files_inactive} style={{width:20,height:20}}/>