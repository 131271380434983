import { combineReducers } from 'redux';
import { SET_ID, SET_PROJECT, ADD_PROJECT, ADD_URI, MAN_FAVOURITE, EDIT_PROJECT, SET_FAVOURITES } from '../actions/index';

const initProduct = {
    projects: [],
    myid: null,
    favourites: []
}

function todoProduct(state = initProduct, action) {
    switch (action.type) {

        case SET_ID:
            return { ...state, myid: action.payload }
        case SET_PROJECT:
            return { ...state, projects: { ...action.payload } }
        case SET_FAVOURITES:
            return { ...state, projects: { ...action.payload } }
        default:
            return state;
    }
}
const ShopApp = combineReducers({
    _todoProduct: todoProduct
});

export default ShopApp;