
export const SET_ID = 'SET_ID' ;
export const SET_PROJECT = 'SET_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_URI = 'ADD_URI';

export const MAN_FAVOURITE = 'MAN_FAVOURITE';
export const EDIT_PROJECT = 'EDIT_PROJECT'

export const SET_FAVOURITES = 'SET_FAVOURITES';

export function SetId(payload){
    return {
        type:'SET_ID',
        payload
    }
}

export function SetProject(payload){
    return {
        type:'SET_PROJECT',
        payload
    }
}

export function AddProject(payload){
    return {
        type:'ADD_PROJECT',
        payload
    }
}

export function EditProject(payload){
    return {
        type:'EDIT_PROJECT',
        payload
    }
}

export function AddUri(payload){
    return {
        type:'ADD_URI',
        payload
    }
}

export function ManFav(payload){
    return {
        type:'MAN_FAVOURITE',
        payload
    }
}

export function SetFavourite(payload){
    return {
        type:'SET_FAVOURITES',
        payload
    }
}

